import z from 'zod';
export const ComplexResidue = z.enum(
  [
    'RF-00000030-PAR',
    'RF-00002582-PAR',
    'RF-00003018-PAR',
    'RF-00003033-PAR',
    'RF-00003039-PAR',
    'RF-00003041-PAR',
    'RF-00003342-PAR',
    'RF-00003343-PAR',
    'RF-00003344-PAR',
    'RF-00003351-PAR',
    'RF-00003367-PAR',
    'RF-00003374-PAR',
    'RF-00004655-PAR',
    'RF-00004659-PAR',
    'RF-00004663-PAR',
    'RF-00004666-PAR',
    'RF-00004667-PAR',
    'RF-00004681-PAR',
    'RF-00004683-PAR',
    'RF-00004687-PAR',
    'RF-00004817-PAR',
    'RF-00005724-PAR',
    'RF-00005726-PAR',
    'RF-00005728-PAR',
    'RF-00005729-PAR',
    'RF-00005730-PAR',
    'RF-00007588-PAR',
    'RF-00007633-PAR',
    'RF-00011515-PAR',
    'RF-00011559-PAR',
    'RF-00011882-PAR',
    'RF-00012032-PAR',
    'RF-00012293-PAR',
    'RF-00012803-PAR',
    'RF-00012874-PAR',
    'RF-00012875-PAR',
    'RF-0008-001-PPP',
    'RF-0010-001-PPP',
    'RF-0020-001-PPP',
    'RF-0021-001-PPP',
    'RF-0024-001-PPP',
    'RF-0034-001-PPP',
    'RF-0041-001-PPP',
    'RF-0042-001-PPP',
    'RF-0075-001-PPP',
    'RF-0096-001-PPP',
    'RF-00014256-PAR',
    'RF-0112-001-PPP',
    'RF-0117-001-PPP',
    'RF-0118-001-PPP',
    'RF-0119-001-PPP',
    'RF-0130-001-PPP',
    'RF-0143-001-PPP',
    'RF-0149-001-PPP',
    'RF-0155-001-PPP',
    'RF-0167-001-PPP',
    'RF-0173-001-PPP',
    'RF-0178-001-PPP',
    'RF-0187-001-PPP',
    'RF-0192-001-PPP',
    'RF-0203-001-PPP',
    'RF-0223-001-PPP',
    'RF-0225-001-PPP',
    'RF-0232-001-PPP',
    'RF-0236-001-PPP',
    'RF-0259-001-PPP',
    'RF-0266-001-PPP',
    'RF-0271-004-PPP',
    'RF-0276-001-PPP',
    'RF-0280-001-PPP',
    'RF-0291-001-PPP',
    'RF-0302-001-PPP',
    'RF-0323-001-PPP',
    'RF-0328-001-PPP',
    'RF-0336-001-PPP',
    'RF-0362-001-PPP',
    'RF-0374-001-PPP',
    'RF-0376-001-PPP',
    'RF-0383-001-PPP',
    'RF-0393-001-PPP',
    'RF-0425-001-PPP',
    'RF-0440-001-PPP',
    'RF-0690-006-PPP',
    'RF-1078-002-PPP',
    'RF-00014208-PAR',
    'RF-00014207-PAR',
  ],
  {
    errorMap: () => ({
      message: 'Veuillez renseigner le résidu.',
    }),
  }
);

export type ComplexResidue = z.infer<typeof ComplexResidue>;

export const ComplexResidueList: ComplexResidue[] = ComplexResidue.options;
