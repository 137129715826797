import { Analyte } from './Analyte';

export const AnalyteLabels: Record<Analyte, string> = {
  'RF-00003331-PAR':
    'BYI08330-enol (cis-3-(2,5-dimethylphenyl)-4-hydroxy-8-methoxy-1-azaspiro[4.5]dec-3-en-2-one)',
  'RF-00003368-PAR': 'Spirotetramat',
  'RF-00003376-PAR': 'BAC 8',
  'RF-1078-003-PPP': 'BAC 10',
  'RF-1078-004-PPP': 'BAC 12',
  'RF-1078-005-PPP': 'BAC 14',
  'RF-1078-006-PPP': 'BAC 16',
  'RF-1078-007-PPP': 'BAC 18',
  'RF-0303-002-PPP': 'Milbemectin A3',
  'RF-0303-003-PPP': 'Milbemectin A4',
  'RF-00003032-PAR': 'Bifenazate-diazene',
  'RF-0044-001-PPP': 'Bifenazate',
  'RF-00007625-PAR': '3-(Tetrahydro-pyran-4-yl)-glutaric acid (GP)',
  'RF-00007626-PAR':
    '3-Hydroxy-(tetrahydro-pyran-4-yl)-glutaric acid (5-OH-DP)',
  'RF-0411-001-PPP': 'Tepraloxydim',
  'RF-0009-001-PPP': '2,4,5-T',
  'RF-0457-001-PPP': '2,4,5-T-Methylester',
  'RF-0047-001-PPP': 'Binapacryl',
  'RF-0144-001-PPP': 'Dinoseb',
  'RF-0632-001-PPP': 'Dinoseb Acetate',
  'RF-0145-001-PPP': 'Dinoterb',
  'RF-1036-001-PPP': 'Medinoterb',
  'RF-00003323-PAR':
    '479M04 ([(2,6-dimethylphenyl)(1Hpyrazol-1-ylmethyl)amino](oxo)acetic acid)',
  'RF-00003324-PAR':
    '479M08 (2-[(2,6-dimethylphenyl)(1Hpyrazol-1-ylmethyl)amino]-2-oxoethanesulfonic acid)',
  'RF-00003325-PAR':
    '479M16 (3-({2-[(2,6-dimethylphenyl)(1H-pyrazol-1-ylmethyl)amino]-2-oxoethyl}sulfinyl)-2-hydroxypropanoic acid)',
  'RF-0285-001-PPP': 'Metazachlor',
  'RF-0016-002-PPP': 'Acibenzolar-S-methyl',
  'RF-0016-003-PPP': 'Acibenzolar acid (CGA 210007)',
  'RF-00003366-PAR': 'Pyraflufen',
  'RF-0371-001-PPP': 'Pyraflufen-ethyl',
  'RF-0040-001-PPP': 'Benfuracarb',
  'RF-0065-002-PPP': 'Carbofuran, 3-hydroxy',
  'RF-0065-003-PPP': 'Carbofuran',
  'RF-0068-001-PPP': 'Carbosulfan',
  'RF-0228-001-PPP': 'Furathiocarb',
  'RF-0011-002-PPP': 'Avermectin B1a, delta-8,9 isomer',
  'RF-0011-003-PPP': 'Avermectin B1a',
  'RF-0011-004-PPP': 'Avermectin B1b',
  'RF-0126-002-PPP': 'Dichlorprop',
  'RF-0126-003-PPP': 'Dichlorprop-p',
  'RF-0215-002-PPP': 'Fluroxypyr-Meptyl',
  'RF-0215-003-PPP': 'Fluroxypyr',
  'RF-00004665-PAR': 'Halauxifen (X11393729)',
  'RF-00007584-PAR': 'Halauxifen-methyl',
  'RF-0235-002-PPP': 'Haloxyfop-Methyl',
  'RF-0235-003-PPP': 'Haloxyfop-Ethoxyethylester',
  'RF-0235-004-PPP': 'Haloxyfop',
  'RF-0235-006-PPP': 'Haloxyfop-P',
  'RF-0235-007-PPP': 'Haloxyfop-P-methyl',
  'RF-00004686-PAR': 'THPI',
  'RF-0061-001-PPP': 'Captan',
  'RF-00003348-PAR': 'TNFA (4-(Trifluoromethyl)nicotinic acid)',
  'RF-00003349-PAR': 'TNFG (4-(Trifluoromethyl)nicotinoyl glycine)',
  'RF-0194-002-PPP': 'Flonicamid',
  'RF-0221-001-PPP': 'Folpet',
  'RF-0991-001-PPP': 'Phthalimide',
  'RF-00004816-PAR': 'Saflufenacil',
  'RF-00007622-PAR':
    "M800H11 (N'-[2-chloro-5-(2,6-dioxo-4-(trifluoromethyl)-3,6-dihydro-1(2H)-pyrimidinyl)-4-fluorobenzoyl]-N-isopropylsulfamide)",
  'RF-00007623-PAR':
    'M800H35 (N-[4-chloro-2-fluoro-5-({[(isopropylamino) sulfonyl]amino} carbonyl)phenyl] urea)',
  'RF-00000021-PAR': '2-keto-ethofumesate',
  'RF-00007614-PAR': 'Open-ring-2-keto-ethofumesate',
  'RF-0163-002-PPP': 'Ethofumesate',
  'RF-00005776-PAR': 'Sodium 5-nitroguaiacolate',
  'RF-00005777-PAR': 'Sodium o-nitrophenolate',
  'RF-00005778-PAR': 'Sodium p-nitrophenolate',
  'RF-0006-001-PPP': '1-naphthylacetamide',
  'RF-0007-001-PPP': '1-naphthylacetic acid',
  'RF-00006186-PAR': '5-amino-4-chloro-3(2H)-pyridazinone',
  'RF-0080-001-PPP': 'Chloridazon',
  'RF-00000022-PAR': 'Fluazifop-P',
  'RF-0197-002-PPP': 'Fluazifop-P-butyl',
  'RF-0698-001-PPP': 'Fluazifop (free acid)',
  'RF-0699-001-PPP': 'Fluazifop-Butyl',
  'RF-00009870-PAR': 'Desmethyl chlorpyrifos-methyl',
  'RF-0088-001-PPP': 'Chlorpyrifos-methyl',
  'RF-0823-001-PPP': '2-phenylphenol',
  'RF-0823-002-PPP': '4-Phenylphenol',
  'RF-00011514-PAR': 'Bicyclopyrone',
  'RF-00011516-PAR':
    '2-(2- methoxyethoxymethyl)-6-(trifluoromethyl) pyridine-3-carboxylic acid (SYN503780)',
  'RF-00011517-PAR':
    '2-(2-hydroxyethoxymethyl)-6- (trifluoromethyl)pyridine-3-carboxylic acid (CSCD686480)',
  'RF-00005982-PAR': 'Carboxin Sulfoxide',
  'RF-0069-001-PPP': 'Carboxin',
  'RF-0322-001-PPP': 'Oxycarboxin',
  'RF-0356-001-PPP': 'Propaquizafop',
  'RF-0384-002-PPP': 'Quizalofop-P',
  'RF-0384-004-PPP': 'Quizalofop',
  'RF-0887-001-PPP': 'Quizalofop-Ethyl',
  'RF-0887-002-PPP': 'Quizalofop-P-ethyl',
  'RF-00003328-PAR':
    'BTS 44595 (N-propyl-N-[2-(2,4,6-trichlorophenoxy)ethyl]-urea)',
  'RF-00003329-PAR':
    "BTS 44596 (N'-Formyl-N-propyl-N-[2-(2,4,6-trichlorophenoxy)ethyl]urea)",
  'RF-0349-002-PPP': 'Prochloraz',
  'RF-00002608-PAR': 'Tembotrione metabolite M5 (Dihydroxy-tembotrione)',
  'RF-0409-001-PPP': 'Tembotrione',
  'RF-00012804-PAR': 'pencycuron-PB-amine',
  'RF-0330-001-PPP': 'Pencycuron',
  'RF-0070-002-PPP': 'Carfentrazone',
  'RF-0070-003-PPP': 'Carfentrazone-ethyl',
  'RF-0128-002-PPP': 'Diclofop',
  'RF-0128-003-PPP': 'Diclofop-Methyl',
  'RF-00002588-PAR': '2,4-DB methylester',
  'RF-00004646-PAR': '2,4-DB',
  'RF-0010-002-PPP': '2,4-D-Methylester',
  'RF-0010-003-PPP': '2,4-D',
  'RF-0020-002-PPP': 'Aldicarb',
  'RF-0020-003-PPP': 'Aldicarb-Sulfoxide',
  'RF-0020-004-PPP': 'Aldicarb-Sulfone',
  'RF-0021-002-PPP': 'Aldrin',
  'RF-0021-003-PPP': 'Dieldrin',
  'RF-0024-002-PPP': 'Amitraz',
  'RF-0024-003-PPP': 'Dimethylphenylformamide, 2,4-',
  'RF-0024-004-PPP': 'Dimethylphenyl-N-methylformamidine, N-2,4-',
  'RF-0992-001-PPP': '2,4-Dimethylanilin',
  'RF-0034-002-PPP': 'Cyhexatin',
  'RF-0034-003-PPP': 'Azocyclotin',
  'RF-0041-002-PPP': 'Carbendazim',
  'RF-0041-003-PPP': 'Benomyl',
  'RF-0042-002-PPP': 'Bentazone',
  'RF-0042-003-PPP': 'Bentazone-8-hydroxy',
  'RF-0042-004-PPP': 'Bentazone-6-hydroxy',
  'RF-0075-003-PPP': 'Chlordane, trans-',
  'RF-0075-004-PPP': 'Chlordane, cis-',
  'RF-0096-002-PPP': 'Clethodim-Sulfon',
  'RF-0096-003-PPP': 'Sethoxydim',
  'RF-0096-004-PPP': 'Clethodim-Sulfoxid',
  'RF-0096-005-PPP': 'Clethodim',
  'RF-0096-006-PPP': 'Clethodim-Imin-Sulfoxid',
  'RF-0096-007-PPP': 'Clethodim-Imin-Sulfon',
  'RF-0096-009-PPP': 'Clethodim, 5-Hydroxy-Sulfon',
  'RF-00006227-PAR': '3-(3-thianyl)glutaric acid S-dioxide',
  'RF-00006240-PAR': '3-hydroxy-3-(3-thianyl)glutaric acid S-dioxide',
  'RF-0106-002-PPP': 'Cycloxydim',
  'RF-00000161-VET': 'Cypermethrin, alpha-',
  'RF-0112-002-PPP': 'Alphamethrin',
  'RF-0112-003-PPP': 'Cypermethrin, beta-',
  'RF-0112-004-PPP': 'Cypermethrin',
  'RF-0112-005-PPP': 'Cypermethrin, zeta-',
  'RF-00003320-PAR': '1,1-dimethyl-hydrazine (UDHM)',
  'RF-00003339-PAR': 'Daminozide',
  'RF-00004632-PAR': 'Metam-sodium',
  'RF-00006293-PAR': '1,3-dimethylthiourea',
  'RF-0118-003-PPP': 'Dazomet',
  'RF-0790-001-PPP': 'Methyl-Isothiocyanate',
  'RF-0119-002-PPP': 'DDE, p,p-',
  'RF-0119-003-PPP': 'DDT, o,p-',
  'RF-0119-004-PPP': 'DDD, p,p-',
  'RF-0119-005-PPP': 'DDD, o,p-',
  'RF-0119-006-PPP': 'DDT, p,p-',
  'RF-0119-007-PPP': 'DDE, o,p-',
  'RF-0130-002-PPP': "Dicofol p, p'",
  'RF-0130-003-PPP': "Dicofol o, p'",
  'RF-00002838-PAR': 'Meptyldinocap',
  'RF-00006758-PAR': '2,6-dinitro-4-[(4RS)-octan-4-yl]phenyl (2E)-but-2-enoate',
  'RF-00007610-PAR': 'Dinocap-4-(1-ethyl hexyl)',
  'RF-00007611-PAR': 'Dinocap-4-(1-methyl heptyl)',
  'RF-00007612-PAR': 'Dinocap-6-(1-ethyl hexyl)',
  'RF-0143-002-PPP': 'Dinocap',
  'RF-0149-002-PPP': 'Disulfoton',
  'RF-0149-003-PPP': 'Disulfoton-Sulfoxid',
  'RF-0149-004-PPP': 'Disulfoton-Sulfon',
  'RF-0155-002-PPP': 'Endosulfansulfate',
  'RF-0155-003-PPP': 'Endosulfan, beta-',
  'RF-0155-004-PPP': 'Endosulfan, alpha-',
  'RF-0167-002-PPP': 'Chlorethanol, 2-',
  'RF-0167-003-PPP': 'Ethylene oxide',
  'RF-0173-002-PPP': 'Fenamiphos-Sulfoxid',
  'RF-0173-003-PPP': 'Fenamiphos-Sulfon',
  'RF-0173-004-PPP': 'Fenamiphos',
  'RF-0178-002-PPP': 'Fenchlorphos',
  'RF-0178-003-PPP': 'Fenchlorphos-oxon',
  'RF-0187-002-PPP': 'Fenthion-Sulfoxide',
  'RF-0187-003-PPP': 'Fenthion-Sulfon',
  'RF-0187-004-PPP': 'Fenthion-Oxon',
  'RF-0187-005-PPP': 'Fenthion-Oxonsulfoxide',
  'RF-0187-006-PPP': 'Fenthion',
  'RF-0187-007-PPP': 'Fenthion oxon sulfone',
  'RF-0192-002-PPP': 'Fipronil-Sulfone',
  'RF-0192-003-PPP': 'Fipronil',
  'RF-00007615-PAR': 'N-Fluorophenyl-N-isopropyl',
  'RF-0203-002-PPP': 'Flufenacet',
  'RF-00001688-PAR': 'Formetanate hydrochloride',
  'RF-0223-002-PPP': 'Formetanate',
  'RF-00004675-PAR': 'Phosphonic acid',
  'RF-1059-001-PPP': 'Fosetyl',
  'RF-00003355-PAR': 'Glufosinate',
  'RF-00003360-PAR': 'MPP (3-Methylphosphinicopropionic acid)',
  'RF-00003361-PAR': 'NAG (N-acetyl-glufosinate)',
  'RF-0231-001-PPP': 'Glufosinate-ammonium',
  'RF-0236-004-PPP': 'Heptachlor',
  'RF-0236-005-PPP': 'Heptachlor epoxide',
  'RF-0236-007-PPP': 'Heptachlor endo-epoxide',
  'RF-0236-008-PPP': 'Heptachlor exo-epoxide',
  'RF-00004677-PAR': 'RPA 202248 diketonitrile-metabolite',
  'RF-0259-002-PPP': 'Isoxaflutole',
  'RF-0266-002-PPP': 'Malaoxon',
  'RF-0266-003-PPP': 'Malathion',
  'RF-0271-002-PPP': 'MCPB',
  'RF-0271-005-PPP': 'MCPA',
  'RF-00004644-PAR': '2,4 DNOP',
  'RF-00004645-PAR': '2,4 DNOPC',
  'RF-00007589-PAR': 'Metaflumizone',
  'RF-00007590-PAR': 'Metaflumizone (Z-isomer)',
  'RF-0291-002-PPP': 'Methiocarb',
  'RF-0291-003-PPP': 'Methiocarb-Sulfoxid',
  'RF-0291-004-PPP': 'Methiocarb-Sulfon',
  'RF-00004672-PAR': 'Mevinphos E-isomer',
  'RF-00004673-PAR': 'Mevinphos Z-isomer',
  'RF-0323-003-PPP': 'Demeton-S-Methylsulfone',
  'RF-0323-004-PPP': 'Oxydemeton-methyl',
  'RF-0328-002-PPP': 'Paraoxon-Methyl',
  'RF-0328-003-PPP': 'Parathion-methyl',
  'RF-0336-002-PPP': 'Phorate-Sulfon',
  'RF-0336-003-PPP': 'Phorate',
  'RF-0336-004-PPP': 'Phorate-Sulfoxid',
  'RF-0336-005-PPP': 'Phorate-O-analogue',
  'RF-0336-006-PPP': 'Phorate-oxonsulfone',
  'RF-0336-007-PPP': 'Phorate-oxonsulfoxid',
  'RF-00004648-PAR': '2-hydroxypropoxycarbazone',
  'RF-0362-002-PPP': 'Propoxycarbazone',
  'RF-0374-002-PPP': 'Pyrethrin I',
  'RF-0374-003-PPP': 'Pyrethrin II',
  'RF-0374-004-PPP': 'Jasmolin I',
  'RF-0374-005-PPP': 'Jasmolin II',
  'RF-1066-002-PPP': 'Cinerin I',
  'RF-1066-003-PPP': 'Cinerin II',
  'RF-0376-002-PPP': 'Pyridate',
  'RF-0875-001-PPP': 'CL 9673 (6-chloro-4-hydroxy-3-phenylpyridazin)',
  'RF-0383-002-PPP': 'Quintozene',
  'RF-0383-003-PPP': 'Pentachloroaniline',
  'RF-0393-002-PPP': 'Spinosyn A',
  'RF-0393-003-PPP': 'Spinosyn D',
  'RF-0425-002-PPP': 'Tolylfluanid',
  'RF-0644-001-PPP': 'Dimethylaminosulfotoluidide (DMST)',
  'RF-00003354-PAR':
    'FM-6-1 (N-(4-chloro-2-trifluoromethylphenyl)-n-propoxyacetamidine)',
  'RF-0440-002-PPP': 'Triflumizole',
  'RF-0690-004-PPP': 'Fenvalerate and Esfenvalerate (Sum of RR and SS isomers)',
  'RF-0690-005-PPP': 'Fenvalerate and Esfenvalerate (Sum of RS and SR isomers)',
  'RF-00007489-PAR': 'Dilauryldimonium Chloride',
  'RF-00007495-PAR': 'Dioctyldimonium Chloride',
  'RF-00007627-PAR': 'DDAC-C10 (Didecyldimethylammonium chloride)',
  'RF-00006819-PAR': 'Pinoxaden metabolite (SYN 502836)',
  'RF-00006827-PAR': 'Pinoxaden metabolite (SYN 505164)',
  'RF-0381-001-PPP': 'Quinmerac',
  'RF-00007283-PAR': '7-chloroquinoline-3,8-dicarboxylic acid',
  'RF-00007284-PAR': '7-chloro-3-(hydroxymethyl)quinoline-8-carboxylic acid',
};
