import { z } from 'zod';

export const QuantityUnit = z.enum(
  [
    'G001A',
    'G002A',
    'G003A',
    'G005A',
    'G006A',
    'G007A',
    'G008A',
    'G009A',
    'G010A',
    'G011A',
    'G012A',
    'G013A',
    'G014A',
    'G015A',
    'G016A',
    'G017A',
    'G018A',
    'G019A',
    'G020A',
    'G021A',
    'G022A',
    'G023A',
    'G024A',
    'G025A',
    'G026A',
    'G027A',
    'G028A',
    'G029A',
    'G030A',
    'G031A',
    'G032A',
    'G033A',
    'G034A',
    'G035A',
    'G036A',
    'G037A',
    'G038A',
    'G039A',
    'G040A',
    'G041A',
    'G042A',
    'G043A',
    'G044A',
    'G045A',
    'G046A',
    'G047A',
    'G048A',
    'G049A',
    'G050A',
    'G051A',
    'G052A',
    'G053A',
    'G054A',
    'G055A',
    'G056A',
    'G057A',
    'G058A',
    'G059A',
    'G060A',
    'G061A',
    'G062A',
    'G063A',
    'G064A',
    'G065A',
    'G066A',
    'G067A',
    'G068A',
    'G069A',
    'G070A',
    'G071A',
    'G072A',
    'G073A',
    'G074A',
    'G075A',
    'G076A',
    'G077A',
    'G078A',
    'G079A',
    'G176A',
    'G080A',
    'G081A',
    'G083A',
    'G084A',
    'G085A',
    'G086A',
    'G087A',
    'G088A',
    'G082A',
    'G090A',
    'G091A',
    'G092A',
    'G093A',
    'G094A',
    'G095A',
    'G096A',
    'G097A',
    'G098A',
    'G099A',
    'G089A',
    'G101A',
    'G102A',
    'G103A',
    'G104A',
    'G105A',
    'G106A',
    'G107A',
    'G108A',
    'G109A',
    'G110A',
    'G111A',
    'G112A',
    'G113A',
    'G114A',
    'G115A',
    'G116A',
    'G117A',
    'G118A',
    'G119A',
    'G120A',
    'G121A',
    'G122A',
    'G123A',
    'G124A',
    'G125A',
    'G126A',
    'G127A',
    'G128A',
    'G129A',
    'G130A',
    'G131A',
    'G132A',
    'G133A',
    'G134A',
    'G135A',
    'G100A',
    'G137A',
    'G138A',
    'G139A',
    'G140A',
    'G141A',
    'G142A',
    'G143A',
    'G144A',
    'G145A',
    'G146A',
    'G147A',
    'G148A',
    'G149A',
    'G150A',
    'G151A',
    'G152A',
    'G153A',
    'G154A',
    'G155A',
    'G156A',
    'G157A',
    'G159A',
    'G160A',
    'G162A',
    'G163A',
    'G164A',
    'G165A',
    'G166A',
    'G167A',
    'G168A',
    'G170A',
    'G171A',
    'G172A',
    'G173A',
    'G174A',
    'G175A',
    'G136A',
    'G177A',
    'G178A',
    'G179A',
    'G180A',
    'G181A',
    'G182A',
    'G183A',
    'G184A',
    'G185A',
    'G187A',
    'G188A',
    'G190A',
    'G191A',
    'G193A',
    'G194A',
    'G198A',
    'G199A',
    'G202A',
    'G205A',
    'G206A',
    'G207A',
    'G208A',
    'G209A',
    'G210A',
    'G211A',
    'G212A',
    'G213A',
    'G214A',
    'G215A',
    'G216A',
    'G217A',
    'G218A',
    'G219A',
    'G220A',
    'G221A',
    'G222A',
    'G223A',
    'G224A',
    'G225A',
    'G226A',
    'G230A',
    'G231A',
    'G232A',
    'G233A',
    'G234A',
    'G235A',
    'G236A',
    'G237A',
    'G238A',
    'G239A',
    'G240A',
    'G241A',
    'G242A',
    'G243A',
    'G244A',
    'G245A',
    'G246A',
    'G247A',
    'G248A',
    'G249A',
    'G250A',
    'G251A',
    'G252A',
    'G253A',
    'G254A',
    'G255A',
    'G256A',
    'G257A',
    'G258A',
    'G259A',
    'G260A',
    'G261A',
    'G281A',
    'G282A',
  ],
  {
    errorMap: () => ({
      message: "Veuillez renseigner l'unité de mesure.",
    }),
  }
);

export type QuantityUnit = z.infer<typeof QuantityUnit>;

export const QuantityUnitList: QuantityUnit[] = QuantityUnit.options;

const PrimaryQuantityUnits: QuantityUnit[] = [
  'G167A',
  'G148A',
  'G155A',
  'G156A',
  'G005A',
];
export const PrimaryQuantityUnitList: QuantityUnit[] = QuantityUnit.extract(
  PrimaryQuantityUnits as [QuantityUnit, ...QuantityUnit[]]
).options;

export const SecondaryQuantityUnitList: QuantityUnit[] = QuantityUnit.exclude(
  PrimaryQuantityUnits as [QuantityUnit, ...QuantityUnit[]]
).options;

export const QuantityUnitLabels: Record<QuantityUnit, string> = {
  G001A: 'Bq/kg',
  G002A: 'Bq/L',
  G003A: 'Bq/day and person',
  G005A: 'Unit',
  G006A: 'FAU',
  G007A: 'FNU',
  G008A: '°',
  G009A: '°Brix',
  G010A: '°dH',
  G011A: '',
  G012A: '',
  G013A: 'g/100 g',
  G014A: 'g/100 mL',
  G015A: 'g/kg',
  G016A: 'g/L',
  G017A: 'g/mL',
  G018A: 'g/pack',
  G019A: 'g/sample',
  G020A: 'Gy',
  G021A: 'pour 10 g',
  G022A: 'pour 100 g',
  G023A: 'pour 100 mL',
  G024A: 'pour 20 mL',
  G025A: 'pour 25 g',
  G026A: 'pour 250 mL',
  G027A: 'pour 50 mL',
  G028A: 'pour 1 g',
  G029A: 'pour kg',
  G030A: '',
  G031A: '',
  G032A: 'pour cm²',
  G033A: 'kg/25 g',
  G034A: 'kg/sample',
  G035A: 'kJ/100 g',
  G036A: 'CFU',
  G037A: 'CFU/100 mL',
  G038A: 'CFU/25 g',
  G039A: 'CFU/25 mL',
  G040A: 'CFU/250 mL',
  G041A: 'CFU/50 mL',
  G042A: 'CFU/g',
  G043A: 'CFU/mL',
  G044A: 'CFU/cm²',
  G045A: 'µg/0,01 g',
  G046A: 'µg/100 g',
  G047A: 'µg/100 mL',
  G048A: 'µg/25 g',
  G049A: 'µg/g',
  G050A: 'µg/kg',
  G051A: 'µg/L',
  G052A: 'µg/mL',
  G053A: 'µg/dm²',
  G054A: 'µg/cm²/Week',
  G055A: 'µg/piece',
  G056A: 'µS/cm',
  G057A: 'mg/100 g',
  G058A: 'mg/100 mL',
  G059A: 'mg/container',
  G060A: 'mg/g',
  G061A: 'mg/kg',
  G062A: 'mg/L',
  G063A: 'mg/mg',
  G064A: 'mg/dm²',
  G065A: 'mg/piece',
  G066A: 'mval/kg',
  G067A: 'mL/10 g',
  G068A: 'mL/100 g',
  G069A: 'mL/100 mL',
  G070A: 'mmol/kg',
  G071A: 'mmol/L',
  G072A: 'mS/m',
  G073A: 'mS/cm',
  G074A: 'min',
  G075A: 'MPN/g',
  G076A: 'ng/g',
  G077A: 'ng/kg',
  G078A: 'ng/L',
  G079A: 'ng/mL',
  G176A: 'pour PORTION',
  G080A: 'pg/g',
  G081A: 'pg/kg',
  G083A: '‰',
  G084A: '%/100 g',
  G085A: '%/25 g',
  G086A: '%/mas',
  G087A: '%/sample',
  G088A: '%/vol',
  G082A: 'PFU',
  G090A: 'cm²',
  G091A: 'Qualitative detection/0,1 g',
  G092A: 'Qualitative detection/10 g',
  G093A: 'Qualitative detection/100 g',
  G094A: 'Qualitative detection/ 25 g',
  G095A: 'Qualitative detection/g',
  G096A: 'Qualitative detection/Sample',
  G097A: 's',
  G098A: 'Piece/quantityl/kg',
  G099A: 'h',
  G089A: 'mm²',
  G101A: 'C',
  G102A: '',
  G103A: '',
  G104A: 'hL',
  G105A: 'IU',
  G106A: 'kJ',
  G107A: 'kcal',
  G108A: 'm³',
  G109A: '',
  G110A: '',
  G111A: 'm',
  G112A: 'µL',
  G113A: 'µS',
  G114A: 'gEq',
  G115A: 'mmol',
  G116A: 'mN',
  G117A: 'mOsmol',
  G118A: 'mS',
  G119A: 'mol',
  G120A: 'ng',
  G121A: 'nL',
  G122A: 'N',
  G123A: '',
  G124A: 'Pa',
  G125A: 'pg',
  G126A: 'pL',
  G127A: '',
  G128A: '',
  G129A: 'dm²',
  G130A: 'm²',
  G131A: 'cm²/week',
  G132A: 'S',
  G133A: 'Piece',
  G134A: 'D',
  G135A: '',
  G100A: 't',
  G137A: 'cL',
  G138A: '%',
  G139A: '% area',
  G140A: 'percent/assay',
  G141A: '°C',
  G142A: 'µgL/10 g',
  G143A: 'µL/g',
  G144A: 'µL/kg',
  G145A: 'µL/L',
  G146A: 'cm',
  G147A: 'EBC',
  G148A: 'g',
  G149A: 'kg/m²',
  G150A: 'kg/m³',
  G151A: 'kJ/100 mL',
  G152A: 'kJ/L',
  G153A: 'kJ/sample',
  G154A: 'L',
  G155A: 'mg',
  G156A: 'mL',
  G157A: 'mm',
  G159A: 'mval',
  G160A: 'mval/L',
  G162A: 'nC',
  G163A: 'NTU',
  G164A: 'pour 0.1 g',
  G165A: 'pour ml',
  G166A: 'bar',
  G167A: 'kg',
  G168A: '°Oe',
  G170A: 'Bq/g carbon',
  G171A: 'g/hl',
  G172A: 'Gram/meal',
  G173A: 'g/daily portion',
  G174A: 'pour 25 millilitre',
  G175A: 'pour litre',
  G136A: 'Vol',
  G177A: 'kcal/100 g',
  G178A: 'kcal/100 ml',
  G179A: 'kcal/l',
  G180A: 'kcal/meal',
  G181A: 'kcal/portion',
  G182A: 'kcal/daily portion',
  G183A: 'kJ/meal',
  G184A: 'kJ/Portion',
  G185A: 'kJ/Daily portion',
  G187A: 'meq O2/kg',
  G188A: 'mg O2/l',
  G190A: 'mg/cm²',
  G191A: 'mg/ml',
  G193A: 'mmol/100 g',
  G194A: 'mSv/Jahr',
  G198A: 'Holding',
  G199A: 'Animal',
  G202A: 'herd/flock',
  G205A: 'week',
  G206A: 'month',
  G207A: 'year',
  G208A: 'µg/day',
  G209A: 'mg/day',
  G210A: 'µg/kg bw/day',
  G211A: 'mg/kg bw/day',
  G212A: 'g/kg bw/day',
  G213A: 'g/person/day',
  G214A: 'ng/kg bw/day',
  G215A: 'ng/kg bw/week',
  G216A: 'µg/kg bw/week',
  G217A: 'mg/kg bw/week',
  G218A: 'g/kg bw/week',
  G219A: 'ng/kg bw/meal',
  G220A: 'µg/kg bw/meal',
  G221A: 'mg/kg bw/meal',
  G222A: 'g/kg bw/meal',
  G223A: 'ng/kg bw',
  G224A: 'µg/kg bw',
  G225A: 'mg/kg bw',
  G226A: 'g/kg bw',
  G230A: 'g/ha',
  G231A: 'ml/ha',
  G232A: 'mg/person/day',
  G233A: 'g/day',
  G234A: 'pg/kg bw',
  G235A: 'mg/m³',
  G236A: 'Event/day',
  G237A: 'Event/year',
  G238A: 'ha',
  G239A: 'kg/day',
  G240A: 'kg/year',
  G241A: 'km',
  G242A: 'l/min',
  G243A: 'pen',
  G244A: 'other',
  G245A: 'μg/m3',
  G246A: 'μg/m2',
  G247A: 'μg/cm2',
  G248A: 'kg/ha',
  G249A: 'l/ha',
  G250A: 'µg/sample',
  G251A: 'µg/football',
  G252A: 'IU/g',
  G253A: 'ml/kg',
  G254A: 'kg/l',
  G255A: 'Pieces/day',
  G256A: 'µl/cm2',
  G257A: 'seeds/ha',
  G258A: 'W/m²',
  G259A: 'MJ/m²/h',
  G260A: 'mg/seed',
  G261A: 'mgeq/g',
  G281A: 'ng/cm2',
  G282A: 'MU/mg',
};
