import { MatrixKindLabels } from '../MatrixKind';
import { Matrix } from './Matrix';

export const MatrixLabels: Record<Matrix, string> = {
  A000L: 'Grains de céréales (et grains de type céréale)',
  A001X: 'Mélange de grains',
  A0D9Y: 'Orge et similaires',
  A000P: "Grains d'orge",
  A002K: "Grain d'orge, perlé",
  A04KH: 'Sarrasin, autres pseudo-céréales et similaires',
  A000N: 'Sarrasin',
  A000M: "Grains d'amarante",
  A0D9X: "Grain d'amarante queue-de-renard",
  A0D9V: "Grains d'amarante rouge",
  A0D9T: "Grain d'amarante plume de Prince de Galles",
  A000Q: 'Grain de canihua',
  A000R: 'Grain de quinoa',
  A000S: 'Maïs et similaires',
  A000V: 'Grains de maïs à popcorn',
  A000X: 'Grain de téosinte',
  A000T: 'Grain de maïs',
  A0D9S: 'Grain de maïs indien',
  A000Y: 'Millet commun et similaires',
  A001B: 'Grain de millet commun',
  A0CGK: 'Grain de fonio noir',
  A0BJZ: "Grain d'alpiste",
  A000B: "Grain d'éleusine",
  A000D: 'Grain de millet des oiseaux',
  A002A: 'Grain de Larme-de-Job',
  A000E: 'Grain de petit millet',
  A001A: 'Grain de millet perlé',
  A000A: 'Grain de teff',
  A001Z: 'Grain de fonio blanc',
  A000Z: 'Millet pied-de-coq',
  A000F: 'Avoine et similaires',
  A000G: "Grain d'avoine",
  A000H: "Grain d'avoine rouge",
  A001C: 'Riz et similaires',
  A001D: 'Grain de riz',
  A001E: 'Grain de riz brun',
  A001F: 'Grain de riz long',
  A001G: 'Grain de riz mélangé',
  A001H: 'Grain de riz rouge',
  A003D: 'Grain de riz poli',
  A003E: 'Grain de riz étuvé',
  A0F6M: 'Grain de riz gluant',
  A0CGL: 'Grain de riz africain',
  A0CGM: 'Hybride Nerica®',
  A001J: 'Grain de riz indien',
  A0D9R: 'Seigle et similaires',
  A001K: 'Grain de seigle',
  A0D9Q: 'Sorgho et similaires',
  A001L: 'Grain de sorgho',
  A0D9P: 'Grain de dourra',
  A0D9N: 'Grain de sorgho du Soudan',
  A0D9M: 'Autres espèces du genre Sorghum, non désignées par ailleurs',
  A001M: 'Blé et similaires',
  A001N: 'Grain de blé commun',
  A001P: 'Grain de blé dur',
  A001Q: 'Grain de blé amidonnier',
  A001T: 'Grain de blé de Khorasan',
  A001S: 'Grain de petit épeautre',
  A001R: "Grain d'épeautre",
  A001V: 'Grain de triticale',
  A170H: 'Tritordeum',
  A0D9K: 'Autres espèces du genre Triticum, non désignées par ailleurs',
  A001Y: 'Autres céréales',
  A0ETP: 'Dérivés primaires de céréales',
  A0BY1: 'Gruaux',
  A065N: "Gruaux d'orge",
  A002H: 'Gruaux de sarrasin',
  A002V: 'Gruaux de millet',
  A002Z: "Gruaux d'avoine",
  A003P: 'Gruaux de seigle',
  A004E: 'Gruaux de blé',
  A004G: 'Boulgour',
  A0ETL: 'Semoule',
  A002N: 'Semoule de maïs',
  A004F: 'Semoule de blé',
  A0F6Q: 'Semoule de riz',
  A04KS: 'Farines de céréales et de grains de type céréale',
  A004S: 'Mélange de farine (blé/seigle/orge/avoine et autre)',
  A002E: "Farine d'amarante",
  A002L: "Farine d'orge",
  A002G: 'Farine de sarrasin',
  A0C0Z: 'Maïs moulu',
  A002Q: 'Farine de maïs',
  A002P: 'Semoule de maïs',
  A002T: 'Farine de millet',
  A002Y: "Farine d'avoine",
  A003F: 'Farine de riz',
  A003J: 'Farine de seigle',
  A003K: 'Farine de seigle, raffinée',
  A003L: 'Farine de seigle, semi-complète',
  A003M: 'Farine de seigle, complète',
  A003N: 'Farine de seigle, sans gluten',
  A003T: 'Farine de sorgho',
  A004H: "Farine d'épeautre",
  A004J: "Farine d'épeautre, raffinée",
  A004K: "Farine d'épeautre, semi-complète",
  A004L: "Farine d'épeautre, complète",
  A003X: 'Farine de blé',
  A003Y: 'Farine de blé, blanche',
  A003Z: 'Farine Graham',
  A004A: 'Farine de blé, brune',
  A004B: 'Farine de blé, complète',
  A004C: 'Farine de blé dur',
  A004D: 'Farine à chapati',
  A04KT:
    'Farines de céréales et de grains de type céréale non mentionnées séparément.',
  A002C: 'Son de céréales',
  A003B: "Son d'avoine",
  A003Q: 'Son de seigle',
  A004P: 'Son de blé',
  A0F6P: 'Son de riz',
  A0ETN: 'Germes de céréales',
  A016K: 'Germes de maïs',
  A004Q: 'Germe de blé',
  A04QY: 'Flocons de céréales et similaires',
  A00EM: 'Flocons transformés à base de céréales mélangées',
  A00DA: "Flocons transformés à base d'orge",
  A00DD: 'Flocons transformés à base de maïs',
  A00DN: "Flocons transformés à base d'avoine",
  A00DK: "Flocons d'avoine à forte teneur en son",
  A00DS: 'Flocons transformés à base de riz',
  A00DY: 'Flocons transformés à base de seigle',
  A00EF: 'Flocons transformés à base de blé',
  A00EC: 'Germes de blé aplatis en flocons',
  A00ED: 'Son de blé aplati en flocons',
  A0F4Q: 'Céréales pour petit-déjeuner extrudées',
  A04LL: 'Céréales éclatées',
  A00CZ: 'Orge éclaté',
  A00DC: 'Popcorn (maïs éclaté)',
  A00DM: 'Avoine éclatée',
  A00DR: 'Riz éclaté',
  A00DX: 'Seigle éclaté',
  A00EE: 'Blé éclaté',
  A04LJ: 'Grains de céréales aplatis',
  A00EH: 'Mélange de grains de céréales aplatis',
  A00CY: "Grains d'orge aplatis",
  A00DF: 'Grains de millet aplatis',
  A00DH: "Grains d'avoine aplatis",
  A00DJ: "Grains d'avoine aplatis, préparation instantanée",
  A00DL: "Grains d'avoine aplatis, complets",
  A00DQ: 'Grains de riz aplatis',
  A00DV: 'Grains de seigle aplatis',
  A00EA: "Grains d'épeautre aplatis",
  A00EB: 'Grains de blé aplatis',
  A00EY: 'Barres de céréales',
  A00EZ: 'Barres de céréales natures',
  A00FA: 'Barres de céréales mélangées',
  A00EJ: 'Muesli et similaires',
  A00EK: 'Muesli nature',
  A00EL: 'Muesli mélangé',
  A00EN: 'Porridge (sous forme sèche, à diluer)',
  A00EQ: "Porridge d'avoine",
  A00ER: 'Porridge de riz',
  A00ES: 'Porridge de seigle',
  A00ET: 'Porridge de farine de maïs',
  A00EV: 'Porridge de semoule de blé',
  A00EX: "Porridge d'orge",
  A16GM: 'Mélange de farines grillées pour les produits type porridge',
  A0F0V: 'Porridge (prêt à consommer)',
  A0F0T: 'Porridge à base de lait (prêt à consommer)',
  A0ESM: "Porridge à base d'eau (prêt à consommer)",
  A007D: 'Pâtes alimentaires et produits similaires',
  A007E: 'Pâtes natures (non fourrées), non cuites',
  A04LC: 'Pâtes au blé complet',
  A007F: 'Pâtes fraîches',
  A007G: 'Pâtes fraîches aux œufs',
  A007J: 'Pâtes fraîches au blé dur',
  A007L: 'Pâtes sèches',
  A007M: 'Pâtes sèches aux œufs',
  A007P: 'Pâtes sèches au blé dur',
  A0ERE: 'Pâtes farcies (fourrées)',
  A007T: 'Pâtes fraîches fourrées',
  A007V: 'Pâtes fraîches aux œufs fourrées',
  A007X: 'Pâtes fraîches au blé dur fourrées',
  A007Y: 'Pâtes sèches fourrées',
  A007Z: 'Pâtes sèches aux œufs fourrées',
  A008A: 'Pâtes sèches au blé dur fourrées',
  A04LF: 'Produits de type pâtes',
  A008B: 'Pâtes, sans gluten',
  A008D: 'Gnocchis',
  A008E: 'Nouilles translucides',
  A008F: 'Nouilles de riz',
  A007R: 'Nouilles asiatiques autres que nouilles translucides',
  A008C: 'Couscous',
  A008G: 'Pâte crue et prémélanges',
  A008H: 'Pâte à pain au levain',
  A008J: 'Pâte à pain/pizza au levain',
  A008K: 'Pâte à pain au levain naturel',
  A008L: 'Pâte sucrée au levain',
  A008M: 'Pâte sucrée fine au levain (brioche)',
  A008N: 'Pâte au levain à fort contenu',
  A008P: 'Pâte levée à la levure chimique',
  A008Q: 'Pâte à pain rapide',
  A008R: 'Pâte fine à fort contenu sans levure',
  A008V: 'Pâte/mélange à gaufres',
  A008T: 'Pâte non levée',
  A008X: 'Pâtes brisées et similaires',
  A008Y: 'Pâte brisée',
  A008Z: 'Pâte fine sans levure',
  A009A: 'Pâte à tarte à la française',
  A009B: 'Pâtes brisées sucrées (pâtes sucrées, pâtes sablées)',
  A009C: 'Pâte brisée sucrée (pâte sucrée)',
  A009D: 'Pâte à sablés',
  A009E: 'Pâte à sablés à la poche',
  A009F: "Pâte d'amandes douces",
  A009G: 'Pâte à choux et similaires',
  A009H: 'Pâte à choux',
  A009J: 'Pré-mélange/pâte pour gâteaux',
  A009K: 'Pâte à génoise',
  A008S: 'Mélange/pâte pour Baumkuchen',
  A009L: 'Pâtes feuilletées',
  A009M: 'Pâte feuilletée',
  A009N: 'Pâte à strudel',
  A16FH: 'Pâte filo',
  A009P: 'Pâtes diverses',
  A009Q: "Pâte à pain d'épices",
  A009R: 'Pâte à base de quark',
  A009S: 'Pâte à base de suif de bœuf',
  A0CSK: 'Pré-mélanges (secs) pour produits cuits',
  A0CSL: 'Pré-mélanges (secs) pour pains',
  A0CSM: 'Pré-mélanges (secs) pour gâteaux',
  A0CSN: 'Pré-mélanges (secs) pour pancakes',
  A0BX1: 'Produits de boulangerie',
  A004V: 'Pain et produits similaires',
  A0DRD: 'Pain',
  A0BY0: 'Pain levé et similaires',
  A005L: 'Pain et petits pains multicéréales',
  A005M: "Pain et petits pains à base d'un mélange de seigle et de blé",
  A005N: 'Pain de blé et de seigle, à base de farine raffinée',
  A005P: 'Pain de blé et de seigle, complet',
  A005Q: 'Pain et petits pains, multicéréales (pas uniquement blé et seigle)',
  A004X: 'Pain et petits pains de blé.',
  A004Y: 'Pain et petits pains de blé, blancs (farine raffinée)',
  A004Z: 'Pain et petits pains de blé, blancs avec maïs',
  A005A: 'Pain et petits pains de blé, blancs avec pomme de terre',
  A005B: 'Pain et petits pains de blé, blancs avec riz',
  A005C: 'Pain et petits pains de blé, blancs avec soja',
  A005D: 'Pain et petits pains de blé, semi-complets',
  A005E: 'Pain et petits pains de blé, bruns ou complets',
  A005F: 'Pain et petits pains pur seigle',
  A005G: 'Pain de seigle, à base de farine raffinée',
  A005H: 'Pain de seigle complet',
  A005J: 'Pain de seigle noir',
  A005K: 'Pain et petits pains avec ingrédients spéciaux ajoutés',
  A0BB2: 'Pain pour sandwich (pain rond à hamburger)',
  A005V: 'Bretzels',
  A04KZ: 'Pain non levé ou plat et similaires',
  A006Q: 'Base de pizza, cuite',
  A006R: 'Pains traditionnels non levés',
  A006S: 'Pain pita',
  A006T: 'Matza',
  A006V: 'Tortilla, galette mexicaine',
  A006X: 'Roti',
  A006Y: 'Chapati',
  A16FX: 'Pâte à pain frite',
  A005Y: 'Crackers et gressins',
  A00FG: 'Bâtonnets type apéritif, salés',
  A0CHT: 'Pain à panification sèche',
  A006A: 'Pain de seigle à panification sèche',
  A006B: 'Pain au seigle, à panification sèche, complet',
  A006C: 'Pain de seigle à panification sèche, à base de farine raffinée',
  A006D: 'Pain de blé à panification sèche',
  A006E: 'Pain au blé, à panification sèche, complet',
  A006F: 'Pain de blé à panification sèche, à base de farine raffinée',
  A005Z: 'Pain à base de grains extrudés, pressés ou soufflés',
  A006G: 'Pain de riz extrudé',
  A006H: 'Pain de céréales à texture soufflée',
  A006J: 'Pain de blé à texture soufflée',
  A006K: 'Pain de riz à texture soufflée',
  A006L: 'Pain de maïs à texture soufflée',
  A006M: 'Biscotte',
  A006N: 'Biscotte, à base de farine raffinée',
  A006P: 'Biscotte complète',
  A0ERB: 'Substitut de pain',
  A005R: 'Pain sans gluten',
  A005S: 'Pain blanc sans gluten',
  A005T: 'Pain brun sans gluten',
  A006Z: 'Autres produits de panification',
  A007A: 'Chapelure',
  A007B: 'Croûtons',
  A007C: 'Farce à base de pain',
  A009T: 'Produits de boulangerie fine',
  A009V: 'Biscuits',
  A009X: 'Biscuits, sucrés, natures',
  A009Y: 'Biscuits au beurre',
  A009Z: 'Biscuits, chocolat',
  A00AA: 'Biscuits, sucrés, au blé complet',
  A00AB: "Biscuits à base de farine d'avoine",
  A00AC: "Biscuits à base de farine d'épeautre",
  A00AD: 'Spéculos',
  A16FJ: 'Biscuit à la cuillère',
  A00AE: 'Biscuits fourrés, garnis ou enrobés',
  A0BYR: 'Gaufrettes',
  A00AG: 'Pâtisserie à base de pâte à choux',
  A00AH: 'Éclair',
  A00AJ: 'Beignets',
  A00AK: 'Profiterole',
  A00AL: 'Croquembouche',
  A00AM: 'Gougère',
  A00AN: 'Gâteaux',
  A00AP: 'Gâteaux natures',
  A00AQ: 'Génoise',
  A00BB: 'Génoise roulée',
  A00AR: 'Cheesecake',
  A00AS: 'Cheesecake à la crème',
  A00AT: 'Génoise à la crème et au fromage frais',
  A00AV: 'Gâteau à la crème',
  A00AX: 'Gâteau à la crème pâtissière',
  A00AY: 'Génoise à la crème pâtissière',
  A00AZ: 'Gâteau à la crème aux noix',
  A00BA: 'Gâteau aux fruits',
  A00BC: 'Muffins',
  A00BD: 'Tarte meringuée',
  A0C6L: 'Gâteau cylindrique à plusieurs strates',
  A00BE: 'Gâteau en forme de pyramide',
  A0C6M: 'Baumkuchen et similaires',
  A00BF: 'Gâteaux à base de chocolat',
  A00BG: 'Gâteau au chocolat',
  A00BH: 'Gâteau au chocolat avec des fruits',
  A00BJ: 'Gâteau marbré, avec du chocolat',
  A00BK: 'Pâtisserie au levain',
  A00BL: 'Petits pains briochés',
  A00BM: 'Croissant',
  A00BN: 'Pain au chocolat',
  A00BP: 'Croissant à la crème',
  A00BQ: 'Croissant à la confiture',
  A00BR: 'Beignets berlinois',
  A00BS: 'Kringkles',
  A00BT: 'Produits briochés',
  A16FV: 'Pâtisserie à base de pain non levé',
  A16FY: 'Pâte sucrée frite',
  A00BV: 'Pâte brisée (tourtes, tartes)',
  A00BX: 'Tarte au flan',
  A00BY: 'Flan à la rhubarbe',
  A00BZ: 'Tartes/tourtes aux fruits',
  A00CB: 'Tourte au massepain',
  A00CC: 'Feuilleté',
  A00CD: 'Croissant feuilleté',
  A00CE: 'Feuilleté au chocolat',
  A00CF: 'Strudel aux pommes',
  A00CG: 'Strudel au fromage à la crème',
  A00CH: 'Baklava, gâteau oriental',
  A00CJ: 'Pâtisseries variées',
  A00CK: 'Chausson sucré',
  A00CL: 'Pancakes',
  A00CM: 'Scones et similaires',
  A00CN: 'Macarons',
  A00CP: 'Meringue',
  A00CQ: 'Gaufres',
  A00CR: 'Gâteaux aux épices',
  A00CS: 'Lebkuchen',
  A00CT: "Pain d'épice",
  A16EQ:
    'Pudding à base de produits céréaliers et/ou noix/fruits et similaires',
  A0DLC: 'Mâche et similaires',
  A00KT: 'Mâche',
  A00KV: 'Laitues italiennes',
  A0DLB: 'Laitues et similaires',
  A00KX: 'Laitues (générique)',
  A00KZ: 'Laitues iceberg',
  A00LA: 'Laitues à couper',
  A00LB: 'Lollo rosso',
  A00KY: 'Laitues pommées',
  A00LC: 'Laitues romaines',
  A00LD: 'Scaroles et similaires',
  A00LE: 'Scaroles',
  A00LF: 'Chicorées frisées',
  A00MD: 'Pissenlits',
  A0DLA: 'Puntarelle',
  A00LG: 'Radicchio',
  A00LJ: 'Chicorées pain-de-sucre',
  A00LH: 'Chicorées sauvages',
  A0DKQ: 'Cressons de terre et similaires',
  A00LL: 'Cressons de terre',
  A00YT: "Cresson d'hiver",
  A00LM: 'Roquette et similaires',
  A00LN: 'Roquette',
  A00LP: 'Roquette sauvage/Roquette jaune',
  A0DKP: 'Feuilles de moutarde brune et similaires',
  A00LQ: 'Feuilles de moutarde brune',
  A00MA:
    'Jeunes pousses (y compris les espèces du genre Brassica) et similaires',
  A0DKN: 'Jeunes feuilles de cardes',
  A0DKL: 'Jeunes feuilles de scaroles',
  A00LZ: "Jeunes feuilles de moutarde de l'Inde",
  A0DKM: 'Jeunes feuilles de laitues',
  A0DKK: "Jeunes feuilles d'épinards",
  A0DKJ: 'Autres espèces récoltées au stade des jeunes feuilles',
  A00LR: 'Jeunes pousses de Brassica',
  A00LV: 'Fanes de navette',
  A00LX: 'Fanes de rutabaga',
  A00MB: 'Feuilles de radis (y compris les collets de radis)',
  A00NF: 'Autre laitues et salades',
  A00NZ: 'Cochléaire',
  A00NG: 'Momordiques à feuilles de vigne',
  A00NH: 'Lyciet de Chine',
  A00NK: 'Feuilles de papaye',
  A00NL: 'Morelle noire',
  A00NM: 'Feuilles de poivron',
  A00NN: 'Feuilles de plantain',
  A00NP: "Raisin d'Amérique",
  A00NQ: 'Feuilles de salsifis',
  A00NR: 'Feuilles de séné',
  A00NS: 'Feuilles de laiteron maraicher',
  A00NV: 'Feuilles de taro',
  A16QH: 'Melientha grass',
  A16QL: 'Pumpkin leaves',
  A16QM: "Jew's mallow leaves",
  A16QR: 'Blumea leaves',
  A16QS: 'Chayote leaves',
  A00NY: 'Feuilles de sornet',
  A00ME: 'Feuilles de populage des marais',
  A00MC: 'Feuilles de mauve',
  A0F6J: 'Feuilles de baobab',
  A00MG: 'Feuilles de type épinard',
  A00MH: 'Épinards et similaires',
  A00MJ: 'Épinards',
  A00ML: "Feuilles d'amarante",
  A0DKH: "Feuilles d'amarante pourpre",
  A0DKG: "Feuilles d'amarante rouge",
  A0DKF: "Feuilles d'amarante paniculée",
  A0DKE: "Feuilles d'amarante de Chine",
  A0DKD: "Feuilles d'amarante verte",
  A0DKC: 'Feuilles de jasmin sauvage',
  A0DKB: 'Vernonies',
  A0DKA: 'Feuilles de haricot à œil noir',
  A00NJ: 'Feuilles de manioc',
  A0DJZ: 'Feuilles de chrysanthèmes à couronne',
  A00MK: 'Épinards de Nouvelle-Zélande/Tétragones',
  A00MV: "Feuilles d'arroche des jardins",
  A00NX: 'Feuilles de patate douce',
  A00NT: 'Feuilles de macabo',
  A00MN: 'Pourpiers et similaires',
  A00MP: 'Pourpiers',
  A00MR: 'Soude commune ',
  A00MS: 'Salicornes',
  A0DJY: 'Fenouils marins/Perce-pierres',
  A0DJX: 'Asters maritimes',
  A0DJV: 'Lavandes de mer',
  A00MQ: "Pourpiers d'hiver",
  A170X: 'Hottentot fig',
  A0DJT: 'Cardes et similaires',
  A00MX: 'Cardes',
  A0DJS: 'Feuilles de betterave',
  A0DJR: 'Bettes à cardes',
  A00MY: 'Autres épinards et feuilles similaires',
  A00MZ: 'Chénopode',
  A00MF: 'Feuilles de roselle',
  A0DJQ: 'Feuilles de vigne et similaires',
  A00NB: 'Feuilles de vigne',
  A0DJP: 'Acacia penné',
  A00MM: 'Épinards de Malabar',
  A00NC: "Cressons d'eau et similaires",
  A00ND: "Cressons d'eau",
  A00NA: "Liseron d'eau",
  A0DJN: "Trèfles d'eau",
  A0DJM: "Mimosas d'eau",
  A0DJL: 'Endives et similaires',
  A00NE: 'Endives',
  A0DJK: 'Feuilles de pissenlit (forcées)',
  A00FT: 'Choux pommés',
  A0DLG: 'Choux de Bruxelles et similaires',
  A00FV: 'Choux de Bruxelles',
  A00FX: 'Choux pommés et similaires',
  A00FY: 'Choux pommés',
  A00FZ: 'Choux pointus',
  A00GA: 'Choux rouges',
  A00GB: 'Choux de Savoie',
  A00GC: 'Choux blancs',
  A00GD: 'Autres choux pommés (p)',
  A00GE: 'Choux feuilles',
  A00GF: 'Choux pommés chinois et similaires',
  A00GG: 'Choux pommés chinois',
  A0DLF: 'Pak-choï en rosette',
  A00GH: "Moutardes de l'Inde",
  A00LT: 'Komatsuna',
  A00LS: 'Mizuna',
  A00GJ: 'Pak-choï',
  A00LY: 'Collets de navet',
  A00GL: 'Choux verts et similaires',
  A00GM: 'Choux verts frisés',
  A00GN: 'Choux fourragers',
  A00GR: 'Chou moëllier',
  A0DLE: 'Choux (géants) de Jersey',
  A00GP: 'Feuilles de chou-rave',
  A0DLD: 'Choux à faucher',
  A00GQ: 'Choux à grosses côtes',
  A170V: 'Black kale',
  A00GS: 'Autres choux feuilles',
  A00GT: 'Chou marin',
  A0F6Y: 'Fougères',
  A0F6Z: 'Osmonde cannelle',
  A0F7A: "Fougère plume d'autruche",
  A0F7B: 'Fougère aigle',
  A0F7C: 'Fougère légume',
  A00LK: 'Cressons',
  A00SM: 'Graines germées de cresson alénois',
  A00SH: 'Graines germées de luzerne cultivée',
  A0DKX: 'Graines germées de ciboulette chinoise',
  A0DKV: 'Graines germées de brocolis',
  A0DKT: 'Graines germées de daïkon',
  A0DKS: 'Graines germées de gingembre',
  A00SR: 'Graines germées de haricot mungo',
  A00ST: 'Graines germées et pousses de pois',
  A0DKR: 'Graines germées de roquette',
  A00SY: 'Graines germées de soja',
  A00SZ: 'Graines germées et pousses de tournesol',
  A00TA: 'Graines germées de blé',
  A00TB: 'Autres espèces utilisées pour la production de jeunes pousses',
  A00SG: 'Graines germées de haricot Adzuki',
  A00SJ: "Graines germées d'orge",
  A00SK: 'Graines germées de haricot commun',
  A00SL: 'Graines germées de pois chiche',
  A00SN: 'Graines germées de fenugrec',
  A00SP: 'Graines germées de lentille',
  A00SQ: 'Graines germées de millet',
  A00SS: 'Graines germées de moutarde',
  A00SV: 'Graines germées de radis',
  A00SX: 'Graines germées de riz',
  A00FM: 'Brocolis et similaires',
  A00FN: 'Brocolis',
  A0DLM: 'Calabrais',
  A00FQ: 'Brocolis de Chine',
  A00GK: 'Choï-sum',
  A00FP: 'Rapini',
  A0DLL: 'Choux-fleurs et similaires',
  A00FR: 'Choux-fleurs',
  A0DLK: 'Choux romanesco',
  A00FS: 'Autres choux (p)',
  A0DGR: 'Courgette (fleurs comestibles)',
  A0DGF: 'Autres fleurs comestibles',
  A0DEV: 'Asperges et similaires',
  A00RT: 'Asperges',
  A0DET: 'Pousses de houblon',
  A0DES: 'Cardons et similaires',
  A00RX: 'Cardons',
  A0DER: 'Tiges de bourrache',
  A0DEQ: 'Céleris et similaires',
  A00RY: 'Céleris',
  A0DEP: 'Fenouils et similaires',
  A00SA: 'Fenouils',
  A0DEN: 'Artichauts et similaires',
  A00RS: 'Artichauts',
  A0DEM: 'Fleurs de bananier',
  A0DEL: 'Fleurs de bananier - espèces cultivées acuminata',
  A0DEK: 'Fleurs de bananier - espèces cultivées balbisiana',
  A0DEJ: 'Fleurs de bananier - espèces cultivées paradisiaca',
  A0DEH: 'Poireaux et similaires',
  A00SB: 'Poireaux',
  A00HM: 'Kurrat',
  A0DEG: 'Rhubarbes et similaires',
  A00SD: 'Rhubarbes',
  A0DEF: 'Pousses de bambou et similaires',
  A00RV: 'Pousses de bambou',
  A0DED: 'Bambou moso',
  A0DEE: 'Bambou roseau',
  A0DEC: 'Cœurs de palmier et similaires',
  A00SC: 'Cœurs de palmier',
  A0DEB: 'Cœurs de palmier pêche',
  A0DEA: 'Cœurs de cocotier',
  A0DDZ: 'Cœurs de rotin de Satan',
  A0DDY: 'Cœurs de palmier Assai',
  A0DDX: 'Cœurs de chou palmiste',
  A00SE: 'Autres légumes-tiges',
  A00RZ: 'Laitue céleri',
  A04RA: 'Choux-raves et similaires',
  A00GV: 'Choux-raves',
  A18YN: 'Sago palm',
  A00GY: 'Aulx et similaires',
  A00GZ: 'Aulx',
  A0DNE: 'Aulx à feuilles obliques',
  A176J: 'Aulx verts',
  A00HB: 'Oignons et similaires',
  A00HC: 'Oignons',
  A0DND: "Bulbes d'oignons destinés à la consommation à l'état frais",
  A00HA: 'Oignons perles',
  A00HE: 'Rakkyo',
  A00HD: 'Oignons argentés',
  A0DNB: 'Échalotes et similaires',
  A00HF: 'Échalotes',
  A0DNA: 'Échalotes grises',
  A0DMZ: 'Échalotes à petite tige',
  A00HG: 'Oignons de printemps et similaires',
  A00HH: 'Oignons de printemps',
  A0DMY: 'Oignons verts',
  A00HJ: 'Ciboules',
  A00HL: 'Oignons rocamboles',
  A00HK: 'Autres légumes-bulbes',
  A00PC: 'Haricots (non écossés) et similaires',
  A0DFV: 'Haricots Adzuki (non écossés)',
  A00PV: 'Haricots à œil noir (non écossés)',
  A00PH: 'Fèves (non écossées)',
  A00PE: 'Haricots à couper (jeunes gousses)',
  A00PG: 'Haricots verts (non écossés)',
  A00PD: "Haricots d'Espagne (non écossés)",
  A00PJ: 'Haricots de Lima (non écossés)',
  A0DFT: 'Ers (non écossés)',
  A00PQ: 'Haricots de Guar (non écossés)',
  A00PM: 'Pois-sabres (non écossés)',
  A00PS: 'Haricot sabre (jeune gousse)',
  A00PL: 'Haricots Lablab (non écossés)',
  A0DFS: "Jarosses d'Auvergne (non écossées)",
  A00PR: 'Haricots mungos (non écossés)',
  A00PT: 'Haricots riz (non écossés)',
  A0DFR: 'Fèves de soja (non écossées)',
  A0DFQ: 'Fèves de petaï (non écossées)',
  A16QA: 'African locust bean (with pods)',
  A0DFP: 'Vesces (non écossées)',
  A00PF: 'Doliques-asperges (non écossés)',
  A00PK: 'Haricot Goa (jeunes gousses)',
  A00PN: 'Haricot papillon (jeunes gousses)',
  A00PP: 'Ambérique (jeunes gousses)',
  A00PX: 'Pois (non écossés) et similaires',
  A00PY: 'Petits pois (non écossés)',
  A00QA: 'Pois mange-tout (jeunes gousses)',
  A0DFG: 'Lotiers rouges (non écossés)',
  A0DFF: 'Gesses cultivées (non écossées)',
  A00PZ: 'Pois chiches (non écossés)',
  A0DFE: 'Graines de Moringa (non écossées)',
  A00QC: "Pois d'angole (non écossées)",
  A00QB: 'Pois ailé (jeunes gousses)',
  A0DFB: 'Lentilles et similaires',
  A00QD: 'Lentilles fraîches',
  A00QE: 'Lentilles (jeunes gousses)',
  A00HP: 'Solanacées',
  A00HZ: 'Poivrons et similaires',
  A00JA: 'Poivrons doux',
  A00JB: 'Piments forts',
  A0DMM: 'Espèces cultivées de piment fort Capsicum annuum',
  A0DML: 'Capsicum baccatum',
  A0DMK: 'Piment Capsicum chinense',
  A0DMJ: 'Piment Tabasco',
  A0DMH: 'Piment Rocoto',
  A00HQ: 'Tomates et similaires',
  A0DMX: 'Tomates',
  A00HR: 'Tomate ronde',
  A00HS: 'Tomate beefsteak',
  A00HT: 'Tomate prune',
  A00HV: 'Tomate cœur de bœuf',
  A00HX: 'Tomates-poires',
  A00HY: 'Tomates cerises',
  A00JG: 'Cerises de terre (physalis, etc.)',
  A0DMV: 'Coquerets alkékenge',
  A0DMT: 'Coquerets du Pérou/Groseilles du Cap',
  A0DMS: 'Groseilles du Cap naines',
  A0DMN: 'Tomatilles',
  A00JH: 'Baie de goji',
  A0DMR: 'Baies de lyciet commun',
  A0DMQ: 'Baies de lyciet de Chine',
  A0DMP: 'Tomates-litchis',
  A00JC: 'Aubergines et similaires',
  A00JD: 'Aubergines',
  A0DMG: 'Anthoras',
  A0DMF: "Aubergines d'Éthiopie",
  A00JE: 'Pepinos',
  A0DME: 'Fruits de Solanum incanum ',
  A0DMD: 'Fausses aubergines',
  A170T: 'Kangaroo apples',
  A0DMC: 'Gombos et similaires',
  A00JF: 'Gombos',
  A00JJ: 'Autres Solanacées',
  A00JL: 'Cucurbitacées à peau comestible',
  A0DMB: 'Concombres et similaires',
  A00JM: 'Concombres',
  A0DMA: 'Concombres-serpents/Concombres flexueux',
  A0DLZ: 'Dosakais',
  A00JN: 'Cornichons et similaires',
  A00JP: 'Cornichons',
  A00KA:
    'Concombres des Antilles/Concombres-cornichons des Antilles/Concombres-marrons',
  A00JQ: 'Courgettes et similaires',
  A00JR: 'Courgettes',
  A00KB: 'Papengayes',
  A00KL: 'Courges-bouteilles',
  A00JZ: 'Chayottes',
  A0DLY: 'Courges écarlates/Tindolas',
  A0DLX: 'Gourdes pointues',
  A00KM: 'Courges-serpents/Trichosanthes serpentins',
  A00JY: 'Sopropos',
  A00JS: "Bonnets d'électeur",
  A00JT: 'Pâtissons',
  A00JV: 'Autres cucurbitacées à peau comestible',
  A00JX: 'Pomme de merveille',
  A16PT: 'Gourde épineuse',
  A00KC: 'Luffa à fruits cylindriques',
  A16QK: 'Gac',
  A00KD: 'Cucurbitacées à peau non comestible',
  A00KE: 'Melons et similaires',
  A00KF: 'Melons',
  A00KG: 'Kiwanos',
  A0DLV: 'Potirons et similaires',
  A00KH: 'Potirons',
  A0DLT: 'Doubeurres/Courges musquées/Courges butternut ',
  A00KN: 'Courges à la cire',
  A0DLR: 'Courges-potirons',
  A0DLQ: 'Pastèques et similaires',
  A00KJ: 'Pastèques',
  A00KK: 'Autres Cucurbitacées à peau non comestible',
  A0DLP: 'Maïs doux et similaires',
  A00KP: 'Maïs doux',
  A0DLN: 'Maïs nain',
  A00KQ: 'Autres légumes-fruits',
  A0DPC: 'Betteraves et similaires',
  A00QG: 'Betteraves',
  A0DPB: 'Carottes et similaires',
  A00QH: 'Carottes',
  A0DPA: 'Variétés de carottes de couleur',
  A0DNZ: 'Mini-carottes',
  A0DNY: 'Céleris-raves et similaires',
  A00QJ: 'Céleris-raves',
  A04JZ: 'Raiforts et similaires',
  A00QK: 'Racines de raifort',
  A0DNX: 'Racines de pissenlit',
  A00QP: 'Racines de gentiane',
  A0DNV: 'Topinambours et similaires',
  A00QQ: 'Topinambours',
  A00RH: 'Oxalide crénelée',
  A00RN: 'Crosnes du Japon',
  A170Q: 'Mashua',
  A170R: 'Tournesol à feuilles pâles',
  A170S: 'Pois tubéreux',
  A0DNS: 'Panais et similaires',
  A00QR: 'Panais',
  A0DNR: 'Persil à grosse racine et similaires',
  A00QS: 'Persil à grosse racine',
  A00QM: "Racines d'angélique",
  A0DNQ: 'Racines de pimprenelle/de boucage',
  A00QN: 'Racines de livèche',
  A0DNP: "Racines d'ortie",
  A0DNN: 'Racines de grande ortie',
  A0DNM: "Autres racines d'ortie",
  A00RM: 'Cerfeuil tubéreux/Cerfeuil bulbeux',
  A00QT: 'Radis et similaires',
  A00QV: 'Radis',
  A00QX: 'Radis noir',
  A00QY: 'Daïkon',
  A0DNK: 'Racines de maca',
  A0DNJ: 'Petits radis ',
  A00QZ: 'Souchets comestibles/Amandes de terre',
  A04KA: 'Salsifis et similaires',
  A00RA: 'Salsifis',
  A00RL: 'Grande bardane ou bardane comestible',
  A00RP: 'Racines de raiponce cultivée',
  A00RC: 'Scorsonères',
  A00RQ: 'Chervis/Chirous',
  A00RB: "Salsifis espagnols (chardons d'Espagne)",
  A0DNH: 'Rutabagas et similaires',
  A00RD: 'Rutabagas',
  A0DNG: 'Navets et similaires',
  A00RE: 'Navets',
  A0DNF: 'Moutardes tubéreuses (moutardes brunes)',
  A00RF:
    "Autres légumes-racines et légumes-tubercules non listés à l'exclusion des espèces amylacées et sucrières",
  A00RJ: 'Arracacha',
  A00RK: 'Sagittaire',
  A00RG: 'Ulluco',
  A00VA: 'Algues et organismes procaryotes',
  A00VB: 'Algue verte',
  A00VD: 'Laitue de mer',
  A00VE: 'Algue rouge',
  A00VF: 'Eucheuma',
  A00VH: 'Laitue rouge',
  A00VJ: 'Dulse',
  A00VG: 'Goémons blancs',
  A00VK: 'Algue brune',
  A00VL: 'Hijiki',
  A00VN: 'Spaghetti de mer',
  A00VC: 'Wakamé',
  A00VM: 'Kombu',
  A170Z: 'Rockweed',
  A04LX: 'Microphyte',
  A00VP: 'Spiruline',
  A0DCR: 'Spirulina maxima',
  A0DCQ: 'Spirulina platensis',
  A0DCP: 'Autres algues',
  A0DCN: 'Autres organismes procaryotes',
  A0DCV: 'Mousses et lichens ',
  A0DCT: "Mousses d'Islande/Cétraires d'Islande/Lichens d'Islande",
  A0DCS: 'Autres mousses et lichens',
  A00TP: 'Champignons de couche et similaires',
  A00TQ: 'Champignons communs',
  A0DDV: 'Charbon du maïs',
  A0DDT: 'Enoki-take',
  A0DDS: 'Fusarium venenatum',
  A0DDR: 'Agaric des jachères/Boules de neige/Champignons des bruyères',
  A0DDQ: 'Oreilles-de-Judas',
  A0DDP: 'Nameko',
  A0DDN: 'Pleurotus',
  A00TR: 'Pleurotes en coquille',
  A0DDG: 'Autres espèces du genre Pleurotus, non désignées par ailleurs ',
  A00TT: 'Champignons de la paille de riz/Volvaires cultivées ',
  A0DDM: 'Hydnes hérissons',
  A00TS: 'Shii-take',
  A0DDL: 'Champignons Shimeji',
  A0DDK: 'Trémelles en fuseau/Champignons de neige',
  A0DDJ: 'Tricholomes nus',
  A0DDH: 'Autres champignons de couche ',
  A00TD: 'Champignons sauvages et similaires',
  A00TG: 'Cèpes',
  A00TE: 'Chanterelles',
  A0DDF: 'Hydnes pied-de-mouton (aiguillards, oursins)',
  A0DDE: "Cornes d'abondance",
  A00TH: 'Lactaire délicieux',
  A00TL: 'Armillaire couleur de miel',
  A00TM: 'Pholiote ridée',
  A00TK: 'Morilles',
  A00TJ: 'Truffes',
  A0DDD: 'Truffes noires/Truffes du Périgord',
  A0DDC: 'Truffes blanches/Truffes grises/Truffes des Provençaux',
  A0DCZ: "Truffes d'été",
  A0DCX: 'Autres espèces du genre Tuber, non désignées par ailleurs ',
  A0DDB: 'Tricholomes de la Saint-George/Mousserons ',
  A0DDA: "Marasmes des oréades/Mousserons d'automne/Nymphes des montagnes",
  A0DCY: 'Autres champignons sauvages ',
  A00TF: 'Agaric champêtre',
  A04MB: 'Produits transformés à base de tomate',
  A00ZB: 'Tomates en conserve non concentrées',
  A00ZC: 'Tomates en conserve, entières ou en morceaux',
  A00ZD: 'Purée de tomate',
  A00ZE: 'Concentré de tomate, en conserve',
  A00ZF: 'Concentré de tomate',
  A00ZG: 'Tomates séchées',
  A00ZH: 'Légumes fermentés ou au vinaigre',
  A0ETT: 'Légumes fermentés',
  A00ZK: 'Choucroute',
  A00ZJ: 'Légumes marinés / au vinaigre',
  A0F3F: 'Purée ou pâte de légumes',
  A00ZN: 'Purée de légume',
  A00ZM: "Purée d'ail",
  A0ETR: 'Légumes salés',
  A0ETS: 'Légumes confits',
  A00ZQ: 'Légumes séchés',
  A00TY: 'Champignons séchés',
  A0ETQ: 'Légumes en boîte/en bocaux',
  A00ZP: 'Maïs doux en boîte',
  A00TZ: 'Champignons en boîte',
  A0ESL: 'Haricots verts en boîte',
  A011Z: 'Haricots (graines fraîches écossées) et similaires',
  A0BAH: 'Haricots Adzuki (écossés)',
  A012E: 'Haricots à œil noir (écossés)',
  A012A: 'Fèves (écossées)',
  A012B: 'Haricots borlotti ou autres haricots communs (écossés)',
  A0BAJ: 'Haricot fourrager (graines fraîches)',
  A0BAM: 'Flageolet (graines fraîches)',
  A0BAL: 'Haricot rouge (graines fraîches)',
  A0BAK: 'Haricot Navy (graines fraîches)',
  A0DFN: 'Ers (écossés)',
  A0DFM: 'Haricots de Guar (écossés)',
  A012C: 'Pois-sabres (écossés)',
  A0BAZ: 'Haricots Lablab (écossés)',
  A012D: 'Haricots de Lima (écossés)',
  A0DFL: "Jarosses d'Auvergne (écossés)",
  A0BAQ: 'Haricots mungos (écossés)',
  A0BAN: 'Haricots riz (écossés)',
  A0DFK: "Haricots d'Espagne (écossés)",
  A012F: 'Fèves de soja (écossées)',
  A0DFJ: 'Fèves de petaï (écossées)',
  A0BAY: 'Vesces (écossées)',
  A0DFH: 'Doliques-asperges (écossés)',
  A0BAT: 'Haricot papillon (graines fraîches)',
  A0BAP: 'Haricot Urd (graines fraîches)',
  A0BAS: 'Haricot tépari (graines fraîches)',
  A012G: 'Pois (écossés) et similaires',
  A012J: 'Petits pois (écossés)',
  A0BAR: 'Pois vert ridé (graines fraîches)',
  A0CEQ: "Pois fourrager (d'érable) (graines fraîches)",
  A0DFD: 'Lotiers rouges (écossés)',
  A0BAX: 'Gesses cultivées (écossées)',
  A0BAV: 'Pois chiches (écossés)',
  A0DFC: 'Graines de Moringa (écossées)',
  A012K: "Pois d'angole (écossés)",
  A012L: 'Lentilles (écossées)',
  A012N: 'Lupins (écossés)',
  A0DFA: 'Lupin blanc (écossé)',
  A0DEZ: 'Lupin bleu (écossé)',
  A0DEY: 'Lupin jaune (écossé)',
  A0DEX: 'Lupin des Andes (écossé)',
  A04HC: 'Autres légumineuses',
  A0BB0: 'Grain de cheval (graines fraîches)',
  A0BB1: 'Lentille de terre (graines fraîches)',
  A012P: 'Arachides (graines fraîches)',
  A012Q: 'Pois bambara (graines fraîches)',
  A012S: 'Haricots (secs) et similaires',
  A013E: 'Haricots Adzuki (secs)',
  A013N: 'Haricots à œil noir (secs)',
  A013H: 'Fèves (sèches)',
  A012T: 'Haricots borlotti ou autres haricots communs (secs)',
  A012Y: 'Haricots fourragers (graines sèches)',
  A012Z: 'Flageolets (graines sèches)',
  A012V: 'Haricot rouge (graines sèches)',
  A012X: 'Haricots Navy (graines sèches)',
  A0DCM: 'Ers (secs)',
  A0DCL: 'Haricots de Guar (secs)',
  A013Y: 'Pois-sabres (secs)',
  A013Z: 'Haricots Lablab (secs)',
  A013A: 'Haricots de Lima (secs)',
  A0DCK: "Jarosses d'Auvergne (sèches)",
  A013D: 'Haricots mungos (secs)',
  A013B: 'Haricots riz (secs) ',
  A0DCJ: "Haricots d'Espagne (secs)",
  A0DCH: 'Fèves de soja destinées à la consommation (sèches)',
  A0DCG: 'Fèves de petaï (sèches)',
  A013X: 'Vesces (sèches)',
  A0DCF: 'Doliques-asperges (secs)',
  A0DCE: 'Lentilles (sèches) et similaires',
  A013Q: 'Lentilles (sèches)',
  A0DCD: 'Pois (secs) et similaires',
  A013J: 'Petits pois (secs)',
  A013K: 'Pois vert ridé (graines sèches)',
  A013L: "Pois fourrager (d'érable) (graines sèches)",
  A0DCC: 'Lotiers rouges (secs)',
  A013V: 'Gesses cultivées (sèches)',
  A013M: 'Pois chiches (secs)',
  A0DCB: 'Graines de Moringa (sèches)',
  A013P: "Pois d'angole (secs)",
  A0DCA: 'Lupins (secs) et similaires',
  A013S: 'Lupins (secs)',
  A0DBZ: 'Lupin blanc (sec)',
  A0DBY: 'Lupin bleu (sec)',
  A0DBX: 'Lupin jaune (sec)',
  A0DBV: 'Lupin des Andes (sec)',
  A04HD: 'Autres légumes secs',
  A013R: 'Pois bambara (graines sèches)',
  A013C: 'Haricot Urd (graines sèches)',
  A014A: 'Grain de cheval (graines sèches)',
  A014B: 'Lentille de terre (graines sèches)',
  A013G: 'Haricot papillon (graines sèches)',
  A013F: 'Haricot tépari (graines sèches)',
  A01AZ: 'Légumineuses en boîte ou en bocaux',
  A01BB: 'Haricots communs en boîte ou en bocaux',
  A01BC: 'Pois en boîte ou en bocaux',
  A01BD: 'Lentilles en boîte ou en bocaux',
  A01BE: 'Pois chiche en boîte ou en bocaux',
  A185Q: 'Farine de légumes secs',
  A01BH: 'Farine de pois chiche',
  A18SV: 'Farine de soja',
  A01BT: 'Agrumes',
  A01CX: 'Pamplemousses et similaires',
  A01CY: 'Pamplemousses',
  A01DA: "Natsudaidais (tangerines d'été)",
  A01CZ: 'Shaddocks',
  A01DB: 'Pomelos',
  A0CFB: 'Sweeties',
  A01DD: 'Tangelolos',
  A01DC: "Tangelos (à l'exception des minneolas)",
  A01DE: 'Ugli',
  A0DZC: 'Autres hybrides de Citrus paradisi, non désignés par ailleurs',
  A01CP: 'Oranges et similaires',
  A0DZB: 'Oranges',
  A01CR: 'Oranges douces',
  A01CS: 'Oranges sanguines',
  A0DZA: 'Navel rouges',
  A0CFD: 'Bergamotes',
  A01CT: 'Oranges amères',
  A01CV: 'Chinottes',
  A0DYZ: 'Oranges trifoliées',
  A01CQ: 'Chironjas',
  A0DYY: 'Autres hybrides de Citrus sinensis, non désignés par ailleurs',
  A01BX: 'Citrons et similaires',
  A01BY: 'Citrons',
  A0CEZ: 'Mains de Bouddha',
  A01BZ: 'Cédrats',
  A0DYX: 'Limettes et similaires',
  A01CA: 'Limettes',
  A0DYV: 'Limettes douces de Palestine',
  A0DYT: 'Combavas',
  A0DYS: 'Limettes douces',
  A0DYR: 'Limes (de Tahiti)/Citrons verts',
  A01CB: 'Mandarines et similaires',
  A01CD: 'Mandarines',
  A01CF: 'Mandarines King',
  A01CC: 'Calamondins',
  A01CE: 'Clémentines',
  A01CG: 'Mandarines Cleopatra',
  A01CL: 'Minneolas ',
  A01CK: 'Satsumas',
  A01CH: 'Tangerines',
  A01CM: 'Tangors',
  A01CJ: 'Mandarines méditerranéennes',
  A01CN: 'Mandarine Tankan',
  A0DYQ: 'Autres hybrides de Citrus reticulata, non désignés par ailleurs',
  A01DF: 'Autres agrumes',
  A16XH: 'Australian finger lime',
  A01DG: 'Fruits à pépins',
  A01DH: 'Pommes et similaires',
  A01DJ: 'Pommes',
  A01DK: 'Pommettes',
  A0DXF: "Cenelles d'aubépine du Mexique",
  A01DN: 'Poires et similaires',
  A01DP: 'Poires',
  A01DQ: 'Poires asiatiques',
  A0DXE: 'Poires sauvages ',
  A0DXD: 'Poires Ya',
  A0DXC: 'Coings et similaires',
  A01DR: 'Coings',
  A0DXB: 'Coings de Chine',
  A0DXA: 'Coings du Japon',
  A0DVZ: 'Nèfles et similaires',
  A01DM: 'Nèfles',
  A0DVY: 'Bibasses et similaires',
  A01DL: 'Bibasses',
  A01DS: 'Autres fruits à pépins',
  A01GE: 'Fruits à noyau',
  A0DVX: 'Abricots et similaires',
  A01GF: 'Abricots',
  A0DVV: 'Abricots du Japon',
  A0DVT: 'Nectacots',
  A01GG: 'Cerises et similaires',
  A01GK: 'Cerises (douces)',
  A0DVR: 'Cerises noires',
  A0DVQ: 'Capulins',
  A01HC: 'Cerises de Virginie',
  A0DVP: 'Cornouilles',
  A0DVN: 'Cerises de Nan-king ',
  A01GH: 'Cerises acides',
  A01GJ: 'Cerises acides rouge foncé',
  A0DVS: 'Cerises acides rouge clair',
  A01GL: 'Pêches et similaires',
  A01GM: 'Pêches communes',
  A0DVL: 'Pêches plates',
  A01GN: 'Brugnons et nectarines',
  A0DVK:
    'Autres hybrides de Persica vulgaris ou Prunus persica, non désignés par ailleurs',
  A01GP: 'Prunes et similaires',
  A01GQ: 'Prunes',
  A01HA: "Prunes d'Amérique",
  A0DVJ: 'Prunes maritimes',
  A01GT: 'Prunes-cerises ',
  A01GV: 'Prunes Chickasaw ',
  A01JM: 'Jujubes communs',
  A01GR: 'Prunes de Damas/Quetsches',
  A01GX: 'Reines-claudes',
  A01GY: 'Prunes du Japon',
  A0DVH: 'Prunes Klamath',
  A01GS: 'Mirabelles',
  A0DVG: 'Plumcots',
  A0DVF: 'Prunus Nadia®',
  A01GZ: 'Prunelles',
  A01HB: 'Autres fruits à noyau',
  A01DT: 'Baies et petits fruits',
  A01DV: 'Raisins et fruits similaires',
  A0DVE: 'Raisins de table et similaires',
  A01DX: 'Raisins de table',
  A0CFT: 'Kiwaïs',
  A0DVD: 'Baies de Schisandra/Baies à cinq parfums',
  A0DVC: 'Raisins de cuve et similaires',
  A01DY: 'Raisins de cuve',
  A0DVB: "Raisins de vigne de l'Amour",
  A0DVA: 'Raisins de muscadine',
  A01DZ: 'Fraises et similaires',
  A01EA: 'Fraises',
  A01EC: 'Fraises musquées/Caprons',
  A01EB: 'Fraises des bois',
  A01ED: 'Fruits de ronces',
  A0DTY: 'Mûres de ronces et similaires',
  A01EE: 'Mûres de ronces',
  A01EF: 'Mûres des haies et similaires',
  A01EG: 'Mûres des haies',
  A01EH: 'Mûres de Boysen',
  A01EJ: 'Ronce-framboise ',
  A01EK: 'Baies Olallie',
  A01ET: 'Ronces remarquables/Ronces élégantes',
  A0CFK: 'Framboises-mûres de Tay',
  A01EV: 'Ronces parviflores («dés à coudre»)',
  A01EL: 'Mûres de Young',
  A0DTX: 'Autres espèces du genre Rubus, non désignées par ailleurs',
  A01EN: 'Framboises et similaires',
  A01EP: 'Framboises (rouges ou jaunes)',
  A0CFL: 'Ronces arctiques',
  A01EQ: 'Framboises noires',
  A0DTT: 'Ronces coréennes',
  A0DTS: 'Framboises coréennes',
  A0CFM: 'Framboises «nectar»',
  A01ER: 'Framboises du Japon',
  A01ES: 'Autres fruits de ronces',
  A01EX: 'Autres petits fruits et baies',
  A04JJ: 'Myrtilles et similaires',
  A01EY: 'Myrtilles',
  A0DTR: "Myrtille d'Amérique",
  A01FB: "Myrtille d'Argentine (œil de lapin)",
  A01FA: 'Bleuet à feuilles étroites',
  A01EZ: 'Airelle à corymbes',
  A0DTQ: "Baies d'aronie",
  A0DTP: "Baies d'aronie (rouges)",
  A0DTN: "Baies d'aronie (noires)",
  A0DTM: "Baies d'aronie (pourpres)",
  A01FD: "Raisins d'ours/Baies de petit buis des Alpes/Busseroles",
  A01FE: 'Myrtilles européennes (générique)',
  A01FF: 'Myrtilles européennes',
  A01FG: 'Myrtilles des marais/Airelles uligineuses',
  A0DTL: "Baies d'épine-vinette commune",
  A0DTK: 'Groseilles dorées',
  A0CFS: 'Baies de chèvrefeuille bleu',
  A01FC: 'Airelles fausses-myrtilles/Bleuets',
  A0CFP: 'Caseilles/Casseilles',
  A01FY: "Fruits de l'amélanchier à grappes/Baies de juin",
  A0DTJ: 'Baies de myrte',
  A0DTH: 'Acrotriches',
  A01FJ: 'Petites airelles et similaires',
  A01FK: 'Airelles rouges',
  A01FL: 'Petites airelles',
  A0DTG: 'Fruits de la gaulthérie shallon',
  A01FZ: 'Argouses',
  A0CFY: 'Amélanches',
  A0DTF: 'Goyaves du Chili',
  A0CFQ: 'Groseilles à maquereau «Worcester»',
  A0DTE:
    'Autres espèces et hybrides des genres Ribes et Vaccinium, non désignés par ailleurs',
  A04JK: 'Airelles canneberges et similaires',
  A01FH: 'Airelles canneberges',
  A01EM: 'Mûres de mûrier',
  A01GC: 'Camarines',
  A0DTD: 'Camarines pourpres',
  A0DTC: 'Camarines noires (tétraploïdes)',
  A0DTB: 'Camarines noires (communes)',
  A0DTA: 'Camarines rouges',
  A0DSZ: 'Pommes à émeus',
  A0DSY: 'Pain-de-perdrix/Baies de mitchella rampant',
  A0DSX: 'Canneberges des marais',
  A0DSV: 'Groseilles à grappes et similaires',
  A01FM: 'Groseilles à grappes (blanches, noires ou rouges)',
  A01FP: 'Groseilles rouges',
  A01FN: 'Groseilles noires',
  A01FQ: 'Groseilles à maquereau',
  A0CFN: 'Groseilles à maquereau (jaunes, rouges ou vertes)',
  A01FR: 'Cynorhodons et similaires',
  A0CFR: 'Cynorhodons',
  A0DSS: 'Églantier commun',
  A0DSR: 'Rosier cannelle',
  A0DSQ: 'Rosa rugosa',
  A04JL: 'Mûres (blanches ou noires) et similaires',
  A01FS: 'Mûres (blanches ou noires)',
  A0DSN: 'Mûres blanches',
  A0DSM: 'Mûres noires',
  A0CFC: 'Azéroles et similaires',
  A01FV: 'Azéroles',
  A04JM: 'Baies de sureau noir et similaires',
  A01FX: 'Baies de sureau noir',
  A0DSL: 'Fraises chinoises/Fruits du myrica',
  A0DSK: 'Baies de shépherdie argentée',
  A0DSJ: 'Baies de che',
  A0DSH: "Baies d'hièble/yèble (petit sureau)",
  A0DSG: 'Baies de viorne obier',
  A01GA: "Cenelles d'aubépine monogyne",
  A0DSF: "Cenelles d'épine blanche",
  A0DSE: 'Fruits du phalsa',
  A0DSD: 'Riberries',
  A0CFZ: 'Amélanches de saskatoon',
  A0DSC: 'Olives de Bohème',
  A01GD: 'Sorbes',
  A0CFX: 'Baies de sorbier',
  A0CFV: 'Sorbes des oiseleurs ',
  A176F: 'Red elderberries',
  A01GB: 'Autres petits fruits et baies non listés',
  A01HD: 'Fruits divers (générique)',
  A01HE: 'Fruits divers à peau comestible',
  A0DSB: 'Dattes et similaires',
  A01HF: 'Dattes',
  A0DSA: "Baies d'açaí",
  A0DRZ: 'Fruits du palmier awara',
  A01KP: "Fruits du palmier doum d'Égypte",
  A0DRY: 'Figues et similaires',
  A01HG: 'Figues',
  A01HH: 'Olives de table et similaires',
  A01BP: 'Olives de table',
  A01JF: 'Olives de Chine, noires, blanches',
  A01JH: 'Dattes du désert',
  A0DRV: 'Olives blanches de Chine',
  A0DRX: 'Olives noires de Chine',
  A01HJ: 'Kumquats et similaires',
  A0DRT: 'Kumquats',
  A01HK: 'Kumquats marumi/',
  A0CGA: 'Kumquats nagami',
  A0DRS: 'Limequats',
  A0DRR:
    'Autres espèces et hybrides du genre Fortunella, non désignés par ailleurs',
  A01HL: 'Caramboles et similaires',
  A01HM: 'Caramboles',
  A01JB: 'Prunes de Cythère/Pommes cythères',
  A01JQ: 'Aonlas',
  A01LX: 'Babacos',
  A01HN: 'Bilimbis',
  A01JE: 'Noix de cajou',
  A01JL: "Jujubes d'Inde",
  A01JK: 'Jaboticabas',
  A01HT: "Pommes d'Haïti/",
  A0DRQ: 'Mombins malais',
  A0DRP: 'Maprangs',
  A01JN: 'Carisses/Prunes du Natal',
  A0DRN: 'Baies de nonis',
  A01HV: 'Pommes-roses',
  A0DRM: 'Mombins pourpres',
  A01KV: 'Fruits du santol',
  A16QN: 'Dracontomelon duperreanum fruits',
  A01HP: 'Kakis et similaires',
  A01HQ: 'Kakis',
  A01HR: 'Plaquemines de Chine',
  A04JN: 'Jamelongues et similaires',
  A01JZ: 'Jamelongues',
  A01JC: 'Acérolas/Cerises des Barbades/Cerises des Antilles',
  A01FT: 'Arbouses',
  A0DRL: 'Fruits du camu-camu',
  A01JD: 'Carandas',
  A01JG: 'Icaques/Prunes coton',
  A01HS: 'Grumichamas/Cerises du Brésil',
  A01JJ: 'Prune mombin',
  A01KB: 'Jamboses/Pommes de Java',
  A01JP: 'Surelles',
  A01JR: 'Raisins de mer/Fruits du raisinier bord-de-mer',
  A01HX: 'Cerises de Cayenne',
  A0DRK: "Pommes d'eau",
  A0DRJ: 'Baies de Syzygium cordatum',
  A0DRH: "Poires d'eau",
  A01JA: 'Autres fruits divers à peau comestible',
  A16XK: 'Davidsons plum',
  A01JS: 'Fruits divers, à peau non comestible et de petite taille',
  A01JT: 'Kiwis (jaunes, rouges ou verts)',
  A0DRF: 'Kiwi velu',
  A0DRE: 'Kiwi jaune',
  A01JV: 'Litchis',
  A01KQ: 'Longanes',
  A0DRC: 'Fruits du marula',
  A0CGC: 'Salaks/fruits-serpents',
  A01KX: 'Quenettes',
  A176G: 'Baels',
  A01KC: 'Fruits de la passion',
  A0DRA: 'Curubas',
  A0DQZ: 'Barbadines',
  A0DQY: 'Grenadelles/Grenadilles sucrées/Grenadilles douces',
  A0DQX: 'Fruits du faux philodendron/Cérimans',
  A0DQV: 'Grenadilles sauvages',
  A01KD: 'Figues de Barbarie',
  A0DQS: 'Pitayas/Fruits du dragon',
  A0CGE: 'Pitayas rouges',
  A0DQR: 'Fruits du cactus saguaro',
  A170N: 'pitaya jaune',
  A01KE: 'Caïmites',
  A01KF: 'Plaquemines de Virginie',
  A01KJ: 'Sapotes noires',
  A01KK: 'Sapotes vertes',
  A01KM: 'Sapotes blanches',
  A01KH: "Canistels (jaunes d'œuf)/Fruits-œufs",
  A01KG: 'Autres fruits divers, à peau non comestible et de petite taille',
  A01LA: 'Fruits divers, à peau non comestible et de grande taille',
  A01LB: 'Avocats',
  A0DQN: 'Avocats à huile',
  A01LC: 'Bananes communes',
  A0DQM: 'Bananes Cavendish - acuminata (espèce cultivée)',
  A0DQL: 'Bananes Cavendish - balbisiana (espèce cultivée)',
  A0DQK: 'Bananes Cavendish - paradisiaca (espèce cultivée)',
  A01LD: 'Bananes naines',
  A01LE: 'Bananes plantains',
  A0DQJ: 'Bananes plantains - acuminata (espèce cultivée)',
  A0DQH: 'Bananes plantains - balbisiana (espèce cultivée)',
  A0DQG: 'Bananes - paradisiaca (espèce cultivée)',
  A01LF: 'Mangues',
  A01LG: 'Papayes',
  A01KN: 'Akées/Akis',
  A01LY: 'Feijoas',
  A0CGB: 'Langsats',
  A01JX: 'Mangoustans',
  A01KS: 'Naranjillas/Narangilles',
  A0DQD: 'Asimines',
  A01HZ: 'Tamarillos/Tomates arbustives',
  A01LH: 'Grenades',
  A01LJ: 'Chérimoles',
  A01KZ: "Féronies des Indes/Pommes d'éléphant",
  A01LM: 'Ilamas',
  A01KL: 'Grandes sapotes',
  A01KR: 'Marmaladedos',
  A01JY: 'Pulasans',
  A01KA: 'Ramboutans',
  A01KT: 'Sapotilles',
  A01LL: 'Pommes-canelles',
  A01LK: 'Pommes-canelles sauvages',
  A0CGD: 'Goyaves',
  A0DQB: 'Goyaves du Brésil',
  A0DQA: 'Goyaves de Chine/Goyages-fraises',
  A0DPZ: 'Goyaves du Costa Rica',
  A0DPY: 'Goyaves de Sartori',
  A0DPX: 'Goyaves de para',
  A0DPV: 'Ananas et similaires',
  A01LP: 'Ananas',
  A01LQ: "Fruits de l'arbre à pain",
  A01LR: 'Pomme de jacque',
  A0DPS: 'Autres espèces du genre Artocarpus, non désignées par ailleurs',
  A01LS: 'Durions',
  A01LT: 'Corossols',
  A01LV: 'Autres fruits divers, à peau non comestible et de grande taille',
  A01LZ: 'Abricot des Antilles',
  A0F6K: 'Fruits du baobab',
  A16QE: 'Arenga westerhoutii fruits',
  A01QN: 'Produits à base de fruits fermentés',
  A01BR: 'Olives transformées',
  A01BQ: 'Olives de table prêtes à la consommation',
  A01PS: 'Fruits confits',
  A01QA: 'Fruit confit, pomme',
  A01PV: 'Fruit confit, bananes',
  A01PT: 'Fruit confit, cerise',
  A01PY: 'Fruit confit, raisin',
  A01QC: "Fruit confit, zeste d'orange",
  A01PZ: 'Fruit confit, poire',
  A01PX: 'Fruit confit, ananas',
  A01QB: 'Fruits mélangés confits',
  A01MA: 'Fruit séché',
  A01QF: 'Fruits séchés mélangés',
  A01QH: 'Chips de fruit',
  A01QQ: 'Fruit séché enrobé de chocolat',
  A01MB: 'Pruneaux séchés',
  A01MC: 'Pommes séchées',
  A01MD: 'Abricots séchés',
  A01ME: 'Raisins secs',
  A01MF: 'Dattes séchées',
  A01MG: 'Figues séchées',
  A01MH: 'Poires séchées',
  A01MJ: 'Bananes séchées',
  A01MK: 'Mangues séchées',
  A01QM: 'Fruit conservé dans le vinaigre ou la saumure',
  A0CJA: "Fruit conservé dans l'alcool",
  A01NN: 'Fruit en boîte ou en bocaux',
  A01PC: 'Fruits mélangés en boîte ou en bocaux',
  A01QK: 'Cocktail de fruits en boîte',
  A01NP: 'Mandarine en boîte ou en bocaux',
  A01NQ: 'Pomme en boîte ou en bocaux',
  A01NR: 'Poire en boîte ou en bocaux',
  A01NS: 'Abricot en boîte ou en bocaux',
  A01NT: 'Prune en boîte ou en bocaux',
  A01NV: 'Cerise douce en boîte ou en bocaux',
  A01NX: 'Cerise acide en boîte ou en bocaux',
  A01NY: 'Pêche en boîte ou en bocaux',
  A01NZ: 'Raisin de table en boîte ou en bocaux',
  A01PA: 'Canneberge en boîte ou en bocaux',
  A01PB: 'Ananas en boîte ou en bocaux',
  A014D: 'Amandes',
  A014F: 'Amandes douces',
  A014E: 'Amandes amères',
  A0CFE: "Amandes (de noyaux) d'abricots",
  A0DYN: 'Noix de Nangaille',
  A0DYM: 'Noix canari (harveyi)',
  A015A: 'Noix canari (indicum)',
  A015C: 'Noix de pili',
  A170M: 'Okari nuts',
  A014G: 'Noix du Brésil',
  A014H: 'Noix de cajou',
  A014J: 'Châtaignes',
  A0DYH: 'Marron du Japon',
  A0DYG: "Châtaigne d'Amérique",
  A0DYF: 'Châtaigne chinoise',
  A0DYE: 'Châtaigne commune ',
  A014K: 'Noix de coco',
  A0DYC: 'Noix d’arec',
  A0CFF: 'Noisettes et similaires',
  A014L: 'Noisettes',
  A0DYB: 'Glands',
  A0CFG: 'Avelines',
  A0DYA: 'Noix de macadamia et similaires',
  A014M: 'Noix de macadamia',
  A0DXZ: 'Macadamia integrifolia',
  A0DXY: 'Macadamia tetraphylla',
  A0DXX: 'Noix de pécan et similaires',
  A014N: 'Noix de pécan',
  A014Y: "Noix d'hickory (caryer ovale)",
  A014P: 'Pignons de pin et similaires',
  A01BV: 'Pignons de pin, sans coquille',
  A0DXV: 'Pignons de pin, avec coquille',
  A0DXT: 'Pignons de pin de Suisse',
  A0DXS: 'Pignons de pin de Chilgoza',
  A0DXR: 'Pignons de pin de Corée',
  A0DXQ: 'Pignons de pin de Sibérie',
  A0DXP: 'Pignons de pin nain de Sibérie',
  A0DXN: 'Pignons de pin du Yunnan',
  A0DXM: 'Pignons de pin du Bouthan',
  A0DXL: 'Autres pignons de pin',
  A0DXK: 'Pistaches et similaires',
  A014Q: 'Pistaches',
  A0DXJ: 'Noix et similaires',
  A014R: 'Noix',
  A0DXH: "Noix noires d'Amérique",
  A0DXG: 'Noix commune',
  A014S: 'Autres fruits à coque',
  A014T: 'Faînes',
  A014V: 'Noix de paradis',
  A014X: 'Noix du noyer cendré',
  A014Z: "Marron d'Inde",
  A015B: 'Pachira',
  A015D: 'Badame',
  A0F0L: 'Fruits à coque confits',
  A0F0N: 'Fruits à coque secs et farines et poudres apparentées',
  A0F0M: 'Pâte/émulsion à base de noix/graines',
  A01BL: 'Crème de marrons (castanea vulgaris)',
  A01BM: 'Pâte de sésame (tahini) (sesamus indicum)',
  A01BN: 'Beurre de cacahuète',
  A0DBT: 'Graines de lin et similaires',
  A015G: 'Graines de lin',
  A0DBS: 'Arachides et similaires',
  A015H: 'Arachides',
  A0DBR: 'Graines de pavot et similaires',
  A015J: 'Graines de pavot',
  A0DBQ: 'Graines de sésame et similaires',
  A015K: 'Graines de sésame',
  A0DBP: 'Graines de tournesol et similaires',
  A015L: 'Graines de tournesol',
  A04KG: 'Graines de colza et similaires',
  A015M: 'Graines de colza',
  A0DBN: 'Graines de radis',
  A015N: 'Graines de navette',
  A015T: 'Graines de moutarde fourragère',
  A0DBM: 'Fèves de soja et similaires',
  A015P: "Fèves de soja destinées à la production d'huile",
  A016E: 'Graines de Moringa',
  A015Q: 'Graines de moutarde et similaires',
  A015S: 'Graines de moutarde',
  A0DBL: 'Graines de moutarde noire',
  A0DBK: 'Graines de moutarde blanche',
  A015R: 'Graines de Canola',
  A0DBJ: 'Graines de coton et similaires',
  A015V: 'Graines de coton',
  A0DBH: 'Graines de coton à longue soie',
  A0DBG: 'Graines de coton commun',
  A0CGF: 'Graines de courge et similaires',
  A015X: 'Graines de courge',
  A0DBF: 'Graines de pastèque',
  A0DBE:
    'Autres graines des espèces de la famille Cucurbitaceae, non désignées par ailleurs',
  A0DBD: 'Graines de carthame et similaires',
  A015Y: 'Graines de carthame',
  A0DBC: 'Graines de chardon Marie/chardon Notre-Dame',
  A016F: 'Graines de niger',
  A0CGG: 'Graines de bourrache et similaires',
  A015Z: 'Graines de bourrache',
  A0CGJ: 'Graines de grémil des champs',
  A0DBA: "Graines d'onagre",
  A0DAZ: 'Graines de monnaie-du-pape/Graines de lunaire',
  A0DAY: 'Graines de Lunaire annuelle',
  A0DAX: 'Graines de Lunaire vivace',
  A0BKL: 'Graines de pérille/shiso vert/shiso pourpre',
  A0CGH: 'Graines de vipérine faux-plantain',
  A0DAV: 'Graines de cameline et similaires',
  A016A: 'Graines de cameline',
  A0DAS: 'Chènevis (graines de chanvre) et similaires',
  A016B: 'Chènevis (graines de chanvre)',
  A0DAR: 'Graines de chanvre commun',
  A0DAQ: 'Graines de chanvre ruderalis',
  A0DAP: 'Graines de ricin et similaires',
  A016C: 'Graines de ricin',
  A016J: 'Pépins de raisin',
  A0DAN: "Graines d'argousier",
  A016D:
    'Autres graines oléagineuses (comprenant les graines non listées ailleurs et les autres graines)',
  A016G: 'Graines de tucuma',
  A0F6H: 'Graines de baobab',
  A16FZ: 'Graines de chia',
  A16RB: 'Graines de mélinjo',
  A0DAM: 'Olives à huile et similaires',
  A016M: 'Olives à huile',
  A0DAL: 'Amandes du palmiste et similaires',
  A016N: 'Amandes du palmiste',
  A0DAK: 'Graines de palmier maripa',
  A0DAJ: "Graines de palmier à huile d'Afrique",
  A0DAH: "Graines de palmier à huile d'Amérique",
  A0DAG: "Noix d'argan",
  A0DAF: 'Noix du palmier babassu',
  A0DAE: 'Noix de jojoba',
  A016H: 'Amandes de karité',
  A0DAD: 'Fruits du palmiste et similaires',
  A016P: 'Fruits du palmiste',
  A0DAC: 'Fruits de palmier maripa',
  A0DAB: "Fruits de palmier à huile d'Afrique",
  A0DAA: "Fruits de palmier à huile d'Amérique",
  A0D9Z: 'Kapoks et similaires',
  A016Q: 'Kapoks',
  A016R: 'Autres fruits oléagineux',
  A0DPP: 'Pommes de terre et similaires',
  A00ZT: 'Pommes de terre',
  A011P: 'Pomme de terre bouillie',
  A011R: 'Pomme de terre au four',
  A00ZX: 'Pommes de terre non hâtives',
  A00ZV: 'Pommes de terre hâtives',
  A0DPM: 'Andigenas',
  A00ZY: 'Légumes-racines et légumes-tubercules tropicaux',
  A04JX: 'Racines de manioc et similaires',
  A00ZZ: 'Racines de manioc',
  A0DPL: 'Taros bleus',
  A010K: 'Fruits du canna (balisier rouge)',
  A010N: 'Racines de chayotte',
  A010B: 'Taros',
  A0DPK: 'Dachines',
  A0DPJ: 'Eddoes/Taros chinois',
  A0DPN: 'Racines de konjac',
  A010A: 'Tannies',
  A0DPH: 'Patates douces et similaires',
  A010C: 'Patates douces',
  A04JY: 'Igname et similaires',
  A010D: 'Ignames',
  A0DPG: 'Doliques tubéreux',
  A0DPF: 'Glycines tubéreuses',
  A010F: 'Pois-patates des Andes',
  A010E: 'Jícamas/Navets mexicains',
  A0DPE: 'Marantes arundinacées et similaires',
  A010G: 'Marantes arundinacées',
  A16FL: "Châtaigne d'eau chinoise",
  A0DPD: 'Racines de lotus sacré',
  A010H: 'Autres légumes-racines et légumes-tubercules tropicaux',
  A010J: 'Topee tambu',
  A010P: 'Racine de haricot ailé',
  A010Q: 'Alocasia',
  A185R: 'Orchid',
  A16RD: 'Racines amylacées et tubercules séchés',
  A011C: 'Produits à base de pommes de terre déshydratées',
  A011E: 'Purée de pomme de terre en poudre',
  A011G: 'Farine de pomme de terre',
  A011D: 'Flocons de pomme de terre',
  A18PR: 'Farine de manioc',
  A16FM: 'Racines amylacées en boîte',
  A0CXQ: 'Betteraves sucrières et similaires',
  A010T: 'Betteraves sucrières',
  A0CXM: 'Racines de chicorée et similaires',
  A010Z: 'Racines de chicorée',
  A0CXL: 'Racines de polypode commun',
  A0CXK: 'Racines de yacón',
  A0CXP: 'Cannes à sucre et similaires',
  A010V: 'Cannes à sucre',
  A0CXN: "Feuilles d'agave",
  A0BB7: "Cœur d'agave bleue",
  A010Y: 'Cannes de sorgho doux',
  A04HB: 'Autres plantes sucrières',
  A0CXJ: 'Bouleaux (sève)',
  A0CXH: 'Ornes communs/Frênes à fleurs (sève)',
  A034A: 'Érables (sève)',
  A0CXG: 'Palmiers (sève)',
  A0CXF: 'Cocotier du Chili (sève)',
  A0CXE: 'Palmier dattier des Canaries (sève)',
  A0CXD: 'Autres plantes sucrières non listées',
  A0DJJ: 'Cerfeuils et similaires',
  A00XB: 'Cerfeuils',
  A00XC: 'Ciboulettes et similaires',
  A00XD: 'Ciboulettes',
  A00XE: 'Ciboulette chinoise/',
  A0DJH: 'Aulx sauvages/Aïl des ours',
  A04KB: 'Feuilles de céleri et similaires',
  A00XA: 'Feuilles de céleri',
  A00VR: 'Angélique (feuilles et tiges)',
  A00VZ: 'Pimprenelle',
  A0DJG: 'Feuilles de carvi',
  A00XF: 'Feuilles de coriandre',
  A0CSR: 'Feuilles de culantro',
  A00XH: "Feuilles d'aneth",
  A00XL: 'Feuilles de fenouil',
  A0DJF: 'Feuilles de fenugrec',
  A00YG: 'Rue officinale/Rue des jardins',
  A00XS: 'Feuilles de livèche',
  A0DJE: 'Pimprenelle',
  A0DJD: 'Petite pimprenelle',
  A00YL: 'Oseilles',
  A0DJC: 'Rumex flexuosus',
  A0DJB: "Oseille d'eau",
  A00MT: 'Oseille des jardins',
  A0DJA: 'Potentille tormentille',
  A00YM: 'Cerfeuil musqué ',
  A0DHZ: 'Persil et similaires',
  A00YE: 'Persils',
  A0DHY: 'Feuilles de persil à grosse racine',
  A0DHX: 'Sauge et similaires',
  A00YH: 'Sauge',
  A00VY: 'Bourrache',
  A0DHV: "Immortelle d'Italie",
  A0DHT: 'Sauge trilobée',
  A0DHS: 'Sauge leucantha',
  A0DHR:
    'Autres espèces et hybrides du genre Salvia, non désignés par ailleurs',
  A0DHQ: 'Romarin et similaires',
  A00YF: 'Romarin',
  A0DHP: 'Santolines',
  A171C: 'Green santolina',
  A04KD: 'Thym et similaires',
  A00YQ: 'Thym',
  A0DHN: 'Serpolet/Thym sauvage',
  A0DHM: 'Origan crétois',
  A0DHL: 'Sarriette citronnée',
  A0DHK: 'Thym citron',
  A00XX: 'Marjolaine',
  A0DHJ: 'Marjolaine sylvestre',
  A00XV: 'Origan',
  A0DHH: 'Sarriette des jardins',
  A0DHG: 'Origan de Syrie',
  A00YK: 'Sarriette des montagnes',
  A0ESV: 'Basilics et menthes',
  A00VV: 'Basilic',
  A00XZ: 'Menthes',
  A0DHA: 'Menthe odorante',
  A0DGZ: 'Menthe bergamote',
  A0DGY: 'Menthe de Corse ',
  A0DGX: 'Menthe gingembre',
  A00YA: 'Menthe pouliote',
  A00YB: 'Menthe poivrée',
  A00YC: 'Menthe verte/Menthe à épis',
  A0DGV: 'Menthe aquatique',
  A0DGT:
    'Autres espèces et hybrides du genre Mentha, non désignés par ailleurs',
  A0DGS: 'Herbe du tigre/Hydrocotyle asiatique ',
  A0DHF: 'Basilic fin vert compact',
  A0DHE: "Basilic d'Amérique",
  A0DHD: 'Basilic sacré',
  A00VT: 'Mélisse',
  A0DHC: 'Basilic citron',
  A0DGQ: 'Petit calament',
  A0DGP: 'Plante caméléon',
  A0DGG: 'Herbe à paddy',
  A0DHB: 'Basilic thaïlandais',
  A00YR: 'Coriandre vietnamienne',
  A18SY: 'Chinese mesona',
  A0DGE: 'Laurier et similaires',
  A00VX: 'Laurier',
  A00XG: 'Feuilles de murraya',
  A00XN: 'Feuilles de combava',
  A0DGD: 'Séné du Siam',
  A0DGC: 'feuilles de Piper sarmentosum',
  A170Y: 'Pandan leaves',
  A04KF: 'Estragon et similaires',
  A00YP: 'Estragon',
  A0DGB: 'Herbe sucrée des Aztèques/Verveine douce',
  A00XJ: 'Épazote',
  A00XM: 'Hysope',
  A00XR: 'Herbe citron/Barbon nard',
  A0DGA: 'Origan marjolaine',
  A00PA: 'Grande ortie',
  A0DFZ: 'Autres espèces du genre Urtica',
  A0DFY: 'Estragon de Russie',
  A0DFX: 'Stévia',
  A00VS: 'Myrte anisée',
  A00XQ: 'Myrte citronnée (herbe fraîche)',
  A00XK: "Feuilles d'onagre",
  A00YJ: 'Feuilles de sassafras',
  A00YS: 'Gingembre sauvage',
  A00YV: 'Feuilles de gaulthérie',
  A00XT: 'Fleurs de soucis',
  A0DGN: 'Souci nain',
  A0DGM: 'Tagète à feuilles ténues',
  A0DGL: 'Autres fleurs de soucis',
  A0DGH: 'Soucis des jardins (fleurs comestibles)',
  A00YD: 'Capucines (feuilles et fleurs)',
  A0DGK: 'Grandes capucines',
  A0DGJ: 'Capucines naines',
  A00YZ: 'Houblon',
  A01AQ: 'Pistils de fleurs',
  A0CXS: 'Safran et similaires',
  A01AR: 'Safran',
  A01AS: 'Autres pistils de fleurs',
  A0CZF: "Graine d'anis et similaires",
  A017Y: "Graine d'anis",
  A0CZE: 'Graine de carvi noir et similaires',
  A0CZD: 'Graine de carvi noir',
  A017Z: 'Graine de Nigelle de Crète',
  A04KN: 'Graine de céleri et similaires',
  A018A: 'Graine de céleri',
  A018B: "Graine d'angélique",
  A018C: 'Graine de livèche',
  A0CGQ: 'Graine de coriandre et similaires',
  A018D: 'Graine de coriandre',
  A0CGP: 'Graine de culantro',
  A0CZA: 'Graine de cumin et similaires',
  A018E: 'Graine de cumin',
  A0CYZ: 'Graine d’aneth et similaires',
  A018F: "Graine d'aneth",
  A0CYY: 'Graine de fenouil et similaires',
  A018G: 'Graine de fenouil ',
  A0CYX: 'Graine de fenouil amer',
  A0CYV: 'Graine de fenouil doux',
  A0CYT: 'Graine de Fenugrec et similaires',
  A018H: 'Graine de Fenugrec',
  A0CYS: 'Graine de muscade et similaires',
  A018J: 'Graine de muscade',
  A018M: 'Graine de rocou ',
  A019N: 'Graines de Kukui',
  A018P: 'Acacias',
  A171B: 'Noix de Faux muscadier Noix  Muscadier du Gabon',
  A018K: 'Autres épices en graines (p)',
  A0F7E: 'Graine de radhudi',
  A0CYA: 'Clous de girofle et similaires',
  A01AL: 'Clous de girofle',
  A01AP:
    'Boutons de casse (cannelle casse, cannelle de Chine, cannelle de Padang)',
  A0CXZ: 'Boutons de cannelle de Batavia',
  A0CXY: 'Boutons de casse de Chine',
  A0CXX: 'Boutons de cannelle de Saigon',
  A0CHA: 'Câpres et similaires',
  A01AM: 'Câpres',
  A019Q: 'Gousses de capucine',
  A0CXV: 'Gousses de grandes capucines',
  A0CXT: 'Gousses de capucines naines',
  A0CHJ: 'Myoga/gingembre japonais',
  A01AN: 'Autres boutons',
  A0CXR: 'Macis et similaires',
  A01AV: 'Macis',
  A01AX: 'Autres arilles',
  A019T: 'Écorce de cannelle et similaires',
  A019V: 'Écorce de cannelle',
  A019X: 'Écorce de casse (générique)',
  A0CYH: 'Écorce de cannelle de Batavia',
  A0CYG: 'Écorce de casse de Chine',
  A0CYF: 'Écorce de cannelle de Saigon',
  A019Y: 'Autres écorces',
  A0CYE: 'Racines de réglisse et similaires',
  A01AA: 'Racines de réglisse',
  A0CGX: 'Racines de gingembre et similaires',
  A0CYC: 'Rhizomes de galanga',
  A0CYD: 'Racines de curcuma rond',
  A01AJ: 'Rhizomes de galingale',
  A01AB: 'Racines de gingembre',
  A010L: 'Rhizomes de grand galanga',
  A010M: 'Rhizomes de petit galanga',
  A170P: "racine d'orris,  racine d'Iris florentina, ",
  A0CHH: 'Racines de raifort du Japon (wasabi)',
  A0CGZ: 'Racines de curcuma et similaires',
  A01AC: 'Racines de curcuma',
  A01AF: "Racines de l'ase fétide",
  A0CYB: 'Racines de temoe-lawacq',
  A0CHG: 'Racines de zédoaire',
  A0CGY: 'Racines de raifort et similaires',
  A00QL: 'Racines de raifort',
  A01AD: 'Autres racines et rhizomes',
  A18FY: 'Racines de gingembre épices',
  A0CYP: 'Piment de la Jamaïque/Myrte piment et similaires',
  A018R: 'Piment de la Jamaïque/Myrte piment',
  A0CGR: 'Poivre du Sichuan et similaires',
  A018S: 'Poivre du Sichuan',
  A0CHB: 'Poivre japonais',
  A0CHC: 'Uzazi',
  A0CGS: 'Carvi et similaires',
  A018T: 'Carvi',
  A018L: 'Ajowan',
  A0CGT: 'Cardamone et similaires',
  A018V: 'Cardamone',
  A0CYM: 'Marjolaine',
  A019R: 'Badiane/Anis étoilé',
  A0CYL: 'Baies de genièvre et similaires',
  A018X: 'Baies de genièvre',
  A018Y: 'Grains de poivre (blanc, noir ou vert) et similaires',
  A018Z: 'Grains de poivre (blanc, noir ou vert)',
  A019A: 'Poivre vert',
  A019B: 'Poivre blanc',
  A019C: 'Poivre noir',
  A018N: 'Faux poivre du Brésil (poivre rose, baie rose)',
  A019G: 'Cubèbe',
  A019P: 'Graine de paradis',
  A019E: 'Poivre long/pipali',
  A019D: 'Faux poivre odorant/Mollé (poivre rose, baie rose)',
  A0CYK: 'Sumac',
  A019F: 'Poivre des Achantis',
  A0CGV: 'Vanille et similaires',
  A019H: 'Vanille',
  A0CHE: 'Vanille de Tahiti',
  A01KY: 'Fève Tonka',
  A0CHF: 'Vanillon',
  A0CYJ: 'Tamarin et similaires',
  A019J: 'Tamarin',
  A019M: 'Autres fruits',
  A019K: 'Piment fort séché',
  A019L: 'Poudre de paprika',
  A016T: 'Herbes séchées',
  A016V: 'Angélique séchée',
  A016X: 'Feuilles de mélisse séchées',
  A016Y: 'Basilic séché',
  A016Z: 'Feuilles de laurier séchées',
  A017A: 'Bourrache séchée',
  A017B: 'Feuilles de céleri séchées',
  A017C: 'Fenouil séché',
  A017D: 'Hysope séchée',
  A017E: 'Lavande séchée',
  A017F: 'Livèche séchée',
  A017G: 'Marjolaine séchée',
  A017H: 'Menthe séchée',
  A017J: 'Romarin séché',
  A017K: 'Rue officinale séchée',
  A017L: 'Sauge séchée',
  A017M: 'Sarriette séchée',
  A017N: 'Cerfeuil musqué séché',
  A017P: 'Tanaisie et espèces apparentées séchées',
  A017Q: 'Thym séché',
  A017R: 'Feuilles de gaulthérie séchées',
  A017S: 'Aspérule séchée',
  A017T: 'Armoises séchées',
  A0BY4: 'Jus de fruits (100 % du fuit cité)',
  A03AN: 'Jus de fruits mélangés',
  A03AP: 'Jus multifruits',
  A03AQ: 'Jus pomme-raisin',
  A03AR: 'Jus pomme-cerise',
  A03AS: 'Jus orange-pamplemousse',
  A03AT: 'Jus pêche-abricot',
  A03AV: 'Jus fraise-cerise',
  A03AX: 'Jus mandarine-banane',
  A03AY: 'Jus abricot-orange',
  A03AZ: 'Jus orange-pêche',
  A03BA: 'Jus fruit rouge-raisin',
  A039M: 'Jus de pomme',
  A039N: "Jus d'abricot",
  A039P: 'Jus de groseille noire',
  A039Q: 'Jus de cassis',
  A039S: 'Jus de mûre',
  A03AG: "Jus d'agrumes",
  A039T: 'Jus de canneberge',
  A039V: 'Jus de baies de sureau',
  A03AK: 'Jus de raisin',
  A03AL: 'Jus de pamplemousse',
  A039X: 'Jus de goyave',
  A03AH: 'Jus de citron',
  A03AJ: 'Jus de limette',
  A039Y: 'Jus de mangue',
  A039Z: 'Jus de nectarine',
  A03AM: "Jus d'orange",
  A03AA: 'Jus de fruit de la passion',
  A03AB: 'Jus de pêche',
  A03AC: 'Jus de poire',
  A03AF: "Jus d'ananas",
  A03AD: 'Jus de grenade',
  A03AE: 'Jus de pruneaux',
  A039R: 'Jus de groseille rouge',
  A03BB:
    "Nectars de fruits (25 à 50 % de fruit minimum, tels que définis dans la législation de l'UE)",
  A03BC: "Nectar d'abricot",
  A03BD: 'Nectar de pomme',
  A03BE: 'Nectar de banane',
  A03BF: 'Nectar de mangue',
  A03BG: "Nectar d'orange",
  A03BH: 'Nectar de pêche',
  A03BJ: 'Nectar de poire',
  A03BK: "Nectar d'ananas",
  A03BL: 'Nectar de fruits mélangés',
  A04PQ: 'Jus de légumes',
  A03CS: 'Jus de légumes mélangés',
  A03CT: 'Jus de tomate et de légumes-feuilles',
  A03CV: 'Jus tomate-carotte',
  A03CX: 'Jus multilégumes',
  A03CK: 'Jus de carotte',
  A03CL: 'Jus de céleri',
  A03CQ: 'Jus de concombre',
  A03CJ: 'Jus de tomate',
  A03CR: 'Jus de navet',
  A03CN: 'Jus de chou blanc',
  A03CM: 'Jus de betterave',
  A03CP: 'Jus de pomme de terre',
  A03DB: 'Jus de fruits et de légumes mélangés',
  A03DC: 'Jus pomme-carotte',
  A03DD: 'Jus multifruits-carotte',
  A03DE: 'Jus mélangés avec ingrédients ajoutés',
  A03DF: 'Smoothies au fruit',
  A03DH: 'Jus multivitaminés',
  A01MM: 'Confiture de fruit/légume',
  A01MN: 'Confiture de fraise',
  A01MP: 'Confiture de framboise',
  A01MQ: 'Confiture de mûre',
  A01MR: 'Confiture de groseille rouge',
  A01MS: 'Confiture de groseille noire',
  A01MT: 'Confiture de petite airelle',
  A01MV: 'Confiture de groseille à maquereau',
  A01MX: 'Confiture de canneberge',
  A01MY: 'Confiture de myrtille',
  A01MZ: 'Confiture de cynorhodon',
  A01NA: "Confiture d'abricot",
  A01NB: 'Confiture de prune',
  A01NC: 'Confiture de pêche',
  A01ND: 'Confiture de cerise douce',
  A01NE: 'Confiture de cerise acide',
  A01NF: "Confiture d'orange",
  A01NG: 'Confiture de mandarine',
  A01NH: 'Confiture de fruits mélangés',
  A01NJ: 'Marmelade',
  A01NK: "Marmelade d'oranges",
  A01NL: 'Marmelade de mandarine',
  A01NM: "Marmelade d'agrumes mélangés",
  A16FC: 'Gelée de fruits',
  A01QP: 'Préparations à base de fruit utilisées pour farcir et/ou aromatiser',
  A01PD: 'Compote de fruit/légume',
  A01PF: 'Compote de fruit à la pomme',
  A01PH: "Compote de fruit à l'abricot",
  A01PP: 'Compote de fruit à la canneberge',
  A01PE: 'Compote de fruit à la mandarine',
  A01PM: 'Compote de fruit à la pêche',
  A01PG: 'Compote de fruit à la poire',
  A01PQ: "Compote de fruit à l'ananas",
  A01PJ: 'Compote de fruit à la prune',
  A01PK: 'Compote de fruit à la cerise douce',
  A01PL: 'Compote de fruit à la cerise acide',
  A01PN: 'Compote de fruit au raisin de table',
  A01PR: 'Compote de fruits mélangés',
  A01QJ: 'Purée de fruit ou de légume',
  A16GL: 'Dessert de fruits épaissi',
  A03DG: "Jus d'aloe vera",
  A0F4S: 'Eau de coco',
  A03BN: 'Concentrés de jus de fruit',
  A03BP: "Concentré de jus d'abricot",
  A03BQ: 'Concentré de jus de mûre',
  A03BR: 'Concentré de jus de myrtille',
  A03BS: 'Concentré de jus de groseille noire',
  A03BT: 'Concentré de jus de groseille rouge',
  A03BV: 'Concentré de jus de canneberge',
  A03BX: 'Concentré de jus de groseille à maquereau',
  A03BY: 'Concentré de jus de raisin',
  A03BZ: 'Concentré de jus de mandarine',
  A03CA: "Concentré de jus d'orange",
  A03CB: 'Concentré de jus de pêche',
  A03CC: 'Concentré de jus de prune',
  A03CD: 'Concentré de jus de framboise',
  A03CE: 'Concentré de jus de fraise',
  A03CF: 'Concentré de jus de cerise douce',
  A16MQ: 'Concentré de jus de pomme',
  A16MR: "Concentré de jus de d'ananas",
  A16MS: 'Concentré de jus de grenade',
  A03CZ: 'Concentré de jus de légume',
  A03CG: 'Jus de fruit déshydraté/en poudre',
  A03DA: 'Jus de légume déshydraté/en poudre',
  A0EVA: "Extrait liquide d'origine végétale",
  A01BK: 'Lait de coco (cocos nucifera)',
  A0ETZ: "Extrait en poudre d'origine végétale",
  A0F4R: 'Lait de coco en poudre',
  A03JK: 'Infusions à partir de feuilles et autres parties aériennes',
  A0D7B: 'Feuilles de fraisier pour infusion et similaires',
  A03JL: 'Feuilles de fraisier pour infusion',
  A00YY: "Feuilles d'armoise pour infusion",
  A0D7A: "Feuilles d'absinthe pour infusion",
  A0D3Z: "Feuilles d'armoise commune pour infusion",
  A0D3G: "Feuilles d'armoise pontique pour infusion",
  A0D3C: "Feuilles d'aurone mâle pour infusion",
  A0D6Y: 'Feuilles de luzerne cultivée pour infusion',
  A0D6Z: "Feuilles d'aigremoine pour infusion",
  A0BB6: "Gel d'aloe vera (pulpe)",
  A0D6V: "Feuilles d'alchémille des Alpes pour infusion",
  A0D6T: 'Feuilles de busserole pour infusion',
  A0D6S: 'Feuilles de myrtille européenne pour infusion',
  A0D6R: 'Feuilles de bouleau pour infusion',
  A0D6Q: "Feuilles d'oranger amer pour infusion",
  A0D6P: 'Feuilles de mûrier pour infusion',
  A0D6N: 'Feuilles de boldo pour infusion',
  A0D6M: 'Feuilles de buchu pour infusion',
  A0D6L: 'Feuilles de sarrasin pour infusion',
  A0D6K: "Feuilles d'herbe-aux-chats pour infusion",
  A0D6J: "Feuilles d'érythrée pour infusion",
  A0D6H: 'Feuilles de swertie pour infusion',
  A0D6G: 'Feuilles de sauge sclarée pour infusion',
  A0D6F: 'Feuilles de lycopode claviforme pour infusion',
  A0D6E: 'Feuilles de véronique officinale pour infusion',
  A0D6D: 'Feuilles de verveine commune pour infusion',
  A0D6C: 'Feuilles de bleuet pour infusion',
  A0D6B: "Feuilles d'airelle rouge pour infusion",
  A0D6A:
    'Feuilles de groseillier à grappes (noire, rouge ou blanche) pour infusion',
  A0D5Z: 'Feuilles de groseillier noire pour infusion',
  A0D5Y: 'Feuilles de groseillier rouge pour infusion',
  A0D5X: 'Feuilles de damiana pour infusion',
  A0D5V: 'Feuilles de chanvre sauvage pour infusion',
  A0D5T: "Feuilles d'échinacée pour infusion",
  A0D5S: "Feuilles d'échinacée à feuilles étroites pour infusion",
  A0D5R: "Feuilles d'échinacée pâle pour infusion",
  A0D5Q: "Feuilles d'échinacée pourpre pour infusion",
  A0D5P: 'Feuilles de sureau noir pour infusion',
  A0D5N: "Feuilles d'eucalyptus pour infusion",
  A0D5M: "Feuilles d'euphraise pour infusion",
  A0D5L: 'Feuilles de prêle des champs pour infusion',
  A0D5K: 'Feuilles de fumeterre pour infusion',
  A0D5J: "Feuilles de lilas d'Espagne pour infusion",
  A0D5H: "Feuilles d'orpin rose pour infusion",
  A0D5G: 'Feuilles de verge d’or pour infusion',
  A0D5F: 'Feuilles de lierre terrestre pour infusion',
  A0D5E: 'Feuilles de gymnème sylvestre pour infusion',
  A0D5D: "Feuilles d'herniaire hirsute pour infusion",
  A0D5C: "Feuilles d'aubépine pour infusion",
  A0D5B: 'Feuilles de noisetier pour infusion',
  A0D5A: 'Feuilles de pensée sauvage pour infusion',
  A0D4Z: 'Feuilles de bruyère pour infusion',
  A0D4Y: 'Feuilles de chanvre pour infusion',
  A0D4X: 'Feuilles de chanvre commun pour infusion',
  A0D4V: 'Feuilles de chanvre ruderalis pour infusion',
  A0D4T: 'Feuilles de benoîte commune pour infusion',
  A0D4S: 'Feuilles de chardon bénit pour infusion',
  A0D4R: 'Feuilles de marrube blanc pour infusion',
  A0D4Q: "Feuilles de marronnier d'Inde pour infusion",
  A0D4P: 'Feuilles de crapaudine pour infusion',
  A0D4N: 'Feuilles de jiaogulan pour infusion',
  A0D4M: 'Feuilles de renouée des oiseaux pour infusion',
  A0D4L: "Feuilles d'alchémille vulgaire pour infusion",
  A0D4K: 'Feuilles de myrte citronnée pour infusion',
  A03JN: 'Feuilles de verveine odorante pour infusion',
  A0D4J: 'Feuilles de limettier pour infusion',
  A0D4H: 'Feuilles de tilleul à petites feuilles pour infusion',
  A0D4G: 'Feuilles de tilleul à grandes feuilles pour infusion',
  A0D4F: 'Feuilles de tilleul argenté pour infusion',
  A0D4E: 'Feuilles de mauve sauvage pour infusion',
  A0D4D: 'Feuilles de guimauve pour infusion',
  A0D4C: 'Feuilles de reine-des-prés pour infusion',
  A0D4B: 'Feuilles de gui pour infusion',
  A0D4A: 'Feuilles de mélisse de Moldavie pour infusion',
  A0D3Y: 'Feuilles de mûrier (noir ou blanc) pour infusion',
  A0D3X: 'Feuilles de mûrier blanc pour infusion',
  A0D3V: 'Feuilles de mûrier noir pour infusion',
  A0D3T: 'Feuilles de molène pour infusion',
  A0D3S: "Feuilles d'épicéa commun pour infusion",
  A0D3R: "Feuilles d'avoine pour infusion",
  A0D3Q: "Feuilles d'olivier pour infusion",
  A0D3P: 'Feuilles de papayer pour infusion',
  A0D3N: 'Feuilles de passiflore pour infusion',
  A0D3M: 'Feuilles de plantain pour infusion',
  A0D3L: 'Feuilles de framboisier (rouge et jaune) pour infusion',
  A0D3K: 'Feuilles de trèfle violet pour infusion',
  A0D3J: 'Feuilles de plantain lancéolé pour infusion',
  A0D3H: 'Feuilles de ciste pour infusion',
  A0D3F: 'Feuilles de bourse-à-pasteur pour infusion',
  A0D3E: 'Feuilles de potentille ansérine/ pour infusion',
  A0D3D: "Feuilles d'herniaire glabre pour infusion",
  A0D3B: 'Feuilles de millepertuis pour infusion',
  A0D3A: 'Feuilles de ronce sucrée de Chine pour infusion',
  A0D2Z: "Feuilles d'oranger doux pour infusion",
  A0D2Y: 'Feuilles de trigonelle bleue pour infusion',
  A00YX: "Feuilles d'aspérule odorante pour infusion",
  A00YN: 'Feuilles de tanaisie pour infusion',
  A0D2T: "Feuilles d'arbre à thé pour infusion",
  A0D2S: 'Feuilles de linaire commune pour infusion',
  A0D2R: 'Feuilles de noyer pour infusion',
  A0D2Q: "Feuilles de noyer noir d'Amérique pour infusion",
  A0D2P: 'Feuilles de noyer commun pour infusion',
  A0D2N: 'Feuilles de lamier blanc pour infusion',
  A0D2M: "Feuilles d'angélique sauvage pour infusion",
  A0D2L: 'Feuilles de fraisier des bois pour infusion',
  A0D2K: "Feuilles d'épilobe à feuilles étroites pour infusion",
  A0D2J: "Feuilles d'hamamélis de Virginie pour infusion",
  A0D2H: 'Feuilles de bétoine pour infusion',
  A0D2G: "Feuilles d'achillée millefeuille pour infusion",
  A0D2F: 'Feuilles de gaillet vrai pour infusion',
  A00XY: 'Feuilles de mélilot jaune pour infusion',
  A171A: 'Muña infusion leaves',
  A04KM: 'Rooibos et similaires',
  A03JP: 'Rooibos ',
  A0D2D: 'Honeybush',
  A0D2C: 'Thé honeybush commun',
  A0D2B: 'Thé des montagnes',
  A0D2A: 'Thé de Heidelberg',
  A0D1Z: 'Thé de Vlei',
  A0D1Y: 'Maté et similaires',
  A03JM: 'Infusion de maté',
  A03JQ: 'Infusion de gingko',
  A0CGN: 'Infusion de noni',
  A176M: 'Moringa infusion material',
  A03JR: 'Autres infusions à partir de feuilles et autres parties aériennes',
  A0D9E: 'Camomille et similaires',
  A03JC: 'Camomille',
  A0D9D: 'Camomille commune',
  A0D9C: 'Camomille allemande',
  A0D9B: "Fleurs d'hibiscus pour infusion et similaires",
  A03JD: "Fleurs d'hibiscus pour infusion",
  A0D9A: 'Fleurs de rosier pour infusion et similaires',
  A03JE: 'Fleurs de rosier pour infusion',
  A0D8Z: "Fleurs d'amandier pour infusion",
  A0D8Y: 'Fleurs de monarde écarlate pour infusion',
  A0D8X: "Fleurs d'oranger amer pour infusion",
  A0D8V: 'Fleurs de robinier pour infusion',
  A0D8T: 'Fleurs de pied-de-chat pour infusion',
  A0D8S: 'Fleurs de chrysanthème pour infusion',
  A0D8R: 'Fleurs de cannelle pour infusion',
  A0D8Q: 'Fleurs de sauge sclarée pour infusion',
  A0D8P: 'Fleurs de bleuet pour infusion',
  A0D8N: 'Fleurs de primevère pour infusion',
  A0D8M: 'Fleurs de pâquerette pour infusion',
  A0D8L: 'Fleurs de genêt des teinturiers pour infusion',
  A03JG: 'Fleurs de sureau noir pour infusion',
  A0D8K: 'Fleurs de pavot coquelicot pour infusion',
  A0D8J: 'Fleurs de grande molène pour infusion',
  A0D8H: "Fleurs d'aubépine pour infusion",
  A0D8G: 'Fleurs de bruyère pour infusion',
  A0D8F: 'Fleurs de rose trémière pour infusion',
  A0D8E: "Fleurs de marronnier d'Inde pour infusion",
  A0D8D: 'Fleurs de dauphinelle/ pour infusion',
  A00XP: 'Fleurs de lavande pour infusion',
  A0D8B: 'Fleurs de mauve sauvage pour infusion',
  A0D8A: 'Fleurs de reine-des-prés pour infusion',
  A0D7Z: 'Fleurs de molène pour infusion',
  A0D7Y: "Fleurs d'oranger pour infusion",
  A0D7X: 'Fleurs de pivoine pour infusion',
  A0D7V: 'Fleurs de trèfle violet pour infusion',
  A0D7T: 'Fleurs de lotus sacré pour infusion',
  A0D7S: 'Fleurs de carthame pour infusion',
  A0D7R: "Fleurs d'immortelle des sables pour infusion",
  A0D7Q: 'Fleurs de millepertuis pour infusion',
  A0D7P: 'Fleurs de tournesol pour infusion',
  A0D7N: "Fleurs d'olivier odorant pour infusion",
  A0D7M: 'Fleurs de violette odorante pour infusion',
  A0D7L: 'Fleurs de lamier blanc pour infusion',
  A0D7K: "Fleurs d'achillée millefeuille pour infusion",
  A0D7J: 'Fleurs de ylang-ylang pour infusion',
  A04KL: 'Fleurs de jasmin pour infusion et similaires',
  A03JF: 'Fleurs de jasmin blanc pour infusion',
  A0D7G: "Fleurs de jasmin d'Arabie pour infusion",
  A0D7F: 'Fleurs de limettier pour infusion et similaires',
  A03JH: 'Fleurs de limettier',
  A0D7E: 'Fleurs de tilleul à petites feuilles pour infusion',
  A0D7D: 'Fleurs de tilleul à grandes feuilles pour infusion',
  A0D7C: 'Fleurs de tilleul argenté pour infusion',
  A03JJ: 'Autres infusions à partir de fleurs',
  A0D9J: 'Fèves de café et similaires',
  A03GK: 'Fèves de café vert',
  A0D9H: 'Fèves de café arabica',
  A0D9F: 'Fèves de café excelsa',
  A0D9G: 'Fèves de café robusta',
  A0CZM: 'Fèves de cacao et similaires',
  A03HF: 'Fèves de cacao',
  A03HL: 'Noix de kola',
  A0CZL: "Noix d'abata cola",
  A0CZK: 'Noix de kolatier',
  A0D1X: 'Racines de valériane pour infusion et similaires',
  A03JT: 'Racines de valériane pour infusion',
  A0D1V: "Racines d'iris versicolore pour infusion",
  A01AG: "Racines d'acore aromatique pour infusion",
  A0D1R: 'Racines de chiendent pour infusion',
  A0D1Q: 'Racines de primevère pour infusion',
  A0D1P: "Racines d'échinacée pour infusion",
  A0D1N: "Racines d'échinacée à feuilles étroites pour infusion",
  A0D1M: "Racines d'échinacée pâle pour infusion",
  A0D1L: "Racines d'échinacée pourpre pour infusion",
  A01AH: "Racines d'inule aulnée pour infusion",
  A0D1J: 'Racines de sumac odorant pour infusion',
  A0D1H: "Racines d'orpin rose pour infusion",
  A0D1G: 'Racines de benoîte commune pour infusion',
  A0D1F: 'Racines de guimauve pour infusion',
  A0D1E: "Racines d'origan marjolaine pour infusion",
  A0D1D: 'Racines de pimprenelle pour infusion',
  A0D1C: 'Racines de ratanhia pour infusion',
  A0D1B: 'Racines de salsepareille pour infusion',
  A0D1A: 'Racines de polygala de Virginie pour infusion',
  A0D0Z: 'Racines de potentille tormentille pour infusion',
  A01AE: "Racine d'angélique sèche",
  A0D0X: 'Ginseng et similaires',
  A03JV: 'Racines de ginseng pour infusion',
  A0D0V: 'Racines de ginseng asiatique pour infusion',
  A0D0T: 'Racines de ginseng américain pour infusion',
  A0D0S: 'Racines de ginseng sibérien pour infusion',
  A03JX: 'Autres infusions à partir de racines',
  A0CZJ: 'Caroubes et similaires',
  A01HY: 'Caroubes',
  A0CZH: 'Jengkols',
  A03JY: 'Infusions à partir de toute autre partie de la plante',
  A0D0R: "Ispahul/Psyllium de l'Inde (graines, téguments pour infusion)",
  A0D0Q: 'Camomille (graines pour infusion)',
  A0D0P: 'Cerises (douces) (tiges pour infusion)',
  A0D0N: 'Quinquina (écorce pour infusion)',
  A0D0M: 'Quinquina jaune (écorce pour infusion)',
  A0D0L: 'Quinquina rouge (écorce pour infusion)',
  A0D0K: 'Cacao (coques pour infusion)',
  A0D0J: 'Condurango (écorce pour infusion)',
  A0D0H: 'Pin mugo (pousses pour infusion)',
  A0D0G: 'Sapin (pousses pour infusion)',
  A0D0F: 'Lentisque (graines pour infusion)',
  A0D0E: 'Sumac odorant (écorce pour infusion)',
  A03HM: 'Guarana (graines pour infusion)',
  A0D0D: 'Hibiscus (graines pour infusion)',
  A0D0C: "Marron d'Inde (graines, écorce pour infusion)",
  A0D0B: 'Genévrier (écorce, bois, pousses pour infusion)',
  A0D0A: 'Lapacho (écorce pour infusion)',
  A0CZZ: 'Gaïac (écorce, bois pour infusion)',
  A0CZY: 'Persil (fruits pour infusion)',
  A0CZX: 'Casse/Cassie (fruits pour infusion)',
  A0CZV: 'Quassia/Bois amer de Suriname (écorce, bois pour infusion)',
  A0CZT: 'Santal rouge (écorce, bois pour infusion)',
  A0CZS: 'Bois de Panama/Quillaja savonneux (écorce pour infusion)',
  A0CZR: 'Cerise acide (tiges pour infusion)',
  A0D1T: 'Maïs doux (stigmates, styles)',
  A0CZQ: 'Angélique sauvage/Angélique des bois (fruits pour infusion)',
  A0CZP: 'Hamamélis de Virginie/Noisetier des sorcières (écorce pour infusion)',
  A03HP: 'Cupuaçu',
  A0CZN: 'Autres infusions à partir de toute autre partie de la plante',
  A185T:
    "Salep, boisson à base de farine faite à partir de tubercules d'Orchis ",
  A0C6A: 'Dérivés de café brut',
  A03GL: 'Fèves de café torréfié',
  A03GM: 'Fèves de café torréfié et décaféiné',
  A03GN: 'Café moulu torréfié',
  A03GP: 'Café moulu torréfié et décaféiné',
  A0DLJ: 'Concentré liquide de café',
  A03GQ: 'Poudre de café instantané',
  A03GR: 'Poudre de café instantané décaféiné',
  A03GS: 'Succédanés de café',
  A03HD: 'Succédanés de cafés mélangés',
  A03GT: 'Succédané de café à base de malt',
  A03GV: 'Succédané de café à base de chicorée',
  A03GX: 'Succédanés de café mineurs',
  A03GY: 'Succédané de café à base de seigle',
  A03GZ: 'Succédané de café à base de blé',
  A03HA: "Succédané de café à base de d'orge",
  A03HB: 'Succédané de café à base de figue',
  A03HC: 'Succédané de café à base de betterave sucrière',
  A0C6B: 'Dérivés de cacao brut',
  A03HG: 'Poudre de cacao',
  A03HJ: 'Pâte de cacao',
  A03HQ: 'Feuilles de thé et ingrédients pour le thé',
  A04KK: 'Feuilles de thés, séchées et/ou fermentées, et similaires',
  A03HR: 'Feuilles et tiges de thé fermenté',
  A03HV: 'Feuilles et tiges de thé déthéiné',
  A03HS: 'Feuilles de thé et tiges avec fruits et arômes',
  A03HT: 'Feuilles de thé vert',
  A03HX: 'Ingrédients et extraits de thé en poudre',
  A03HY: 'Extraits de thé (en poudre)',
  A03HZ: 'Extraits de thé (en poudre) déthéiné',
  A16GP: 'Extraits de thé (liquide)',
  A16GX: "Extraits d'infusion (Dérivés de denrées non transformées) ",
  A16GY: "Extraits d'infusion (poudre) ",
  A16GZ: "Extraits d'infusion (liquide) ",
  A03HH: 'Préparation en poudre pour boisson à base de cacao',
  A0F2E: 'Préparation en poudre pour boisson à base de café',
  A03KB: 'Café espresso (boisson)',
  A167A: 'Café fort (boisson)',
  A03KC: 'Café mi-fort (boisson)',
  A03KD: 'Café léger (boisson)',
  A03KE: 'Café instantané (boisson)',
  A03KF: 'Café décaféiné (boisson)',
  A03KG: 'Café au lait ou café crème',
  A03KH: 'Cappuccino',
  A03KJ: 'Café macchiato',
  A03KK: 'Café glacé',
  A03KM: 'Café à base de malt',
  A03KN: 'Café à base de chicorée',
  A03KP: 'Infusions à base de succédanés mineurs de café',
  A03KQ: 'Café à base de seigle',
  A03KR: 'Café à base de blé',
  A03KS: "Café à base d'orge",
  A03KT: 'Café à base de figue',
  A03KV: 'Café à base de betterave sucrière',
  A03KX: 'Infusion à base de succédanés de cafés mélangés',
  A03KZ: 'Chocolat chaud',
  A03LA: 'Chocolat chaud avec ingrédients ajoutés',
  A03LC: 'Infusion de thé (noir, blanc)',
  A03LD: 'Infusion de thé vert',
  A03LE: 'Infusion de thé déthéiné',
  A03LF: 'Infusion de thé avec ingrédients aromatisants ajoutés',
  A03LH: 'Infusion de camomille',
  A03LJ: 'Infusion de jasmin',
  A03LK: 'Infusion de fruit',
  A03LL: 'Infusion de roselle',
  A03LM: 'Infusion de rose',
  A03LN: 'Infusion de fleurs de sureau noir',
  A03LP: 'Infusion de fleurs de limettier',
  A03LQ: 'Infusion de feuilles de fraisier',
  A03LR: 'Maté',
  A03LS: 'Infusion de verveine odorante',
  A03LT: 'Infusion de rooibos',
  A16GQ: 'Menthe poivrée pour infusion',
  A16GR: 'Fenouil pour infusion',
  A03LV: 'Infusion de gingko',
  A03LX: 'Infusion de racine de valériane',
  A03LY: 'Infusion de racine de ginseng',
  A03MB: 'Bière',
  A03MC: 'Bière blonde',
  A03MD: 'Bière forte',
  A03ME: 'Bière ordinaire',
  A03MF: 'Bière légère',
  A03MG: 'Bière sans alcool',
  A03MH: 'Bière Ale',
  A03MJ: 'Bière Ale blonde',
  A03MK: 'Bière Ale brune',
  A03ML: 'Stout',
  A03MM: 'Bière de froment',
  A03MN: 'Boissons ressemblant à la bière',
  A03MP: 'Bières à faible teneur en malt',
  A03MQ: 'Panaché',
  A03MR: 'Boissons mélangées à base de bière',
  A16GG: 'Racinette',
  A03MT: 'Vin',
  A03MV: 'Vin blanc',
  A03MX: 'Vin rouge',
  A03MY: 'Rosé',
  A03MZ: 'Vins pétillants',
  A03NA: 'Vin mousseux',
  A03NB: 'Vin pétillant rouge ou rosé',
  A03NC: 'Boissons ressemblant à du vin',
  A03ND: 'Cidre',
  A03NE: 'Poiré',
  A03NF: 'Vins pour dessert',
  A03NN: 'Vin de glace et vin de paille',
  A03NP: 'Vin de paille',
  A03NQ: 'Vin de glace',
  A03NR: 'Vin issu de vendanges «botrytisées»',
  A03NG: 'Vins fortifiés et liquoreux',
  A03NH: 'Vermouth',
  A03NJ: 'Sherry',
  A03NK: 'Madère',
  A03NL: 'Porto',
  A03NM: 'Marsala',
  A03NT: 'Liqueur à base de fruits',
  A03NV: "Liqueur à base d'herbes",
  A03NX: 'Liqueur à base de chocolat',
  A03NY: 'Liqueur à base de café',
  A03NZ: "Liqueur à base d'œuf",
  A03PA: 'Liqueur à base de crème',
  A03PB: 'Liqueur à base de crème de noix de coco',
  A03PC: 'Liqueur à base de lait',
  A0CSC: 'Spiritueux autres que ceux à base de fruits',
  A03PL: 'Tequila et spiritueux similaires',
  A03PJ: 'Rhum',
  A03PH: 'Vodka et spiritueux type vodka.',
  A03PF: 'Whisky',
  A0CSA: 'Spiritueux à base de fruits',
  A0CSB: 'Spiritueux à base de fruits autres que des fruits à noyau',
  A03PE: 'Brandy',
  A03PK: 'Spiritueux à base de fruits à noyau',
  A04QG: 'Spiritueux aromatisés non sucrés',
  A03PG: 'Gin',
  A03PN: 'Cocktail',
  A03PP: 'Boisson «alcopop» et vin aromatisé',
  A03PQ: 'Autres mélanges de boissons alcoolisées',
  A03PR: "Mélanges de bière et d'autres boissons alcoolisées",
  A03PS: 'Punch',
  A03PT: 'Sangria',
  A034G: 'Chocolat amer',
  A034H: 'Chocolat amer sucré',
  A034J: 'Chocolat au lait',
  A034K: 'Chocolat de ménage',
  A034L: 'Chocolat à la crème',
  A034M: 'Chocolat de couverture',
  A034P: 'Chocolat blanc',
  A034N: 'Gianduja',
  A0C6P: 'Pâte à tartiner au cacao',
  A034Q: 'Chocolat fourré',
  A034R: 'Confiserie enrobée de chocolat',
  A034S: 'Pralines',
  A0EQP: 'Bonbons mous et analogues',
  A035H: 'Produits à base de mousse de sucre (guimauves)',
  A035J: 'Bonbons à la réglisse',
  A035K: 'Boules de gommes',
  A035L: 'Bonbons gélifiés',
  A035M: 'Chewing gum',
  A034X: 'Bonbons durs',
  A035F: 'Dragée enrobée de sucre',
  A034Y: 'Massepain',
  A035C: 'Fudge',
  A035E: 'Halva à base de noix et de graines',
  A035G: 'Barbe à papa',
  A035B: 'Toffee',
  A035D: 'Nougat',
  A034Z: 'Caramel dur',
  A0FDG: 'Loukoum',
  A0FDH: 'Pâte de coing',
  A035P: 'Massepain cru',
  A035Q: 'Pâte de réglisse',
  A035R: 'Pâte de chocolat',
  A035S: 'Pâte de nougat blanc',
  A035T: 'Pâte de nougat crue',
  A035V: 'Pâte de guimauve',
  A035X: 'Pâte fondante',
  A035Y: 'Pâte de nougatine',
  A035Z: 'Pâte de truffe',
  A036A: 'Meringue',
  A036B: 'Pâte de noix',
  A036C: 'Pâte à macaron',
  A036D: 'Pâte à biscuit florentin',
  A036E: 'Pâte de graines oléagineuses',
  A035A: 'Caramel mou',
  A03PY: 'Préparations pour nourrissons et préparations de suite',
  A0EQM: 'Préparations pour nourrissons',
  A03PZ: 'Préparations pour nourrissons, en poudre',
  A03QA: 'Préparation pour nourrissons, à base de lait, en poudre',
  A03QB: 'Préparation pour nourrissons, à base de soja, en poudre',
  A03QC: 'Préparation pour nourrissons, à base de lait et de soja, en poudre',
  A03QD:
    "Préparation pour nourrissons, à base d'hydrolysats de protéines, en poudre",
  A03QE: 'Préparations pour nourrissons, liquides',
  A03QF: 'Préparation pour nourrissons, à base de lait, liquide',
  A03QG: 'Préparation pour nourrissons, à base de soja, liquide',
  A03QH: 'Préparation pour nourrissons, à base de lait et de soja, liquide',
  A03QJ:
    "Préparation pour nourrissons, à base d'hydrolysats de protéines, liquide",
  A0EQL: 'Préparations de suite',
  A03QK: 'Préparations de suite, en poudre',
  A03QL: 'Préparation de suite, à base de lait, en poudre',
  A03QM: 'Préparation de suite, à base de soja, en poudre',
  A03QN: 'Préparation de suite, à base de lait et de soja, en poudre',
  A03QP: "Préparation de suite, à base d'hydrolysats de protéines, en poudre",
  A03QQ: 'Préparations de suite, liquides',
  A03QR: 'Préparation de suite, à base de lait, liquide',
  A03QS: 'Préparation de suite, à base de soja, liquide',
  A03QT: 'Préparation de suite, à base de lait et de soja, liquide',
  A03QV: "Préparation de suite, à base d'hydrolysats de protéines, liquide",
  A03RC: 'Plat préparé pour nourrissons et enfants en bas âge',
  A03RF: 'Plat préparé, à base de viande, pour enfants',
  A03RG: 'Plat préparé, à base de poisson, pour enfants',
  A03RH: 'Plat préparé, à base de lait, pour enfants',
  A03RE: 'Plat préparé à base de céréale, pour enfants',
  A03RJ: 'Plat préparé, à base de fruit, pour enfants',
  A03RD: 'Plat préparé, à base de légume, pour enfants',
  A03RK: 'Plats préparés mélangés, pour enfants',
  A03QX:
    'Préparations à base de céréales destinées aux nourrissons et aux enfants en bas âge',
  A03QZ:
    "Céréales contenant un aliment ajouté à teneur élevée en protéines qui doivent être reconstituées avec de l'eau ou un autre liquide exempt de protéines",
  A0BZF:
    'Céréales contenant un aliment ajouté à teneur élevée en protéines, reconstituées',
  A03QY:
    "Céréales simples qui doivent être reconstituées avec du lait ou d'autres liquides nutritifs appropriés",
  A0BZE: 'Céréales simples pour nourrissons ou enfants, reconstituées',
  A03RA: 'Biscuits, biscottes et cookies pour enfants',
  A03RB: 'Pâtes pour enfants (sèches, à cuire)',
  A03RM: 'Infusions spécifiques pour nourrissons et enfants en bas âge',
  A16GS: 'Infusions spécifiques séchées pour nourrissons et enfants en bas âge',
  A03RN:
    'Jus et nectars de fruits et de légumes spécifiques pour nourrissons et enfants en bas âge',
  A03RP: 'Aliments spéciaux pour la croissance des enfants',
  A03YY:
    'Sandwichs, pizzas et autres produits fourrés à base de céréales semblables à du pain',
  A03YZ: 'Sandwichs et plats de type sandwich',
  A03ZB: 'Sandwich avec garniture à base de viande transformée',
  A03ZG: 'Sandwich avec garniture à base de viande et de légumes',
  A03ZM: 'Sandwich au steak',
  A03ZJ: 'Kebab',
  A03ZK: 'Hot dog avec pain',
  A03ZL: 'Hamburger avec pain',
  A03ZC: 'Sandwich avec garniture à base de poisson',
  A03ZH: 'Sandwich avec garniture à base de poisson et de légumes',
  A03ZA: 'Sandwich avec garniture à base de fromage',
  A03ZE: 'Sandwich avec garniture à base de fromage et de légumes',
  A03ZF: "Sandwich avec garniture à base de fromage, d'œufs et de légumes",
  A03ZD: 'Sandwich avec garniture à base de légumes',
  A03ZN: 'Pizza et plats de type pizza',
  A03ZQ: 'Pizza et similaires avec garniture à base de viande transformée',
  A03ZX: 'Pizza et similaires avec viande et légumes',
  A040A: 'Pizza et similaires avec fromage, viande et champignons',
  A040B: 'Pizza et similaires avec fromage, viande, champignons et légumes',
  A03ZR: 'Pizza et similaires avec garniture à base de poisson/fruit de mer',
  A03ZP: 'Pizza et similaires avec garniture à base de fromage',
  A03ZS: 'Pizza et similaires avec garniture à base de légumes',
  A03ZT: 'Pizza et similaires avec fromage et légumes',
  A03ZV: 'Pizza et similaires avec fromage, légumes et fruits',
  A03ZY: 'Pizza et similaires avec fromage, viande et légumes',
  A03ZZ: 'Pizza et similaires avec fromage et champignons',
  A0CEN: 'Tartes et tourtes salées',
  A03YM: 'Quiche',
  A00CA: 'Tourte salée au fromage',
  A03YA: 'Tourte aux légumes',
  A040C: 'Amuse-bouche',
  A040J: 'Canapé à base de viande',
  A040D: 'Friand à la saucisse',
  A040H: 'Canapé à base de poisson',
  A040L: 'Canapé à base de fromage',
  A040K: "Canapé à base d'œuf",
  A040E: 'Canapé fourré',
  A040F: 'Rouleaux de printemps',
  A040G: 'Vol-au-vent',
  A03YT: 'Crostini aux champignons',
  A040M: 'Plats à base de pâtes et de riz (ou autre céréale)',
  A007S: 'Plats à base de pâte, non cuits',
  A040N: 'Plats à base de pâte, cuits',
  A0CDQ: 'Pâtes natures (non fourrées), cuites',
  A040P: 'Lasagne',
  A040Q: 'Pâtes, cuites, avec sauce mélangée',
  A040R: 'Pâtes, cuites, avec légumes',
  A040S: 'Pâtes, cuites, avec fromage/crème',
  A040T: 'Pâtes, cuites, avec légumes et fromage/crème',
  A03YX: 'Pâtes, cuites, avec sauce aux champignons',
  A0CDP: 'Pâtes, garnies, cuites',
  A040V: 'Pâtes, garnies, avec mélange de viandes, cuites',
  A040X: 'Pâtes, garnies, avec mélange de légumes, cuites',
  A040Y: 'Pâtes, avec garniture mélangée ou non spécifiée, cuites',
  A0BA2: 'Plats déshydratés à base de riz, non cuits',
  A040Z: 'Plats à base de riz, cuits',
  A041H: 'Plat à base de viande et de riz',
  A041J: 'Plat à base de viande, de légumes et de riz',
  A16GF: 'Plats à base de riz, poisson/fruits de mer et légumes',
  A041D: 'Paëlla',
  A041A: 'Dolma',
  A041B: 'Riz au lait',
  A041C: 'Nasi goreng',
  A041E: 'Pudding de riz',
  A041F: 'Risotto',
  A041G: 'Plat à base de riz et de légumes',
  A18PS: 'Plats à base de céréales (autres que les pâtes et le riz)',
  A03VC:
    "Plats à l'exclusion des plats à base de pâtes ou de riz, les sandwichs et les pizzas",
  A03VV: 'Plats à base de viande',
  A03VX: 'Goulash',
  A03VY: 'Ragoût de viande',
  A03VZ: 'Aspic de viande',
  A03XA: 'Pain de viande',
  A03XB: 'Pain de viande de porc',
  A03XC: 'Pain de viande de bœuf',
  A03XD: 'Pain de viande avec fromage, légumes ou autres',
  A03XE: 'Moussaka',
  A03XF: 'Hamburger à base de viande (non considéré comme un sandwich)',
  A03XG: 'Boulettes de viande',
  A03XH: 'Terrine de viande',
  A16ER: "Plats à base d'abats",
  A03XJ: 'Plats à base de poisson et de fruits de mer',
  A03XK: 'Bouillabaisse',
  A03XL: 'Gratin de poisson',
  A03XM: 'Plats à base de fruits de mer',
  A03XN: 'Salade à base de fruits de mer',
  A03XP: 'Salade préparée à base de poisson',
  A03XQ: 'Plat à base de poisson et de pomme de terre',
  A03XR: 'Plat à base de poisson et de riz',
  A03XS: 'Plat à base de poisson et de légumes',
  A03XT: 'Pains de poisson',
  A03XV: 'Pâté de poisson',
  A02KD: 'Boulettes de poisson',
  A03YJ: "Plats à base d'œufs",
  A03YN: 'Omelette nature',
  A03YK: 'Omelette au fromage',
  A03YP: 'Omelette au bacon',
  A03YQ: 'Omelette aux légumes',
  A03YR: 'Omelette aux champignons',
  A03YL: 'Œufs Bénédicte',
  A03VM: 'Plats à base de légumineuses',
  A03VN: 'Houmous',
  A03VP: 'Dal',
  A03VQ: 'Salade à base de tofu',
  A03VR: 'Plat à base de haricots et de viande',
  A03VS: 'Plat à base de haricots et de légumes',
  A03VT: 'Plat à base de haricots, de viande et de légumes',
  A01BA: 'Purée de pois',
  A16FF: 'Plats à base de pains (ou produits similaires)',
  A03VD: 'Plats à base de pommes de terre',
  A03VE: 'Hachis parmentier',
  A03VF: 'Poêlée de pommes de terre',
  A03VG: 'Salade préparée à base de pommes de terre',
  A03VH: 'Plat à base de pommes de terre et de légumes',
  A03VJ: 'Plat à base de pommes de terre et de viande',
  A03VK: 'Plat à base de pommes de terre, de viande et de légumes',
  A03VL: 'Plat à base de pommes de terre et de fromage',
  A011M: 'Rösti',
  A011Q: 'Pommes de terre sautées',
  A011N: 'Frites',
  A0BYV: 'Frites à base de pommes de terre tranchées',
  A0BYX: 'Frites fabriquées à partir de pâte de pommes de terre',
  A0C6H: 'Purée de pommes de terre',
  A0C6K: 'Purée fabriquée à partir de pommes de terre',
  A0C6J: 'Purée fabriquée à partir de flocons ou de poudre de pommes de terre',
  A011S: 'Croquettes de pommes de terre',
  A03YS: 'Plats à base de champignons',
  A03XX: 'Plats à base de légumes',
  A03XY: 'Mélange de légumes en crème',
  A03XZ: 'Poêlée de légumes',
  A16GB: 'Légumes mélangés',
  A03YB: 'Mélange de légumes grillés',
  A03YC: 'Mélange de légumes frits',
  A03YD: 'Mélange de légumes braisés',
  A03YE: 'Mélange de légumes bouillis',
  A03YF: 'Légumes gratinés',
  A03YG: 'Soufflé aux légumes',
  A03YH: 'Ratatouille',
  A0B9J: 'Soupes (mélange déshydraté non cuit)',
  A0B9Q: 'Soupe à base de viande/volaille, déshydratée',
  A0B9P: 'Soupe à base de viande et de légumes, déshydratée',
  A0B9N: 'Soupe de poissons, déshydratée',
  A0B9L: 'Soupe à base de produits laitiers/œufs, déshydratée',
  A0B9T: 'Soupe aux légumineuses (haricots), déshydratée',
  A0B9V: 'Soupe de pommes de terre, déshydratée',
  A0B9M: 'Soupe aux céréales, déshydratée',
  A0B9Y: "Soupe à l'oignon, déshydratée",
  A0B9X: 'Soupe à la tomate, déshydratée',
  A0B9S: 'Soupe aux champignons, déshydratée',
  A0B9R: 'Soupe de légumes mélangés, déshydratée',
  A0B9K: 'Soupe de fruits, déshydratée',
  A041L: 'Soupes (prêtes à être consommées)',
  A041T: 'Soupe de viande',
  A0BZA: 'Soupe à base de viande, avec morceaux',
  A0BZB: 'Consommé de viande',
  A041X: 'Soupe de poissons',
  A041Z: 'Soupe à base de produits laitiers/œufs',
  A041Q: 'Soupe aux légumineuses (haricots)',
  A041P: 'Soupe de pommes de terre',
  A041Y: 'Soupe aux céréales',
  A00EP: 'Soupe épaisse à base de semoule de maïs',
  A041M: "Soupe à l'oignon",
  A041N: 'Soupe à la tomate',
  A0CVB: 'Gazpacho et similaires',
  A041R: 'Soupe aux champignons',
  A042A: 'Soupe de fruits',
  A0CDN: 'Soupes mélangées',
  A041S: 'Soupe de légumes mélangés',
  A0BZ9: 'Soupe de légumes mélangés, avec purée ou morceaux',
  A0BZ8: 'Consommé de légumes mélangés',
  A041V: 'Soupe à base de viande et de légumes',
  A0BZC: 'Soupe à base de viande et de légumes, avec purée ou morceaux',
  A0BZD: 'Consommé de viande et de légumes',
  A042B: 'Salades',
  A042C: 'Salade verte mélangée',
  A042D: 'Salade de crudités mélangées',
  A042E: 'Salade César',
  A042F: 'Salade grecque',
  A042G: 'Salade préparée à base de légumineuses (haricots)',
  A042H: 'Salade préparée à base de pâte',
  A042J: 'Salade préparée à base de riz',
  A042K: 'Salade préparée à base de noix',
  A042L: 'Salade préparée à base de viande',
  A042M:
    "Salade préparée à base d'œufs/de viande/de poisson/de légumes mélangés",
  A03YV: 'Salade aux champignons',
  A01QG: 'Salade de fruits',
  A04PD: "Desserts sucrés à base d'eau",
  A036F: "Desserts à base d'eau à manger à la cuillère",
  A036G: 'Halva',
  A036H: 'Dessert à base de gélatine',
  A036J: "Crèmes glacées à base d'eau",
  A036K: 'Sorbet',
  A036L: 'Granita',
  A02PT: 'Desserts lactés et similaires',
  A02PV: 'Desserts lactés, à manger à la cuillère',
  A02PX: 'Crème dessert',
  A02PY: 'Pudding amylacé',
  A02PZ: 'Crèmes glacées et similaires',
  A02QA: 'Crèmes glacées à base de lait',
  A02QB: 'Crèmes glacées à base de succédanés de lait',
  A02QC: 'Yaourt glacé',
  A02QD: 'Milkshakes',
  A04NS: 'Autres desserts à manger à la cuillère',
  A0EQY: 'Chips, pommes frites et analogues produits à partir d’une pâte',
  A0EQX: 'Chips',
  A011L: 'Chips de pommes de terre',
  A0BYS: 'Chips fabriquées à partir de pommes de terre tranchées',
  A0BYT: 'Chips fabriquées à partir de pâte de pommes de terre',
  A00FC: 'Chips de maïs',
  A00FD: 'Chips tortilla',
  A00FF: 'Chips de riz',
  A0EQV: 'Amuse-gueules extrudés type produits soufflés',
  A00FE: 'Produits soufflés à base de maïs',
  A06HL: 'Amuse-gueules autres que les chips et similaires',
  A00FH: 'Amuse-gueules à base de céréales mélangées',
  A011T: "Amuse-gueules à base d'amidon de pomme de terre",
  A011V: "Amuse-gueules à base d'amidon de tapioca",
  A042Q: 'Sel à faible teneur en sodium',
  A042R: 'Sel iodé',
  A042S: 'Sel fluoré',
  A042T: 'Sel iodé et fluoré',
  A042V: 'Sel aromatisé',
  A042X: 'Sel de mer',
  A042Z: "Mélange d'épices et de fines herbes",
  A043A: 'Poudre de curry',
  A043B: 'Bouquet garni',
  A043C: 'Garam masala',
  A043D: 'Épices à marinade',
  A043Q: 'Ingrédients pour sauce au jus de viande',
  A043R: 'Épaississant pour sauce au jus de viande',
  A043S: 'Agent brunissant pour sauce au jus de viande',
  A043T: 'Granulés instantanés pour sauce au jus de viande',
  A043F: 'Bouillon en cube ou en granulés (base de bouillon)',
  A0BZ7: 'Bouillon de viande en cube ou en granulés',
  A043G: 'Bouillon de poulet en cube ou en granulés',
  A043H: 'Bouillon de bœuf en cube ou en granulés',
  A043J: 'Bouillon de légumes en cube ou en granulés',
  A18BC: 'Bouillon en cubes ou en granulés de poisson',
  A0EZC: 'Extraits de produits primaires bruts exhausteurs de goût',
  A0BN3: 'Protéines animales hydrolysées',
  A0C0T: 'Protéines végétales hydrolysées',
  A043K: 'Extraits salés divers',
  A043L: 'Extraits végétaux salés',
  A043M: 'Extrait de viande',
  A043N: 'Extrait de malt',
  A043P: 'Extrait de levure',
  A16GK: 'Préparation déshydratée pour sauce salée',
  A044L: 'Vinaigre',
  A044M: 'Vinaigre de vin',
  A04JG: 'Vinaigre balsamique et similaires',
  A044N: 'Vinaigre de pomme',
  A045K: 'Sauce pour salades',
  A045L: 'Sauce pour salades à faible teneur en matières grasses',
  A043V: 'Sauces salées',
  A0F1M: 'Mayonnaise, sauce hollandaise et sauces apparentées',
  A044X: 'Mayonnaise',
  A044Y: 'Mayonnaise à faible teneur en matières grasses',
  A045F: 'Sauce hollandaise',
  A045N: 'Sauce tartare',
  A044C: 'Sauces cuisinées à base de tomates',
  A0F1N: 'Sauces blanches',
  A043X: 'Sauce béchamel',
  A043Y: 'Sauce au fromage',
  A044A: 'Sauce à la crème',
  A044B: 'Sauce au beurre',
  A043Z: 'Sauce brune scandinave, sauce au jus de viande',
  A044G: 'Moutarde et sauces apparentées',
  A044H: 'Moutarde douce',
  A044J: 'Moutarde mi-forte',
  A044K: 'Moutarde forte',
  A045B: 'Sauces à barbecue ou à steak',
  A0EZD:
    'Sauces fabriquées à partir de sources fermentées/hydrolisées et similaires',
  A044R: 'Sauce de soja',
  A044S: "Sauce d'huître",
  A044T: 'Sauce teriyaki',
  A0B9H: 'Sauce à base de poisson',
  A045C: 'Sauce brune britannique',
  A16EY: 'Sauce aigre douce',
  A16QF: 'Sauce aux haricots verts',
  A185V: 'sauce crevette',
  A044P: 'Ketchup et sauces apparentées',
  A0ERG: 'Sauces aux herbes/épices',
  A044Q: 'Tabasco',
  A045A: "Aïoli ou sauce à l'ail",
  A045D: 'Sauce au curry',
  A044V: 'Pesto',
  A045G: 'Sauce au raifort',
  A045H: 'Sauce à la menthe',
  A045E: "Sauces aux fines herbes, aux légumes et à l'huile",
  A045M: 'Sauce salsa',
  A0FDF: 'Sauce tzatziki',
  A16BR: 'Sauce à base de viande',
  A044E: 'Sauce cuisinée à base de légumes',
  A044D: 'Sauce cuisinée à base de champignons',
  A16BS: "Sauce à base d'alcool",
  A046E: 'Achards',
  A045Q: 'Chutneys',
  A045R: 'Chutney à la tomate',
  A045S: 'Chutney à la pomme',
  A045T: 'Chutney au concombre',
  A045V: 'Chutney à la mangue',
  A045X: 'Chutneys mélangés',
  A045Y: 'Pickles',
  A045Z: 'Piccalilli',
  A046A: 'Piments au vinaigre',
  A046B: 'Limettes au vinaigre',
  A046C: 'Mangue au vinaigre',
  A046D: 'Pickles mélangés',
  A046G: 'Sauce aux fruits',
  A046H: 'Sauce au chocolat',
  A046J: 'Sauce au fudge',
  A046K: 'Sauce sucrée alcoolisée',
  A0F3D: 'Matières grasses/huiles animales et végétales',
  A036N: 'Matières grasses et huiles végétales comestibles',
  A036P: "Huiles d'olive",
  A036Q: "Huile d'olive vierge ou extra-vierge",
  A036R: "Huile d'olive raffinée",
  A036S: "Huile de grignons d'olives",
  A036T: 'Huiles de graines',
  A036V: 'Huile de colza comestible',
  A036X: 'Huile de soja raffinée',
  A036Y: 'Huile de maïs comestible',
  A036Z: 'Huile de coton comestible',
  A037A: "Huile d'arachide comestible",
  A037B: 'Huile de carthame comestible',
  A037C: 'Huile de sésame comestible',
  A037D: 'Huile de tournesol comestible',
  A037E: "Huile d'amande",
  A037F: 'Huile de pépins de raisin',
  A037G: 'Huile de lin',
  A037H: 'Huile de pépins de courge',
  A037K: 'Huile de noix',
  A18FZ: 'Huile de graines de chanvre',
  A037L: 'Autres huiles végétales',
  A037M: 'Huile/matière grasse de noix de coco',
  A037N: 'Huile/matière grasse de palme',
  A037P: 'Huile de palmiste comestible',
  A037Q: 'Huile de germe de blé',
  A037R: 'Beurre de cacao',
  A0F5Z: 'Beurre de karité',
  A037T:
    'Matières grasses et huiles animales (graisses transformées de tissus animaux)',
  A04PJ: "Matières grasses et huiles provenant d'animaux terrestres",
  A037V: 'Lard de porc',
  A037X: 'Suif de bœuf (y compris graisse de bœuf)',
  A037Y: "Suif d'autres animaux",
  A037Z: 'Suif de buffle',
  A038A: 'Suif de chameau',
  A038B: 'Suif de mouton',
  A038C: 'Suif de chèvre',
  A038D: 'Suif de cheval',
  A038E: 'Matières grasses de volaille transformées',
  A038F: 'Graisses transformées de poulet',
  A038G: 'Graisses transformées de canard',
  A038H: "Graisses transformées d'oie",
  A038J: 'Graisses transformées de dinde',
  A038K: "Matières grasses et huiles provenant d'animaux marins",
  A038L: 'Graisses transformées de mammifères marins',
  A038M: 'Huile de poisson',
  A038Q: 'Huile de foie de morue',
  A038N: 'Huile de hareng',
  A038P: 'Huile de sardine',
  A04PL: 'Autres huiles de poisson',
  A038R: 'Matières grasses laitières',
  A038V: 'Matière grasse du lait de vache (non émulsifiée)',
  A038X: 'Beurre fondu',
  A038Y: 'Beurre clarifié',
  A04PK: "Matière grasse du lait d'autres animaux",
  A038S: 'Matière grasse du lait de bufflonne',
  A038T: 'Matière grasse du lait de chamelle',
  A038Z: 'Matière grasse du lait de chèvre',
  A039A: 'Matière grasse du lait de brebis',
  A039B: 'Émulsions de matières grasses et mélange de matières grasses',
  A039C: 'Beurre',
  A0F1G: 'Margarines et similaires',
  A039D: 'Margarine traditionnelle',
  A039E: 'Margarine mélangée',
  A039H: 'Margarines/matières grasses végétales fonctionnelles',
  A04SD: "Mélange d'huiles et de matières grasses",
  A039J: 'Mélange d’huiles et de matières grasses pour friture',
  A039F: 'Mélanges de beurre et de margarine/huile',
  A039G:
    'Graisse de boulangerie et matières grasses similaires destinées à la cuisson',
  A032G: 'Sucres (mono- et disaccharides)',
  A032H: 'Saccharose (sucre commun)',
  A032J: 'Sucre blanc',
  A032K: 'Sucre de betterave raffiné',
  A032L: 'Sucre de canne raffiné',
  A032M: 'Sucre roux',
  A032N: 'Sucre roux de betterave',
  A032P: 'Sucre roux de canne',
  A16QB: 'Sucre de palme roux',
  A032Q: 'Sucre aromatisé',
  A032R: 'Sucre glace',
  A032S: 'Mono- et disaccharides autres que le saccharose',
  A02PQ: 'Lactose',
  A032X: 'Galactose',
  A032Y: 'Maltose',
  A032V: 'Glucose',
  A032T: 'Fructose',
  A033J: 'Miel',
  A033K: 'Miel monofloral',
  A033L: 'Miel polyfloral',
  A033M: 'Miel mélangé',
  A033N: 'Miel de miellat',
  A033P: 'Miel en rayons',
  A033Q: 'Types de miel moins répandus',
  A033R: 'Sirops (mélasses et autres sirops)',
  A033S: 'Mélasses',
  A033T: "Mélasse d'agrumes",
  A033X: 'Mélasse de sorgho',
  A033Y: 'Mélasse de canne à sucre',
  A033V: 'Mélasse de betterave sucrière',
  A033Z: 'Sirops',
  A034B: "Sirop d'orge malté",
  A034C: 'Sirop de maïs',
  A034D: 'Sirop de sucre de betterave',
  A034E: 'Mélasse raffinée',
  A032Z: 'Polyols',
  A033D: 'Maltitol',
  A033E: 'Lactitol',
  A033F: 'Érythritol',
  A033H: 'Autres polyols',
  A033G: 'Isomalt',
  A033B: 'Mannitol',
  A033C: 'Xylitol',
  A033A: 'Sorbitol',
  A0DPT: 'Maltodextrines et similaires',
  A0BSL: 'Malodextrine',
  A0BSK: 'Dextrine',
  A0BSM: 'Polydextrose',
  A0EZE: 'Inuline et autres polyfructoses',
  A0F7T: 'Édulcorants de table sous forme liquide',
  A0F7V: 'Édulcorants de table sous forme de poudre',
  A0F7X: 'Édulcorants de table sous forme de comprimés',
  A002R: 'Amidon de maïs',
  A003A: "Amidon d'avoine",
  A003G: 'Amidon de riz',
  A003R: 'Amidon de seigle',
  A004M: 'Amidon de blé',
  A004N: 'Amidon de blé, sans gluten',
  A011F: 'Amidon de pomme de terre',
  A011J: 'Amidon de tapioca',
  A02PR: 'Protéine de lait',
  A02PS: 'Protéine de lactosérum',
  A01BG: 'Protéines de soja',
  A0F5E: 'Gélatine',
  A16EN: 'Collagène',
  A16EP: 'Acide Hyaluronique',
  A16GN: 'Gluten',
  A047A: 'Arômes alimentaires',
  A047B: 'Aromatisants',
  A06JV: 'Arôme de menthe-chocolat',
  A06JX: "Arôme d'amande",
  A06JY: "Arôme d'amarena",
  A06JZ: "Arôme d'amaretto",
  A06KA: "Arôme d'anis",
  A06KB: 'Arôme de pomme',
  A06KC: 'Arôme de pomme verte',
  A06KD: 'Arôme de pomme rouge',
  A06KE: "Arôme d'abricot",
  A06KF: "Arôme d'abricot-pêche",
  A06KG: "Arôme d'artichaut",
  A06KH: "Arôme d'avocat",
  A06KJ: 'Arôme de bacon',
  A06KK: 'Arôme de vinaigre balsamique',
  A06KL: 'Arôme de banane',
  A06KM: 'Arôme «barbecue»',
  A06KN: "Arôme d'orge",
  A06KP: 'Arôme de basilic',
  A06KQ: 'Arôme de bœuf',
  A06KR: 'Arôme de soupe de bœuf',
  A06KS: 'Arôme de fruits rouges',
  A06KT: 'Arôme amer',
  A06KV: 'Arôme de thé noir',
  A06KX: 'Arôme de mûre',
  A06KY: 'Arôme de groseille noire',
  A06KZ: "Arôme d'orange sanguine",
  A06LA: 'Arôme de fromage bleu',
  A06LB: 'Arôme de myrtille',
  A06LC: 'Arôme de bourbon',
  A06LD: 'Arôme de mûre de Boysen',
  A06LE: "Arôme d'eau de vie",
  A06LF: "Arôme d'eau de vie à l'orange",
  A06LG: 'Arôme de nougatine',
  A06LH: 'Arôme de cassonade',
  A06LJ: 'Arôme de chewing-gum',
  A06LK: 'Arôme de beurre',
  A06LL: 'Arôme de babeurre',
  A06LM: 'Arôme de caramel au beurre',
  A06LN: 'Arôme de café-au-lait',
  A06LP: 'Arôme de cappuccino',
  A06LQ: 'Arôme de piment',
  A06LR: 'Arôme de caramel',
  A06LS: 'Arôme de cardamome',
  A047K: 'Arôme de caroube',
  A06LT: 'Arôme de carotte',
  A06LV: 'Arôme de cassis',
  A06LX: 'Arôme de céleri',
  A06LY: 'Arôme de céréales',
  A06LZ: 'Arôme de fromage',
  A06MA: 'Arôme «cheesecake»',
  A06MB: 'Arôme de cerise',
  A06MC: 'Arôme de châtaigne',
  A0CRZ: 'Arôme de poulet',
  A06MD: 'Arôme de chili',
  A06ME: 'Arôme de ciboulette',
  A06MF: 'Arôme de chocolat',
  A06MG: 'Arôme de chocolat noir',
  A06MH: 'Arôme de chocolat au lait',
  A06MJ: 'Arôme de cannelle',
  A06MK: 'Arôme de citron',
  A06ML: "Arôme d'agrume",
  A06MM: 'Arôme de noix de coco',
  A06MN: 'Arôme de café',
  A06MP: 'Arôme de cola',
  A06MQ: 'Arôme «cookies et crème»',
  A06MR: 'Arôme de coriandre',
  A06MS: 'Arôme de maïs',
  A06MT: 'Arôme de crabe',
  A06MV: 'Arôme de canneberge',
  A06MX: 'Arôme de crème',
  A06MY: 'Arôme de crème brûlée',
  A06MZ: 'Arôme de concombre',
  A06NA: 'Arôme de cumin',
  A06NB: 'Arôme de groseille',
  A06NC: 'Arôme de curry',
  A06ND: "Arôme de jaune d'œuf",
  A06NE: 'Arôme de lait de poule',
  A06NF: "Arôme d'estragon",
  A06NG: "Arôme d'eucalyptus",
  A06NH: 'Arôme de fruits exotiques',
  A06NJ: 'Arôme «très piquant»',
  A06NK: 'Arôme de feijoa',
  A06NL: 'Arôme de fenouil',
  A06NM: 'Arôme de figue séchée',
  A06NN: 'Arôme de poisson',
  A06NP: 'Arôme de lait frais',
  A06NQ: 'Arôme de fruit',
  A06NR: 'Arôme de cocktail de fruits',
  A06NS: 'Arôme de fruits de la forêt',
  A06NT: "Arôme d'ail",
  A06NV: 'Arôme de gianduja',
  A06NX: 'Arôme de gin',
  A06NY: 'Arôme de gingembre',
  A06NZ: 'Arôme de ginseng',
  A06PA: 'Arôme de raisin',
  A06PB: 'Arôme de raisin Concord',
  A06PC: 'Arôme de pamplemousse',
  A06PD: 'Arôme de thé vert',
  A06PE: 'Arôme de grenadine',
  A06PF: 'Arôme de corossol',
  A06PG: 'Arôme de guarana',
  A06PH: 'Arôme de goyave',
  A06PJ: 'Arôme de jambon',
  A06PK: 'Arôme de noisette',
  A06PL: "Arôme d'ail et fines herbes",
  A06PM: 'Arôme «mélange de fines herbes»',
  A06PN: 'Arôme de fines herbes',
  A06PP: "Arôme d'hibiscus",
  A06PQ: 'Arôme de miel',
  A06PR: 'Arôme de miel à la cannelle',
  A06PS: 'Arôme de raifort',
  A06PT: 'Arôme «piquant»',
  A06PV: 'Arôme «fort et épicé»',
  A06PX: 'Arôme de pomme de jacque',
  A06PY: 'Arôme de jasmin',
  A06PZ: 'Arôme de genièvre',
  A06QA: 'Arôme de ketchup',
  A06QB: 'Arôme de kirsch',
  A06QC: 'Arôme de kiwi',
  A06QD: 'Arôme de poireau',
  A06QE: 'Arôme de citron',
  A06QF: 'Arôme de myrte citronnée',
  A06QG: 'Arôme de réglisse',
  A06QH: 'Arôme de limette',
  A06QJ: 'Arôme de noix de macadamia',
  A06QK: 'Arôme de raisins de Malaga',
  A06QL: 'Arôme de malt',
  A06QM: 'Arôme de mandarine',
  A06QN: 'Arôme de mangue',
  A06QP: "Arôme d'érable",
  A06QQ: 'Arôme de marasquin',
  A06QR: 'Arôme de marjolaine',
  A06QS: 'Arôme de marsala',
  A06QT: 'Arôme de massepain',
  A06QV: 'Arôme de masala fort',
  A06QX: 'Arôme de mascarpone',
  A06QY: 'Arôme de viande',
  A06QZ: 'Arôme «moyennement épicé»',
  A06RA: 'Arôme de melon',
  A06RB: 'Arôme doux',
  A06RC: 'Arôme de lait',
  A06RD: 'Arôme de menthe',
  A06RE: 'Arôme de baies mélangées',
  A06RF: 'Arôme de moka',
  A06RG: 'Arôme de muesli',
  A06RH: 'Arôme de raisin muscat',
  A06RJ: 'Arôme de champignon',
  A06RK: 'Arôme de moutarde',
  A06RL: 'Arôme de nougat',
  A06RM: 'Arôme de nutella',
  A06RN: 'Arôme de noix de muscade',
  A06RP: 'Arôme de fruits à coque',
  A06RQ: "Arôme d'oignon",
  A06RR: "Arôme d'orange",
  A06RS: "Arôme d'origan",
  A06RT: "Arôme d'huître",
  A06RV: 'Arôme de papaye',
  A06RX: 'Arôme de paprika',
  A06RY: 'Arôme de persil',
  A06RZ: 'Arôme de fruit de la passion',
  A06SA: 'Arôme de pâtisserie',
  A06SB: 'Arôme de pêche',
  A06SC: "Arôme d'arachide",
  A06SD: 'Arôme de beurre de cacahuète',
  A06SE: 'Arôme de poire',
  A06SF: 'Arôme de poivron',
  A06SG: 'Arôme de menthe poivrée',
  A06SH: 'Arôme de piment',
  A06SJ: "Arôme d'ananas",
  A06SK: 'Arôme de pistache',
  A06SL: 'Arôme de prune',
  A06SM: 'Arôme de grenade',
  A06SN: 'Arôme de porc',
  A06SP: 'Arôme de pruneau',
  A06SQ: 'Arôme de courge',
  A06SR: 'Arôme de quark',
  A06SS: 'Arôme de rafaello',
  A06ST: 'Arôme de raisin sec',
  A06SV: 'Arôme de framboise',
  A06SX: 'Arôme de fruits rouges',
  A06SY: "Arôme d'orange sanguine",
  A06SZ: 'Arôme de rhubarbe',
  A06TA: 'Arôme de torréfié',
  A06TB: 'Arôme de rose',
  A06TC: 'Arôme de romarin',
  A06TD: 'Arôme de rhum',
  A06TE: 'Arôme de rhum-raisin',
  A06TF: 'Arôme de sauge',
  A06TG: "Arôme d'argouse",
  A06TH: 'Arôme de sherry',
  A06TJ: 'Arôme de crevette',
  A06TK: 'Arôme de fumée',
  A06TL: 'Arôme de soupe',
  A06TM: 'Arôme aigre',
  A06TN: 'Arôme de crème aigre',
  A06TP: 'Arôme de crème aigre à la cerise',
  A06TQ: 'Arôme de menthe verte',
  A06TR: "Arôme «mélange d'épices»",
  A06TS: "Arôme d'épinard",
  A06TT: 'Arôme de stracciatella',
  A06TV: 'Arôme de fraise',
  A06TX: 'Arôme de yaourt à la fraise',
  A06TY: 'Arôme de raisin de Corinthe',
  A06TZ: 'Arôme de tomate séchée',
  A06VA: 'Arôme sucré',
  A06VB: 'Arôme aigre-doux',
  A06VC: 'Arôme de piment doux',
  A06VD: 'Arôme de tamarin',
  A06VE: 'Arôme de tandoori',
  A06VF: 'Arôme de tangerine',
  A06VG: 'Arôme de thé noir',
  A06VH: 'Arôme de thé vert',
  A06VJ: 'Arôme de tequila',
  A06VK: 'Arôme de thym',
  A06VL: 'Arôme de tiramisu',
  A06VM: 'Arôme de toffee',
  A06VN: 'Arôme de tomate',
  A06VP: "Arôme d'eau tonique",
  A06VQ: 'Arôme de fruits tropicaux',
  A06VR: 'Arôme de truffe',
  A06VS: 'Arôme de vanille',
  A06VT: 'Arôme de vanilline',
  A06VV: 'Arôme de noix',
  A0CRY: 'Arôme de wasabi',
  A06VX: 'Arôme de pastèque',
  A06VY: 'Arôme de whisky',
  A06VZ: 'Arôme de baies sauvages',
  A06XA: 'Arôme de fraise des bois',
  A06XB: 'Arôme de vin',
  A06XC: "Arôme d'aspérule",
  A06XD: 'Arôme de yaourt',
  A06XE: 'Arôme de sabayon',
  A0CHZ: 'Arôme de mastic',
  A047C: 'Essences et extraits naturels',
  A047D: "Essence d'amande",
  A047E: 'Essence de vanille',
  A047F: 'Extrait de gousses de vanille',
  A047G: "Essence d'orange",
  A047H: 'Essence de citron',
  A047J: 'Essence de rhum',
  A047L: 'Extrait de réglisse',
  A17LY: "Extrait d'anis",
  A047N: 'Colorants alimentaires',
  A047P: 'Colorant alimentaire naturel',
  A047Q: 'Colorant alimentaire artificiel',
  A046M: 'Édulcorants artificiels (aspartame, saccharine)',
  A046N: 'Saccharine',
  A046P: 'Aspartame',
  A046Q: 'Acésulfame k',
  A046R: 'Sucralose',
  A046S: 'Cyclamate',
  A046T: 'Néohespéridine',
  A046V: 'Thaumatine',
  A046X: 'Néotame',
  A046Y: 'Glycoside de stéviol',
  A046Z: 'Advantame',
  A047R:
    'Additifs alimentaires autres que les arômes, les colorants et les édulcorants',
  A047S: 'Agent conservateur',
  A047T: 'Antioxydant',
  A047V: 'Émulsifiant',
  A047X: 'Épaississant',
  A047Y: 'Agent gélifiant',
  A047Z: 'Stabilisant',
  A048A: 'Exhausteur de goût',
  A048B: 'Acide',
  A048C: "Régulateur d'acidité",
  A048D: 'Agent anti-agglomérant',
  A048E: 'Amidon modifié',
  A048F: 'Agent antimoussant',
  A048G: "Agent d'enrobage",
  A048H: 'Sels émulsifiants',
  A048J: 'Agent de traitement de la farine',
  A048K: 'Agent affermissant',
  A048L: 'Humectant',
  A048M: 'Agent gonflant',
  A048N: 'Gaz propulseur',
  A169T: 'Agents levants',
  A18FD: 'Agent liant',
  A04QQ: 'Adjuvants pour préparation à domicile',
  A048Q:
    'Préparations pour lever et aromatiser les produits de boulangerie faits-maison',
  A048R: 'Préparations pour gélifier les plats faits-maison',
  A048S: 'Préparations pour saumurer les légumes',
  A0F3T: 'Préparations pour aromatiser les aliments',
  A16GC: 'Préparations pour dessert',
  A048T: 'Auxiliaires technologiques',
  A0EVG: 'Vitamines',
  A0EXZ: 'Vitamine A     (rétinol, caroténoïdes)',
  A0EXY: 'Vitamine B1   (thiamine)',
  A0EXX: 'Vitamine B2   (riboflavine)',
  A0EXV: 'Vitamine B3   (niacine, niacinamide)',
  A0EXT: 'Vitamine B5   (acide pantothénique)',
  A0EXS: 'Vitamine B6   (pyridoxine, pyridoxamine, pyridoxal)',
  A0EXR: 'Vitamine B7   (biotine)',
  A0EXQ: 'Vitamine B9   (acide folique, acide folinique)',
  A0EXP:
    'Vitamine B12   (cyanocobalamine, hydroxocobalamine, méthylcobalamine)',
  A0EXN: 'Vitamine C     (acide ascorbique)',
  A0EXM: 'Vitamine D     (cholécalciférol)',
  A0EXL: 'Vitamine E     (tocophérols, tocotriénols)',
  A0EXK: 'Vitamine K     (phylloquinone, ménaquinones)',
  A0EVF: 'Éléments chimiques',
  A0EXJ: 'Potassium',
  A0EXH: 'Calcium',
  A0EXG: 'Phosphore',
  A0EXF: 'Magnésium',
  A0EXE: 'Zinc',
  A0EXD: 'Fer',
  A0EXC: 'Manganèse',
  A0EXB: 'Cuivre ',
  A0EXA: 'Iode',
  A0F3A: 'Fluor',
  A0EVZ: 'Sélénium',
  A0EVY: 'Molybdène',
  A0EVX: 'Brome',
  A16YP: 'Chromium',
  A0EVS: 'Acides gras spéciaux',
  A0EVV: 'Acides gras oméga-3',
  A0EVT: 'Acides gras oméga-6',
  A0EVR: 'Fibres alimentaires',
  A0EVM: 'Éléments phytochimiques',
  A0EVQ: 'Phytostérols',
  A0EVP: 'Polyphénols',
  A0EVN: 'Caroténoïdes',
  A0EVL: "Agents fortifiants à base d'algues (spiruline, chlorelle)",
  A0EVK: 'Caféine',
  A16GT: 'Taurine',
  A16YQ: 'Glucosamine',
  A0EVH: "Agents fortifiants issus de l'apiculture",
  A0CVH: 'Pollen (résidus de pollens végétaux)',
  A0CVG: 'Gelée royale',
  A0CVF: "Autres produits de l'apiculture comestibles",
  A0F4M: 'Cofacteurs du métabolisme',
  A0F4N: 'Carnitine',
  A0EVJ: 'Enzymes destinées à la fortification',
  A0F4P: 'Créatine-créatinine',
  A048X: 'Micro-organismes vivants pour la production alimentaire',
  A048Y: 'Cultures de ferments lactiques',
  A048Z: 'Cultures de levure',
  A049A: 'Levure de boulangerie',
  A05QQ: 'Levain chef',
  A049B: 'Cultures de moisissures ou de micro-champignons',
  A16FP: 'Cultures probiotiques',
  A048V: "Enzymes pour l'industrie alimentaire",
  A16PQ: "Nid d'oiseau (partie comestible)",
  ...MatrixKindLabels,
};
