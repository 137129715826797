import { z } from 'zod';
import { MatrixKind } from '../MatrixKind';

export const Matrix = z.enum(
  [
    'A000L',
    'A001X',
    'A0D9Y',
    'A000P',
    'A002K',
    'A04KH',
    'A000N',
    'A000M',
    'A0D9X',
    'A0D9V',
    'A0D9T',
    'A000Q',
    'A000R',
    'A000S',
    'A000V',
    'A000X',
    'A000T',
    'A0D9S',
    'A000Y',
    'A001B',
    'A0CGK',
    'A0BJZ',
    'A000B',
    'A000D',
    'A002A',
    'A000E',
    'A001A',
    'A000A',
    'A001Z',
    'A000Z',
    'A000F',
    'A000G',
    'A000H',
    'A001C',
    'A001D',
    'A001E',
    'A001F',
    'A001G',
    'A001H',
    'A003D',
    'A003E',
    'A0F6M',
    'A0CGL',
    'A0CGM',
    'A001J',
    'A0D9R',
    'A001K',
    'A0D9Q',
    'A001L',
    'A0D9P',
    'A0D9N',
    'A0D9M',
    'A001M',
    'A001N',
    'A001P',
    'A001Q',
    'A001T',
    'A001S',
    'A001R',
    'A001V',
    'A170H',
    'A0D9K',
    'A001Y',
    'A0ETP',
    'A0BY1',
    'A065N',
    'A002H',
    'A002V',
    'A002Z',
    'A003P',
    'A004E',
    'A004G',
    'A0ETL',
    'A002N',
    'A004F',
    'A0F6Q',
    'A04KS',
    'A004S',
    'A002E',
    'A002L',
    'A002G',
    'A0C0Z',
    'A002Q',
    'A002P',
    'A002T',
    'A002Y',
    'A003F',
    'A003J',
    'A003K',
    'A003L',
    'A003M',
    'A003N',
    'A003T',
    'A004H',
    'A004J',
    'A004K',
    'A004L',
    'A003X',
    'A003Y',
    'A003Z',
    'A004A',
    'A004B',
    'A004C',
    'A004D',
    'A04KT',
    'A002C',
    'A003B',
    'A003Q',
    'A004P',
    'A0F6P',
    'A0ETN',
    'A016K',
    'A004Q',
    'A04QY',
    'A00EM',
    'A00DA',
    'A00DD',
    'A00DN',
    'A00DK',
    'A00DS',
    'A00DY',
    'A00EF',
    'A00EC',
    'A00ED',
    'A0F4Q',
    'A04LL',
    'A00CZ',
    'A00DC',
    'A00DM',
    'A00DR',
    'A00DX',
    'A00EE',
    'A04LJ',
    'A00EH',
    'A00CY',
    'A00DF',
    'A00DH',
    'A00DJ',
    'A00DL',
    'A00DQ',
    'A00DV',
    'A00EA',
    'A00EB',
    'A00EY',
    'A00EZ',
    'A00FA',
    'A00EJ',
    'A00EK',
    'A00EL',
    'A00EN',
    'A00EQ',
    'A00ER',
    'A00ES',
    'A00ET',
    'A00EV',
    'A00EX',
    'A16GM',
    'A0F0V',
    'A0F0T',
    'A0ESM',
    'A007D',
    'A007E',
    'A04LC',
    'A007F',
    'A007G',
    'A007J',
    'A007L',
    'A007M',
    'A007P',
    'A0ERE',
    'A007T',
    'A007V',
    'A007X',
    'A007Y',
    'A007Z',
    'A008A',
    'A04LF',
    'A008B',
    'A008D',
    'A008E',
    'A008F',
    'A007R',
    'A008C',
    'A008G',
    'A008H',
    'A008J',
    'A008K',
    'A008L',
    'A008M',
    'A008N',
    'A008P',
    'A008Q',
    'A008R',
    'A008V',
    'A008T',
    'A008X',
    'A008Y',
    'A008Z',
    'A009A',
    'A009B',
    'A009C',
    'A009D',
    'A009E',
    'A009F',
    'A009G',
    'A009H',
    'A009J',
    'A009K',
    'A008S',
    'A009L',
    'A009M',
    'A009N',
    'A16FH',
    'A009P',
    'A009Q',
    'A009R',
    'A009S',
    'A0CSK',
    'A0CSL',
    'A0CSM',
    'A0CSN',
    'A0BX1',
    'A004V',
    'A0DRD',
    'A0BY0',
    'A005L',
    'A005M',
    'A005N',
    'A005P',
    'A005Q',
    'A004X',
    'A004Y',
    'A004Z',
    'A005A',
    'A005B',
    'A005C',
    'A005D',
    'A005E',
    'A005F',
    'A005G',
    'A005H',
    'A005J',
    'A005K',
    'A0BB2',
    'A005V',
    'A04KZ',
    'A006Q',
    'A006R',
    'A006S',
    'A006T',
    'A006V',
    'A006X',
    'A006Y',
    'A16FX',
    'A005Y',
    'A00FG',
    'A0CHT',
    'A006A',
    'A006B',
    'A006C',
    'A006D',
    'A006E',
    'A006F',
    'A005Z',
    'A006G',
    'A006H',
    'A006J',
    'A006K',
    'A006L',
    'A006M',
    'A006N',
    'A006P',
    'A0ERB',
    'A005R',
    'A005S',
    'A005T',
    'A006Z',
    'A007A',
    'A007B',
    'A007C',
    'A009T',
    'A009V',
    'A009X',
    'A009Y',
    'A009Z',
    'A00AA',
    'A00AB',
    'A00AC',
    'A00AD',
    'A16FJ',
    'A00AE',
    'A0BYR',
    'A00AG',
    'A00AH',
    'A00AJ',
    'A00AK',
    'A00AL',
    'A00AM',
    'A00AN',
    'A00AP',
    'A00AQ',
    'A00BB',
    'A00AR',
    'A00AS',
    'A00AT',
    'A00AV',
    'A00AX',
    'A00AY',
    'A00AZ',
    'A00BA',
    'A00BC',
    'A00BD',
    'A0C6L',
    'A00BE',
    'A0C6M',
    'A00BF',
    'A00BG',
    'A00BH',
    'A00BJ',
    'A00BK',
    'A00BL',
    'A00BM',
    'A00BN',
    'A00BP',
    'A00BQ',
    'A00BR',
    'A00BS',
    'A00BT',
    'A16FV',
    'A16FY',
    'A00BV',
    'A00BX',
    'A00BY',
    'A00BZ',
    'A00CB',
    'A00CC',
    'A00CD',
    'A00CE',
    'A00CF',
    'A00CG',
    'A00CH',
    'A00CJ',
    'A00CK',
    'A00CL',
    'A00CM',
    'A00CN',
    'A00CP',
    'A00CQ',
    'A00CR',
    'A00CS',
    'A00CT',
    'A16EQ',
    'A0DLC',
    'A00KT',
    'A00KV',
    'A0DLB',
    'A00KX',
    'A00KZ',
    'A00LA',
    'A00LB',
    'A00KY',
    'A00LC',
    'A00LD',
    'A00LE',
    'A00LF',
    'A00MD',
    'A0DLA',
    'A00LG',
    'A00LJ',
    'A00LH',
    'A0DKQ',
    'A00LL',
    'A00YT',
    'A00LM',
    'A00LN',
    'A00LP',
    'A0DKP',
    'A00LQ',
    'A00MA',
    'A0DKN',
    'A0DKL',
    'A00LZ',
    'A0DKM',
    'A0DKK',
    'A0DKJ',
    'A00LR',
    'A00LV',
    'A00LX',
    'A00MB',
    'A00NF',
    'A00NZ',
    'A00NG',
    'A00NH',
    'A00NK',
    'A00NL',
    'A00NM',
    'A00NN',
    'A00NP',
    'A00NQ',
    'A00NR',
    'A00NS',
    'A00NV',
    'A16QH',
    'A16QL',
    'A16QM',
    'A16QR',
    'A16QS',
    'A00NY',
    'A00ME',
    'A00MC',
    'A0F6J',
    'A00MG',
    'A00MH',
    'A00MJ',
    'A00ML',
    'A0DKH',
    'A0DKG',
    'A0DKF',
    'A0DKE',
    'A0DKD',
    'A0DKC',
    'A0DKB',
    'A0DKA',
    'A00NJ',
    'A0DJZ',
    'A00MK',
    'A00MV',
    'A00NX',
    'A00NT',
    'A00MN',
    'A00MP',
    'A00MR',
    'A00MS',
    'A0DJY',
    'A0DJX',
    'A0DJV',
    'A00MQ',
    'A170X',
    'A0DJT',
    'A00MX',
    'A0DJS',
    'A0DJR',
    'A00MY',
    'A00MZ',
    'A00MF',
    'A0DJQ',
    'A00NB',
    'A0DJP',
    'A00MM',
    'A00NC',
    'A00ND',
    'A00NA',
    'A0DJN',
    'A0DJM',
    'A0DJL',
    'A00NE',
    'A0DJK',
    'A00FT',
    'A0DLG',
    'A00FV',
    'A00FX',
    'A00FY',
    'A00FZ',
    'A00GA',
    'A00GB',
    'A00GC',
    'A00GD',
    'A00GE',
    'A00GF',
    'A00GG',
    'A0DLF',
    'A00GH',
    'A00LT',
    'A00LS',
    'A00GJ',
    'A00LY',
    'A00GL',
    'A00GM',
    'A00GN',
    'A00GR',
    'A0DLE',
    'A00GP',
    'A0DLD',
    'A00GQ',
    'A170V',
    'A00GS',
    'A00GT',
    'A0F6Y',
    'A0F6Z',
    'A0F7A',
    'A0F7B',
    'A0F7C',
    'A00LK',
    'A00SM',
    'A00SH',
    'A0DKX',
    'A0DKV',
    'A0DKT',
    'A0DKS',
    'A00SR',
    'A00ST',
    'A0DKR',
    'A00SY',
    'A00SZ',
    'A00TA',
    'A00TB',
    'A00SG',
    'A00SJ',
    'A00SK',
    'A00SL',
    'A00SN',
    'A00SP',
    'A00SQ',
    'A00SS',
    'A00SV',
    'A00SX',
    'A00FM',
    'A00FN',
    'A0DLM',
    'A00FQ',
    'A00GK',
    'A00FP',
    'A0DLL',
    'A00FR',
    'A0DLK',
    'A00FS',
    'A0DGR',
    'A0DGF',
    'A0DEV',
    'A00RT',
    'A0DET',
    'A0DES',
    'A00RX',
    'A0DER',
    'A0DEQ',
    'A00RY',
    'A0DEP',
    'A00SA',
    'A0DEN',
    'A00RS',
    'A0DEM',
    'A0DEL',
    'A0DEK',
    'A0DEJ',
    'A0DEH',
    'A00SB',
    'A00HM',
    'A0DEG',
    'A00SD',
    'A0DEF',
    'A00RV',
    'A0DED',
    'A0DEE',
    'A0DEC',
    'A00SC',
    'A0DEB',
    'A0DEA',
    'A0DDZ',
    'A0DDY',
    'A0DDX',
    'A00SE',
    'A00RZ',
    'A04RA',
    'A00GV',
    'A18YN',
    'A00GY',
    'A00GZ',
    'A0DNE',
    'A176J',
    'A00HB',
    'A00HC',
    'A0DND',
    'A00HA',
    'A00HE',
    'A00HD',
    'A0DNB',
    'A00HF',
    'A0DNA',
    'A0DMZ',
    'A00HG',
    'A00HH',
    'A0DMY',
    'A00HJ',
    'A00HL',
    'A00HK',
    'A00PC',
    'A0DFV',
    'A00PV',
    'A00PH',
    'A00PE',
    'A00PG',
    'A00PD',
    'A00PJ',
    'A0DFT',
    'A00PQ',
    'A00PM',
    'A00PS',
    'A00PL',
    'A0DFS',
    'A00PR',
    'A00PT',
    'A0DFR',
    'A0DFQ',
    'A16QA',
    'A0DFP',
    'A00PF',
    'A00PK',
    'A00PN',
    'A00PP',
    'A00PX',
    'A00PY',
    'A00QA',
    'A0DFG',
    'A0DFF',
    'A00PZ',
    'A0DFE',
    'A00QC',
    'A00QB',
    'A0DFB',
    'A00QD',
    'A00QE',
    'A00HP',
    'A00HZ',
    'A00JA',
    'A00JB',
    'A0DMM',
    'A0DML',
    'A0DMK',
    'A0DMJ',
    'A0DMH',
    'A00HQ',
    'A0DMX',
    'A00HR',
    'A00HS',
    'A00HT',
    'A00HV',
    'A00HX',
    'A00HY',
    'A00JG',
    'A0DMV',
    'A0DMT',
    'A0DMS',
    'A0DMN',
    'A00JH',
    'A0DMR',
    'A0DMQ',
    'A0DMP',
    'A00JC',
    'A00JD',
    'A0DMG',
    'A0DMF',
    'A00JE',
    'A0DME',
    'A0DMD',
    'A170T',
    'A0DMC',
    'A00JF',
    'A00JJ',
    'A00JL',
    'A0DMB',
    'A00JM',
    'A0DMA',
    'A0DLZ',
    'A00JN',
    'A00JP',
    'A00KA',
    'A00JQ',
    'A00JR',
    'A00KB',
    'A00KL',
    'A00JZ',
    'A0DLY',
    'A0DLX',
    'A00KM',
    'A00JY',
    'A00JS',
    'A00JT',
    'A00JV',
    'A00JX',
    'A16PT',
    'A00KC',
    'A16QK',
    'A00KD',
    'A00KE',
    'A00KF',
    'A00KG',
    'A0DLV',
    'A00KH',
    'A0DLT',
    'A00KN',
    'A0DLR',
    'A0DLQ',
    'A00KJ',
    'A00KK',
    'A0DLP',
    'A00KP',
    'A0DLN',
    'A00KQ',
    'A0DPC',
    'A00QG',
    'A0DPB',
    'A00QH',
    'A0DPA',
    'A0DNZ',
    'A0DNY',
    'A00QJ',
    'A04JZ',
    'A00QK',
    'A0DNX',
    'A00QP',
    'A0DNV',
    'A00QQ',
    'A00RH',
    'A00RN',
    'A170Q',
    'A170R',
    'A170S',
    'A0DNS',
    'A00QR',
    'A0DNR',
    'A00QS',
    'A00QM',
    'A0DNQ',
    'A00QN',
    'A0DNP',
    'A0DNN',
    'A0DNM',
    'A00RM',
    'A00QT',
    'A00QV',
    'A00QX',
    'A00QY',
    'A0DNK',
    'A0DNJ',
    'A00QZ',
    'A04KA',
    'A00RA',
    'A00RL',
    'A00RP',
    'A00RC',
    'A00RQ',
    'A00RB',
    'A0DNH',
    'A00RD',
    'A0DNG',
    'A00RE',
    'A0DNF',
    'A00RF',
    'A00RJ',
    'A00RK',
    'A00RG',
    'A00VA',
    'A00VB',
    'A00VD',
    'A00VE',
    'A00VF',
    'A00VH',
    'A00VJ',
    'A00VG',
    'A00VK',
    'A00VL',
    'A00VN',
    'A00VC',
    'A00VM',
    'A170Z',
    'A04LX',
    'A00VP',
    'A0DCR',
    'A0DCQ',
    'A0DCP',
    'A0DCN',
    'A0DCV',
    'A0DCT',
    'A0DCS',
    'A00TP',
    'A00TQ',
    'A0DDV',
    'A0DDT',
    'A0DDS',
    'A0DDR',
    'A0DDQ',
    'A0DDP',
    'A0DDN',
    'A00TR',
    'A0DDG',
    'A00TT',
    'A0DDM',
    'A00TS',
    'A0DDL',
    'A0DDK',
    'A0DDJ',
    'A0DDH',
    'A00TD',
    'A00TG',
    'A00TE',
    'A0DDF',
    'A0DDE',
    'A00TH',
    'A00TL',
    'A00TM',
    'A00TK',
    'A00TJ',
    'A0DDD',
    'A0DDC',
    'A0DCZ',
    'A0DCX',
    'A0DDB',
    'A0DDA',
    'A0DCY',
    'A00TF',
    'A04MB',
    'A00ZB',
    'A00ZC',
    'A00ZD',
    'A00ZE',
    'A00ZF',
    'A00ZG',
    'A00ZH',
    'A0ETT',
    'A00ZK',
    'A00ZJ',
    'A0F3F',
    'A00ZN',
    'A00ZM',
    'A0ETR',
    'A0ETS',
    'A00ZQ',
    'A00TY',
    'A0ETQ',
    'A00ZP',
    'A00TZ',
    'A0ESL',
    'A011Z',
    'A0BAH',
    'A012E',
    'A012A',
    'A012B',
    'A0BAJ',
    'A0BAM',
    'A0BAL',
    'A0BAK',
    'A0DFN',
    'A0DFM',
    'A012C',
    'A0BAZ',
    'A012D',
    'A0DFL',
    'A0BAQ',
    'A0BAN',
    'A0DFK',
    'A012F',
    'A0DFJ',
    'A0BAY',
    'A0DFH',
    'A0BAT',
    'A0BAP',
    'A0BAS',
    'A012G',
    'A012J',
    'A0BAR',
    'A0CEQ',
    'A0DFD',
    'A0BAX',
    'A0BAV',
    'A0DFC',
    'A012K',
    'A012L',
    'A012N',
    'A0DFA',
    'A0DEZ',
    'A0DEY',
    'A0DEX',
    'A04HC',
    'A0BB0',
    'A0BB1',
    'A012P',
    'A012Q',
    'A012S',
    'A013E',
    'A013N',
    'A013H',
    'A012T',
    'A012Y',
    'A012Z',
    'A012V',
    'A012X',
    'A0DCM',
    'A0DCL',
    'A013Y',
    'A013Z',
    'A013A',
    'A0DCK',
    'A013D',
    'A013B',
    'A0DCJ',
    'A0DCH',
    'A0DCG',
    'A013X',
    'A0DCF',
    'A0DCE',
    'A013Q',
    'A0DCD',
    'A013J',
    'A013K',
    'A013L',
    'A0DCC',
    'A013V',
    'A013M',
    'A0DCB',
    'A013P',
    'A0DCA',
    'A013S',
    'A0DBZ',
    'A0DBY',
    'A0DBX',
    'A0DBV',
    'A04HD',
    'A013R',
    'A013C',
    'A014A',
    'A014B',
    'A013G',
    'A013F',
    'A01AZ',
    'A01BB',
    'A01BC',
    'A01BD',
    'A01BE',
    'A185Q',
    'A01BH',
    'A18SV',
    'A01BT',
    'A01CX',
    'A01CY',
    'A01DA',
    'A01CZ',
    'A01DB',
    'A0CFB',
    'A01DD',
    'A01DC',
    'A01DE',
    'A0DZC',
    'A01CP',
    'A0DZB',
    'A01CR',
    'A01CS',
    'A0DZA',
    'A0CFD',
    'A01CT',
    'A01CV',
    'A0DYZ',
    'A01CQ',
    'A0DYY',
    'A01BX',
    'A01BY',
    'A0CEZ',
    'A01BZ',
    'A0DYX',
    'A01CA',
    'A0DYV',
    'A0DYT',
    'A0DYS',
    'A0DYR',
    'A01CB',
    'A01CD',
    'A01CF',
    'A01CC',
    'A01CE',
    'A01CG',
    'A01CL',
    'A01CK',
    'A01CH',
    'A01CM',
    'A01CJ',
    'A01CN',
    'A0DYQ',
    'A01DF',
    'A16XH',
    'A01DG',
    'A01DH',
    'A01DJ',
    'A01DK',
    'A0DXF',
    'A01DN',
    'A01DP',
    'A01DQ',
    'A0DXE',
    'A0DXD',
    'A0DXC',
    'A01DR',
    'A0DXB',
    'A0DXA',
    'A0DVZ',
    'A01DM',
    'A0DVY',
    'A01DL',
    'A01DS',
    'A01GE',
    'A0DVX',
    'A01GF',
    'A0DVV',
    'A0DVT',
    'A01GG',
    'A01GK',
    'A0DVR',
    'A0DVQ',
    'A01HC',
    'A0DVP',
    'A0DVN',
    'A01GH',
    'A01GJ',
    'A0DVS',
    'A01GL',
    'A01GM',
    'A0DVL',
    'A01GN',
    'A0DVK',
    'A01GP',
    'A01GQ',
    'A01HA',
    'A0DVJ',
    'A01GT',
    'A01GV',
    'A01JM',
    'A01GR',
    'A01GX',
    'A01GY',
    'A0DVH',
    'A01GS',
    'A0DVG',
    'A0DVF',
    'A01GZ',
    'A01HB',
    'A01DT',
    'A01DV',
    'A0DVE',
    'A01DX',
    'A0CFT',
    'A0DVD',
    'A0DVC',
    'A01DY',
    'A0DVB',
    'A0DVA',
    'A01DZ',
    'A01EA',
    'A01EC',
    'A01EB',
    'A01ED',
    'A0DTY',
    'A01EE',
    'A01EF',
    'A01EG',
    'A01EH',
    'A01EJ',
    'A01EK',
    'A01ET',
    'A0CFK',
    'A01EV',
    'A01EL',
    'A0DTX',
    'A01EN',
    'A01EP',
    'A0CFL',
    'A01EQ',
    'A0DTT',
    'A0DTS',
    'A0CFM',
    'A01ER',
    'A01ES',
    'A01EX',
    'A04JJ',
    'A01EY',
    'A0DTR',
    'A01FB',
    'A01FA',
    'A01EZ',
    'A0DTQ',
    'A0DTP',
    'A0DTN',
    'A0DTM',
    'A01FD',
    'A01FE',
    'A01FF',
    'A01FG',
    'A0DTL',
    'A0DTK',
    'A0CFS',
    'A01FC',
    'A0CFP',
    'A01FY',
    'A0DTJ',
    'A0DTH',
    'A01FJ',
    'A01FK',
    'A01FL',
    'A0DTG',
    'A01FZ',
    'A0CFY',
    'A0DTF',
    'A0CFQ',
    'A0DTE',
    'A04JK',
    'A01FH',
    'A01EM',
    'A01GC',
    'A0DTD',
    'A0DTC',
    'A0DTB',
    'A0DTA',
    'A0DSZ',
    'A0DSY',
    'A0DSX',
    'A0DSV',
    'A01FM',
    'A01FP',
    'A01FN',
    'A01FQ',
    'A0CFN',
    'A01FR',
    'A0CFR',
    'A0DSS',
    'A0DSR',
    'A0DSQ',
    'A04JL',
    'A01FS',
    'A0DSN',
    'A0DSM',
    'A0CFC',
    'A01FV',
    'A04JM',
    'A01FX',
    'A0DSL',
    'A0DSK',
    'A0DSJ',
    'A0DSH',
    'A0DSG',
    'A01GA',
    'A0DSF',
    'A0DSE',
    'A0DSD',
    'A0CFZ',
    'A0DSC',
    'A01GD',
    'A0CFX',
    'A0CFV',
    'A176F',
    'A01GB',
    'A01HD',
    'A01HE',
    'A0DSB',
    'A01HF',
    'A0DSA',
    'A0DRZ',
    'A01KP',
    'A0DRY',
    'A01HG',
    'A01HH',
    'A01BP',
    'A01JF',
    'A01JH',
    'A0DRV',
    'A0DRX',
    'A01HJ',
    'A0DRT',
    'A01HK',
    'A0CGA',
    'A0DRS',
    'A0DRR',
    'A01HL',
    'A01HM',
    'A01JB',
    'A01JQ',
    'A01LX',
    'A01HN',
    'A01JE',
    'A01JL',
    'A01JK',
    'A01HT',
    'A0DRQ',
    'A0DRP',
    'A01JN',
    'A0DRN',
    'A01HV',
    'A0DRM',
    'A01KV',
    'A16QN',
    'A01HP',
    'A01HQ',
    'A01HR',
    'A04JN',
    'A01JZ',
    'A01JC',
    'A01FT',
    'A0DRL',
    'A01JD',
    'A01JG',
    'A01HS',
    'A01JJ',
    'A01KB',
    'A01JP',
    'A01JR',
    'A01HX',
    'A0DRK',
    'A0DRJ',
    'A0DRH',
    'A01JA',
    'A16XK',
    'A01JS',
    'A01JT',
    'A0DRF',
    'A0DRE',
    'A01JV',
    'A01KQ',
    'A0DRC',
    'A0CGC',
    'A01KX',
    'A176G',
    'A01KC',
    'A0DRA',
    'A0DQZ',
    'A0DQY',
    'A0DQX',
    'A0DQV',
    'A01KD',
    'A0DQS',
    'A0CGE',
    'A0DQR',
    'A170N',
    'A01KE',
    'A01KF',
    'A01KJ',
    'A01KK',
    'A01KM',
    'A01KH',
    'A01KG',
    'A01LA',
    'A01LB',
    'A0DQN',
    'A01LC',
    'A0DQM',
    'A0DQL',
    'A0DQK',
    'A01LD',
    'A01LE',
    'A0DQJ',
    'A0DQH',
    'A0DQG',
    'A01LF',
    'A01LG',
    'A01KN',
    'A01LY',
    'A0CGB',
    'A01JX',
    'A01KS',
    'A0DQD',
    'A01HZ',
    'A01LH',
    'A01LJ',
    'A01KZ',
    'A01LM',
    'A01KL',
    'A01KR',
    'A01JY',
    'A01KA',
    'A01KT',
    'A01LL',
    'A01LK',
    'A0CGD',
    'A0DQB',
    'A0DQA',
    'A0DPZ',
    'A0DPY',
    'A0DPX',
    'A0DPV',
    'A01LP',
    'A01LQ',
    'A01LR',
    'A0DPS',
    'A01LS',
    'A01LT',
    'A01LV',
    'A01LZ',
    'A0F6K',
    'A16QE',
    'A01QN',
    'A01BR',
    'A01BQ',
    'A01PS',
    'A01QA',
    'A01PV',
    'A01PT',
    'A01PY',
    'A01QC',
    'A01PZ',
    'A01PX',
    'A01QB',
    'A01MA',
    'A01QF',
    'A01QH',
    'A01QQ',
    'A01MB',
    'A01MC',
    'A01MD',
    'A01ME',
    'A01MF',
    'A01MG',
    'A01MH',
    'A01MJ',
    'A01MK',
    'A01QM',
    'A0CJA',
    'A01NN',
    'A01PC',
    'A01QK',
    'A01NP',
    'A01NQ',
    'A01NR',
    'A01NS',
    'A01NT',
    'A01NV',
    'A01NX',
    'A01NY',
    'A01NZ',
    'A01PA',
    'A01PB',
    'A014D',
    'A014F',
    'A014E',
    'A0CFE',
    'A0DYN',
    'A0DYM',
    'A015A',
    'A015C',
    'A170M',
    'A014G',
    'A014H',
    'A014J',
    'A0DYH',
    'A0DYG',
    'A0DYF',
    'A0DYE',
    'A014K',
    'A0DYC',
    'A0CFF',
    'A014L',
    'A0DYB',
    'A0CFG',
    'A0DYA',
    'A014M',
    'A0DXZ',
    'A0DXY',
    'A0DXX',
    'A014N',
    'A014Y',
    'A014P',
    'A01BV',
    'A0DXV',
    'A0DXT',
    'A0DXS',
    'A0DXR',
    'A0DXQ',
    'A0DXP',
    'A0DXN',
    'A0DXM',
    'A0DXL',
    'A0DXK',
    'A014Q',
    'A0DXJ',
    'A014R',
    'A0DXH',
    'A0DXG',
    'A014S',
    'A014T',
    'A014V',
    'A014X',
    'A014Z',
    'A015B',
    'A015D',
    'A0F0L',
    'A0F0N',
    'A0F0M',
    'A01BL',
    'A01BM',
    'A01BN',
    'A0DBT',
    'A015G',
    'A0DBS',
    'A015H',
    'A0DBR',
    'A015J',
    'A0DBQ',
    'A015K',
    'A0DBP',
    'A015L',
    'A04KG',
    'A015M',
    'A0DBN',
    'A015N',
    'A015T',
    'A0DBM',
    'A015P',
    'A016E',
    'A015Q',
    'A015S',
    'A0DBL',
    'A0DBK',
    'A015R',
    'A0DBJ',
    'A015V',
    'A0DBH',
    'A0DBG',
    'A0CGF',
    'A015X',
    'A0DBF',
    'A0DBE',
    'A0DBD',
    'A015Y',
    'A0DBC',
    'A016F',
    'A0CGG',
    'A015Z',
    'A0CGJ',
    'A0DBA',
    'A0DAZ',
    'A0DAY',
    'A0DAX',
    'A0BKL',
    'A0CGH',
    'A0DAV',
    'A016A',
    'A0DAS',
    'A016B',
    'A0DAR',
    'A0DAQ',
    'A0DAP',
    'A016C',
    'A016J',
    'A0DAN',
    'A016D',
    'A016G',
    'A0F6H',
    'A16FZ',
    'A16RB',
    'A0DAM',
    'A016M',
    'A0DAL',
    'A016N',
    'A0DAK',
    'A0DAJ',
    'A0DAH',
    'A0DAG',
    'A0DAF',
    'A0DAE',
    'A016H',
    'A0DAD',
    'A016P',
    'A0DAC',
    'A0DAB',
    'A0DAA',
    'A0D9Z',
    'A016Q',
    'A016R',
    'A0DPP',
    'A00ZT',
    'A011P',
    'A011R',
    'A00ZX',
    'A00ZV',
    'A0DPM',
    'A00ZY',
    'A04JX',
    'A00ZZ',
    'A0DPL',
    'A010K',
    'A010N',
    'A010B',
    'A0DPK',
    'A0DPJ',
    'A0DPN',
    'A010A',
    'A0DPH',
    'A010C',
    'A04JY',
    'A010D',
    'A0DPG',
    'A0DPF',
    'A010F',
    'A010E',
    'A0DPE',
    'A010G',
    'A16FL',
    'A0DPD',
    'A010H',
    'A010J',
    'A010P',
    'A010Q',
    'A185R',
    'A16RD',
    'A011C',
    'A011E',
    'A011G',
    'A011D',
    'A18PR',
    'A16FM',
    'A0CXQ',
    'A010T',
    'A0CXM',
    'A010Z',
    'A0CXL',
    'A0CXK',
    'A0CXP',
    'A010V',
    'A0CXN',
    'A0BB7',
    'A010Y',
    'A04HB',
    'A0CXJ',
    'A0CXH',
    'A034A',
    'A0CXG',
    'A0CXF',
    'A0CXE',
    'A0CXD',
    'A0DJJ',
    'A00XB',
    'A00XC',
    'A00XD',
    'A00XE',
    'A0DJH',
    'A04KB',
    'A00XA',
    'A00VR',
    'A00VZ',
    'A0DJG',
    'A00XF',
    'A0CSR',
    'A00XH',
    'A00XL',
    'A0DJF',
    'A00YG',
    'A00XS',
    'A0DJE',
    'A0DJD',
    'A00YL',
    'A0DJC',
    'A0DJB',
    'A00MT',
    'A0DJA',
    'A00YM',
    'A0DHZ',
    'A00YE',
    'A0DHY',
    'A0DHX',
    'A00YH',
    'A00VY',
    'A0DHV',
    'A0DHT',
    'A0DHS',
    'A0DHR',
    'A0DHQ',
    'A00YF',
    'A0DHP',
    'A171C',
    'A04KD',
    'A00YQ',
    'A0DHN',
    'A0DHM',
    'A0DHL',
    'A0DHK',
    'A00XX',
    'A0DHJ',
    'A00XV',
    'A0DHH',
    'A0DHG',
    'A00YK',
    'A0ESV',
    'A00VV',
    'A00XZ',
    'A0DHA',
    'A0DGZ',
    'A0DGY',
    'A0DGX',
    'A00YA',
    'A00YB',
    'A00YC',
    'A0DGV',
    'A0DGT',
    'A0DGS',
    'A0DHF',
    'A0DHE',
    'A0DHD',
    'A00VT',
    'A0DHC',
    'A0DGQ',
    'A0DGP',
    'A0DGG',
    'A0DHB',
    'A00YR',
    'A18SY',
    'A0DGE',
    'A00VX',
    'A00XG',
    'A00XN',
    'A0DGD',
    'A0DGC',
    'A170Y',
    'A04KF',
    'A00YP',
    'A0DGB',
    'A00XJ',
    'A00XM',
    'A00XR',
    'A0DGA',
    'A00PA',
    'A0DFZ',
    'A0DFY',
    'A0DFX',
    'A00VS',
    'A00XQ',
    'A00XK',
    'A00YJ',
    'A00YS',
    'A00YV',
    'A00XT',
    'A0DGN',
    'A0DGM',
    'A0DGL',
    'A0DGH',
    'A00YD',
    'A0DGK',
    'A0DGJ',
    'A00YZ',
    'A01AQ',
    'A0CXS',
    'A01AR',
    'A01AS',
    'A0CZF',
    'A017Y',
    'A0CZE',
    'A0CZD',
    'A017Z',
    'A04KN',
    'A018A',
    'A018B',
    'A018C',
    'A0CGQ',
    'A018D',
    'A0CGP',
    'A0CZA',
    'A018E',
    'A0CYZ',
    'A018F',
    'A0CYY',
    'A018G',
    'A0CYX',
    'A0CYV',
    'A0CYT',
    'A018H',
    'A0CYS',
    'A018J',
    'A018M',
    'A019N',
    'A018P',
    'A171B',
    'A018K',
    'A0F7E',
    'A0CYA',
    'A01AL',
    'A01AP',
    'A0CXZ',
    'A0CXY',
    'A0CXX',
    'A0CHA',
    'A01AM',
    'A019Q',
    'A0CXV',
    'A0CXT',
    'A0CHJ',
    'A01AN',
    'A0CXR',
    'A01AV',
    'A01AX',
    'A019T',
    'A019V',
    'A019X',
    'A0CYH',
    'A0CYG',
    'A0CYF',
    'A019Y',
    'A0CYE',
    'A01AA',
    'A0CGX',
    'A0CYC',
    'A0CYD',
    'A01AJ',
    'A01AB',
    'A010L',
    'A010M',
    'A170P',
    'A0CHH',
    'A0CGZ',
    'A01AC',
    'A01AF',
    'A0CYB',
    'A0CHG',
    'A0CGY',
    'A00QL',
    'A01AD',
    'A18FY',
    'A0CYP',
    'A018R',
    'A0CGR',
    'A018S',
    'A0CHB',
    'A0CHC',
    'A0CGS',
    'A018T',
    'A018L',
    'A0CGT',
    'A018V',
    'A0CYM',
    'A019R',
    'A0CYL',
    'A018X',
    'A018Y',
    'A018Z',
    'A019A',
    'A019B',
    'A019C',
    'A018N',
    'A019G',
    'A019P',
    'A019E',
    'A019D',
    'A0CYK',
    'A019F',
    'A0CGV',
    'A019H',
    'A0CHE',
    'A01KY',
    'A0CHF',
    'A0CYJ',
    'A019J',
    'A019M',
    'A019K',
    'A019L',
    'A016T',
    'A016V',
    'A016X',
    'A016Y',
    'A016Z',
    'A017A',
    'A017B',
    'A017C',
    'A017D',
    'A017E',
    'A017F',
    'A017G',
    'A017H',
    'A017J',
    'A017K',
    'A017L',
    'A017M',
    'A017N',
    'A017P',
    'A017Q',
    'A017R',
    'A017S',
    'A017T',
    'A0BY4',
    'A03AN',
    'A03AP',
    'A03AQ',
    'A03AR',
    'A03AS',
    'A03AT',
    'A03AV',
    'A03AX',
    'A03AY',
    'A03AZ',
    'A03BA',
    'A039M',
    'A039N',
    'A039P',
    'A039Q',
    'A039S',
    'A03AG',
    'A039T',
    'A039V',
    'A03AK',
    'A03AL',
    'A039X',
    'A03AH',
    'A03AJ',
    'A039Y',
    'A039Z',
    'A03AM',
    'A03AA',
    'A03AB',
    'A03AC',
    'A03AF',
    'A03AD',
    'A03AE',
    'A039R',
    'A03BB',
    'A03BC',
    'A03BD',
    'A03BE',
    'A03BF',
    'A03BG',
    'A03BH',
    'A03BJ',
    'A03BK',
    'A03BL',
    'A04PQ',
    'A03CS',
    'A03CT',
    'A03CV',
    'A03CX',
    'A03CK',
    'A03CL',
    'A03CQ',
    'A03CJ',
    'A03CR',
    'A03CN',
    'A03CM',
    'A03CP',
    'A03DB',
    'A03DC',
    'A03DD',
    'A03DE',
    'A03DF',
    'A03DH',
    'A01MM',
    'A01MN',
    'A01MP',
    'A01MQ',
    'A01MR',
    'A01MS',
    'A01MT',
    'A01MV',
    'A01MX',
    'A01MY',
    'A01MZ',
    'A01NA',
    'A01NB',
    'A01NC',
    'A01ND',
    'A01NE',
    'A01NF',
    'A01NG',
    'A01NH',
    'A01NJ',
    'A01NK',
    'A01NL',
    'A01NM',
    'A16FC',
    'A01QP',
    'A01PD',
    'A01PF',
    'A01PH',
    'A01PP',
    'A01PE',
    'A01PM',
    'A01PG',
    'A01PQ',
    'A01PJ',
    'A01PK',
    'A01PL',
    'A01PN',
    'A01PR',
    'A01QJ',
    'A16GL',
    'A03DG',
    'A0F4S',
    'A03BN',
    'A03BP',
    'A03BQ',
    'A03BR',
    'A03BS',
    'A03BT',
    'A03BV',
    'A03BX',
    'A03BY',
    'A03BZ',
    'A03CA',
    'A03CB',
    'A03CC',
    'A03CD',
    'A03CE',
    'A03CF',
    'A16MQ',
    'A16MR',
    'A16MS',
    'A03CZ',
    'A03CG',
    'A03DA',
    'A0EVA',
    'A01BK',
    'A0ETZ',
    'A0F4R',
    'A03JK',
    'A0D7B',
    'A03JL',
    'A00YY',
    'A0D7A',
    'A0D3Z',
    'A0D3G',
    'A0D3C',
    'A0D6Y',
    'A0D6Z',
    'A0BB6',
    'A0D6V',
    'A0D6T',
    'A0D6S',
    'A0D6R',
    'A0D6Q',
    'A0D6P',
    'A0D6N',
    'A0D6M',
    'A0D6L',
    'A0D6K',
    'A0D6J',
    'A0D6H',
    'A0D6G',
    'A0D6F',
    'A0D6E',
    'A0D6D',
    'A0D6C',
    'A0D6B',
    'A0D6A',
    'A0D5Z',
    'A0D5Y',
    'A0D5X',
    'A0D5V',
    'A0D5T',
    'A0D5S',
    'A0D5R',
    'A0D5Q',
    'A0D5P',
    'A0D5N',
    'A0D5M',
    'A0D5L',
    'A0D5K',
    'A0D5J',
    'A0D5H',
    'A0D5G',
    'A0D5F',
    'A0D5E',
    'A0D5D',
    'A0D5C',
    'A0D5B',
    'A0D5A',
    'A0D4Z',
    'A0D4Y',
    'A0D4X',
    'A0D4V',
    'A0D4T',
    'A0D4S',
    'A0D4R',
    'A0D4Q',
    'A0D4P',
    'A0D4N',
    'A0D4M',
    'A0D4L',
    'A0D4K',
    'A03JN',
    'A0D4J',
    'A0D4H',
    'A0D4G',
    'A0D4F',
    'A0D4E',
    'A0D4D',
    'A0D4C',
    'A0D4B',
    'A0D4A',
    'A0D3Y',
    'A0D3X',
    'A0D3V',
    'A0D3T',
    'A0D3S',
    'A0D3R',
    'A0D3Q',
    'A0D3P',
    'A0D3N',
    'A0D3M',
    'A0D3L',
    'A0D3K',
    'A0D3J',
    'A0D3H',
    'A0D3F',
    'A0D3E',
    'A0D3D',
    'A0D3B',
    'A0D3A',
    'A0D2Z',
    'A0D2Y',
    'A00YX',
    'A00YN',
    'A0D2T',
    'A0D2S',
    'A0D2R',
    'A0D2Q',
    'A0D2P',
    'A0D2N',
    'A0D2M',
    'A0D2L',
    'A0D2K',
    'A0D2J',
    'A0D2H',
    'A0D2G',
    'A0D2F',
    'A00XY',
    'A171A',
    'A04KM',
    'A03JP',
    'A0D2D',
    'A0D2C',
    'A0D2B',
    'A0D2A',
    'A0D1Z',
    'A0D1Y',
    'A03JM',
    'A03JQ',
    'A0CGN',
    'A176M',
    'A03JR',
    'A0D9E',
    'A03JC',
    'A0D9D',
    'A0D9C',
    'A0D9B',
    'A03JD',
    'A0D9A',
    'A03JE',
    'A0D8Z',
    'A0D8Y',
    'A0D8X',
    'A0D8V',
    'A0D8T',
    'A0D8S',
    'A0D8R',
    'A0D8Q',
    'A0D8P',
    'A0D8N',
    'A0D8M',
    'A0D8L',
    'A03JG',
    'A0D8K',
    'A0D8J',
    'A0D8H',
    'A0D8G',
    'A0D8F',
    'A0D8E',
    'A0D8D',
    'A00XP',
    'A0D8B',
    'A0D8A',
    'A0D7Z',
    'A0D7Y',
    'A0D7X',
    'A0D7V',
    'A0D7T',
    'A0D7S',
    'A0D7R',
    'A0D7Q',
    'A0D7P',
    'A0D7N',
    'A0D7M',
    'A0D7L',
    'A0D7K',
    'A0D7J',
    'A04KL',
    'A03JF',
    'A0D7G',
    'A0D7F',
    'A03JH',
    'A0D7E',
    'A0D7D',
    'A0D7C',
    'A03JJ',
    'A0D9J',
    'A03GK',
    'A0D9H',
    'A0D9F',
    'A0D9G',
    'A0CZM',
    'A03HF',
    'A03HL',
    'A0CZL',
    'A0CZK',
    'A0D1X',
    'A03JT',
    'A0D1V',
    'A01AG',
    'A0D1R',
    'A0D1Q',
    'A0D1P',
    'A0D1N',
    'A0D1M',
    'A0D1L',
    'A01AH',
    'A0D1J',
    'A0D1H',
    'A0D1G',
    'A0D1F',
    'A0D1E',
    'A0D1D',
    'A0D1C',
    'A0D1B',
    'A0D1A',
    'A0D0Z',
    'A01AE',
    'A0D0X',
    'A03JV',
    'A0D0V',
    'A0D0T',
    'A0D0S',
    'A03JX',
    'A0CZJ',
    'A01HY',
    'A0CZH',
    'A03JY',
    'A0D0R',
    'A0D0Q',
    'A0D0P',
    'A0D0N',
    'A0D0M',
    'A0D0L',
    'A0D0K',
    'A0D0J',
    'A0D0H',
    'A0D0G',
    'A0D0F',
    'A0D0E',
    'A03HM',
    'A0D0D',
    'A0D0C',
    'A0D0B',
    'A0D0A',
    'A0CZZ',
    'A0CZY',
    'A0CZX',
    'A0CZV',
    'A0CZT',
    'A0CZS',
    'A0CZR',
    'A0D1T',
    'A0CZQ',
    'A0CZP',
    'A03HP',
    'A0CZN',
    'A185T',
    'A0C6A',
    'A03GL',
    'A03GM',
    'A03GN',
    'A03GP',
    'A0DLJ',
    'A03GQ',
    'A03GR',
    'A03GS',
    'A03HD',
    'A03GT',
    'A03GV',
    'A03GX',
    'A03GY',
    'A03GZ',
    'A03HA',
    'A03HB',
    'A03HC',
    'A0C6B',
    'A03HG',
    'A03HJ',
    'A03HQ',
    'A04KK',
    'A03HR',
    'A03HV',
    'A03HS',
    'A03HT',
    'A03HX',
    'A03HY',
    'A03HZ',
    'A16GP',
    'A16GX',
    'A16GY',
    'A16GZ',
    'A03HH',
    'A0F2E',
    'A03KB',
    'A167A',
    'A03KC',
    'A03KD',
    'A03KE',
    'A03KF',
    'A03KG',
    'A03KH',
    'A03KJ',
    'A03KK',
    'A03KM',
    'A03KN',
    'A03KP',
    'A03KQ',
    'A03KR',
    'A03KS',
    'A03KT',
    'A03KV',
    'A03KX',
    'A03KZ',
    'A03LA',
    'A03LC',
    'A03LD',
    'A03LE',
    'A03LF',
    'A03LH',
    'A03LJ',
    'A03LK',
    'A03LL',
    'A03LM',
    'A03LN',
    'A03LP',
    'A03LQ',
    'A03LR',
    'A03LS',
    'A03LT',
    'A16GQ',
    'A16GR',
    'A03LV',
    'A03LX',
    'A03LY',
    'A03MB',
    'A03MC',
    'A03MD',
    'A03ME',
    'A03MF',
    'A03MG',
    'A03MH',
    'A03MJ',
    'A03MK',
    'A03ML',
    'A03MM',
    'A03MN',
    'A03MP',
    'A03MQ',
    'A03MR',
    'A16GG',
    'A03MT',
    'A03MV',
    'A03MX',
    'A03MY',
    'A03MZ',
    'A03NA',
    'A03NB',
    'A03NC',
    'A03ND',
    'A03NE',
    'A03NF',
    'A03NN',
    'A03NP',
    'A03NQ',
    'A03NR',
    'A03NG',
    'A03NH',
    'A03NJ',
    'A03NK',
    'A03NL',
    'A03NM',
    'A03NT',
    'A03NV',
    'A03NX',
    'A03NY',
    'A03NZ',
    'A03PA',
    'A03PB',
    'A03PC',
    'A0CSC',
    'A03PL',
    'A03PJ',
    'A03PH',
    'A03PF',
    'A0CSA',
    'A0CSB',
    'A03PE',
    'A03PK',
    'A04QG',
    'A03PG',
    'A03PN',
    'A03PP',
    'A03PQ',
    'A03PR',
    'A03PS',
    'A03PT',
    'A034G',
    'A034H',
    'A034J',
    'A034K',
    'A034L',
    'A034M',
    'A034P',
    'A034N',
    'A0C6P',
    'A034Q',
    'A034R',
    'A034S',
    'A0EQP',
    'A035H',
    'A035J',
    'A035K',
    'A035L',
    'A035M',
    'A034X',
    'A035F',
    'A034Y',
    'A035C',
    'A035E',
    'A035G',
    'A035B',
    'A035D',
    'A034Z',
    'A0FDG',
    'A0FDH',
    'A035P',
    'A035Q',
    'A035R',
    'A035S',
    'A035T',
    'A035V',
    'A035X',
    'A035Y',
    'A035Z',
    'A036A',
    'A036B',
    'A036C',
    'A036D',
    'A036E',
    'A035A',
    'A03PY',
    'A0EQM',
    'A03PZ',
    'A03QA',
    'A03QB',
    'A03QC',
    'A03QD',
    'A03QE',
    'A03QF',
    'A03QG',
    'A03QH',
    'A03QJ',
    'A0EQL',
    'A03QK',
    'A03QL',
    'A03QM',
    'A03QN',
    'A03QP',
    'A03QQ',
    'A03QR',
    'A03QS',
    'A03QT',
    'A03QV',
    'A03RC',
    'A03RF',
    'A03RG',
    'A03RH',
    'A03RE',
    'A03RJ',
    'A03RD',
    'A03RK',
    'A03QX',
    'A03QZ',
    'A0BZF',
    'A03QY',
    'A0BZE',
    'A03RA',
    'A03RB',
    'A03RM',
    'A16GS',
    'A03RN',
    'A03RP',
    'A03YY',
    'A03YZ',
    'A03ZB',
    'A03ZG',
    'A03ZM',
    'A03ZJ',
    'A03ZK',
    'A03ZL',
    'A03ZC',
    'A03ZH',
    'A03ZA',
    'A03ZE',
    'A03ZF',
    'A03ZD',
    'A03ZN',
    'A03ZQ',
    'A03ZX',
    'A040A',
    'A040B',
    'A03ZR',
    'A03ZP',
    'A03ZS',
    'A03ZT',
    'A03ZV',
    'A03ZY',
    'A03ZZ',
    'A0CEN',
    'A03YM',
    'A00CA',
    'A03YA',
    'A040C',
    'A040J',
    'A040D',
    'A040H',
    'A040L',
    'A040K',
    'A040E',
    'A040F',
    'A040G',
    'A03YT',
    'A040M',
    'A007S',
    'A040N',
    'A0CDQ',
    'A040P',
    'A040Q',
    'A040R',
    'A040S',
    'A040T',
    'A03YX',
    'A0CDP',
    'A040V',
    'A040X',
    'A040Y',
    'A0BA2',
    'A040Z',
    'A041H',
    'A041J',
    'A16GF',
    'A041D',
    'A041A',
    'A041B',
    'A041C',
    'A041E',
    'A041F',
    'A041G',
    'A18PS',
    'A03VC',
    'A03VV',
    'A03VX',
    'A03VY',
    'A03VZ',
    'A03XA',
    'A03XB',
    'A03XC',
    'A03XD',
    'A03XE',
    'A03XF',
    'A03XG',
    'A03XH',
    'A16ER',
    'A03XJ',
    'A03XK',
    'A03XL',
    'A03XM',
    'A03XN',
    'A03XP',
    'A03XQ',
    'A03XR',
    'A03XS',
    'A03XT',
    'A03XV',
    'A02KD',
    'A03YJ',
    'A03YN',
    'A03YK',
    'A03YP',
    'A03YQ',
    'A03YR',
    'A03YL',
    'A03VM',
    'A03VN',
    'A03VP',
    'A03VQ',
    'A03VR',
    'A03VS',
    'A03VT',
    'A01BA',
    'A16FF',
    'A03VD',
    'A03VE',
    'A03VF',
    'A03VG',
    'A03VH',
    'A03VJ',
    'A03VK',
    'A03VL',
    'A011M',
    'A011Q',
    'A011N',
    'A0BYV',
    'A0BYX',
    'A0C6H',
    'A0C6K',
    'A0C6J',
    'A011S',
    'A03YS',
    'A03XX',
    'A03XY',
    'A03XZ',
    'A16GB',
    'A03YB',
    'A03YC',
    'A03YD',
    'A03YE',
    'A03YF',
    'A03YG',
    'A03YH',
    'A0B9J',
    'A0B9Q',
    'A0B9P',
    'A0B9N',
    'A0B9L',
    'A0B9T',
    'A0B9V',
    'A0B9M',
    'A0B9Y',
    'A0B9X',
    'A0B9S',
    'A0B9R',
    'A0B9K',
    'A041L',
    'A041T',
    'A0BZA',
    'A0BZB',
    'A041X',
    'A041Z',
    'A041Q',
    'A041P',
    'A041Y',
    'A00EP',
    'A041M',
    'A041N',
    'A0CVB',
    'A041R',
    'A042A',
    'A0CDN',
    'A041S',
    'A0BZ9',
    'A0BZ8',
    'A041V',
    'A0BZC',
    'A0BZD',
    'A042B',
    'A042C',
    'A042D',
    'A042E',
    'A042F',
    'A042G',
    'A042H',
    'A042J',
    'A042K',
    'A042L',
    'A042M',
    'A03YV',
    'A01QG',
    'A04PD',
    'A036F',
    'A036G',
    'A036H',
    'A036J',
    'A036K',
    'A036L',
    'A02PT',
    'A02PV',
    'A02PX',
    'A02PY',
    'A02PZ',
    'A02QA',
    'A02QB',
    'A02QC',
    'A02QD',
    'A04NS',
    'A0EQY',
    'A0EQX',
    'A011L',
    'A0BYS',
    'A0BYT',
    'A00FC',
    'A00FD',
    'A00FF',
    'A0EQV',
    'A00FE',
    'A06HL',
    'A00FH',
    'A011T',
    'A011V',
    'A042Q',
    'A042R',
    'A042S',
    'A042T',
    'A042V',
    'A042X',
    'A042Z',
    'A043A',
    'A043B',
    'A043C',
    'A043D',
    'A043Q',
    'A043R',
    'A043S',
    'A043T',
    'A043F',
    'A0BZ7',
    'A043G',
    'A043H',
    'A043J',
    'A18BC',
    'A0EZC',
    'A0BN3',
    'A0C0T',
    'A043K',
    'A043L',
    'A043M',
    'A043N',
    'A043P',
    'A16GK',
    'A044L',
    'A044M',
    'A04JG',
    'A044N',
    'A045K',
    'A045L',
    'A043V',
    'A0F1M',
    'A044X',
    'A044Y',
    'A045F',
    'A045N',
    'A044C',
    'A0F1N',
    'A043X',
    'A043Y',
    'A044A',
    'A044B',
    'A043Z',
    'A044G',
    'A044H',
    'A044J',
    'A044K',
    'A045B',
    'A0EZD',
    'A044R',
    'A044S',
    'A044T',
    'A0B9H',
    'A045C',
    'A16EY',
    'A16QF',
    'A185V',
    'A044P',
    'A0ERG',
    'A044Q',
    'A045A',
    'A045D',
    'A044V',
    'A045G',
    'A045H',
    'A045E',
    'A045M',
    'A0FDF',
    'A16BR',
    'A044E',
    'A044D',
    'A16BS',
    'A046E',
    'A045Q',
    'A045R',
    'A045S',
    'A045T',
    'A045V',
    'A045X',
    'A045Y',
    'A045Z',
    'A046A',
    'A046B',
    'A046C',
    'A046D',
    'A046G',
    'A046H',
    'A046J',
    'A046K',
    'A0F3D',
    'A036N',
    'A036P',
    'A036Q',
    'A036R',
    'A036S',
    'A036T',
    'A036V',
    'A036X',
    'A036Y',
    'A036Z',
    'A037A',
    'A037B',
    'A037C',
    'A037D',
    'A037E',
    'A037F',
    'A037G',
    'A037H',
    'A037K',
    'A18FZ',
    'A037L',
    'A037M',
    'A037N',
    'A037P',
    'A037Q',
    'A037R',
    'A0F5Z',
    'A037T',
    'A04PJ',
    'A037V',
    'A037X',
    'A037Y',
    'A037Z',
    'A038A',
    'A038B',
    'A038C',
    'A038D',
    'A038E',
    'A038F',
    'A038G',
    'A038H',
    'A038J',
    'A038K',
    'A038L',
    'A038M',
    'A038Q',
    'A038N',
    'A038P',
    'A04PL',
    'A038R',
    'A038V',
    'A038X',
    'A038Y',
    'A04PK',
    'A038S',
    'A038T',
    'A038Z',
    'A039A',
    'A039B',
    'A039C',
    'A0F1G',
    'A039D',
    'A039E',
    'A039H',
    'A04SD',
    'A039J',
    'A039F',
    'A039G',
    'A032G',
    'A032H',
    'A032J',
    'A032K',
    'A032L',
    'A032M',
    'A032N',
    'A032P',
    'A16QB',
    'A032Q',
    'A032R',
    'A032S',
    'A02PQ',
    'A032X',
    'A032Y',
    'A032V',
    'A032T',
    'A033J',
    'A033K',
    'A033L',
    'A033M',
    'A033N',
    'A033P',
    'A033Q',
    'A033R',
    'A033S',
    'A033T',
    'A033X',
    'A033Y',
    'A033V',
    'A033Z',
    'A034B',
    'A034C',
    'A034D',
    'A034E',
    'A032Z',
    'A033D',
    'A033E',
    'A033F',
    'A033H',
    'A033G',
    'A033B',
    'A033C',
    'A033A',
    'A0DPT',
    'A0BSL',
    'A0BSK',
    'A0BSM',
    'A0EZE',
    'A0F7T',
    'A0F7V',
    'A0F7X',
    'A002R',
    'A003A',
    'A003G',
    'A003R',
    'A004M',
    'A004N',
    'A011F',
    'A011J',
    'A02PR',
    'A02PS',
    'A01BG',
    'A0F5E',
    'A16EN',
    'A16EP',
    'A16GN',
    'A047A',
    'A047B',
    'A06JV',
    'A06JX',
    'A06JY',
    'A06JZ',
    'A06KA',
    'A06KB',
    'A06KC',
    'A06KD',
    'A06KE',
    'A06KF',
    'A06KG',
    'A06KH',
    'A06KJ',
    'A06KK',
    'A06KL',
    'A06KM',
    'A06KN',
    'A06KP',
    'A06KQ',
    'A06KR',
    'A06KS',
    'A06KT',
    'A06KV',
    'A06KX',
    'A06KY',
    'A06KZ',
    'A06LA',
    'A06LB',
    'A06LC',
    'A06LD',
    'A06LE',
    'A06LF',
    'A06LG',
    'A06LH',
    'A06LJ',
    'A06LK',
    'A06LL',
    'A06LM',
    'A06LN',
    'A06LP',
    'A06LQ',
    'A06LR',
    'A06LS',
    'A047K',
    'A06LT',
    'A06LV',
    'A06LX',
    'A06LY',
    'A06LZ',
    'A06MA',
    'A06MB',
    'A06MC',
    'A0CRZ',
    'A06MD',
    'A06ME',
    'A06MF',
    'A06MG',
    'A06MH',
    'A06MJ',
    'A06MK',
    'A06ML',
    'A06MM',
    'A06MN',
    'A06MP',
    'A06MQ',
    'A06MR',
    'A06MS',
    'A06MT',
    'A06MV',
    'A06MX',
    'A06MY',
    'A06MZ',
    'A06NA',
    'A06NB',
    'A06NC',
    'A06ND',
    'A06NE',
    'A06NF',
    'A06NG',
    'A06NH',
    'A06NJ',
    'A06NK',
    'A06NL',
    'A06NM',
    'A06NN',
    'A06NP',
    'A06NQ',
    'A06NR',
    'A06NS',
    'A06NT',
    'A06NV',
    'A06NX',
    'A06NY',
    'A06NZ',
    'A06PA',
    'A06PB',
    'A06PC',
    'A06PD',
    'A06PE',
    'A06PF',
    'A06PG',
    'A06PH',
    'A06PJ',
    'A06PK',
    'A06PL',
    'A06PM',
    'A06PN',
    'A06PP',
    'A06PQ',
    'A06PR',
    'A06PS',
    'A06PT',
    'A06PV',
    'A06PX',
    'A06PY',
    'A06PZ',
    'A06QA',
    'A06QB',
    'A06QC',
    'A06QD',
    'A06QE',
    'A06QF',
    'A06QG',
    'A06QH',
    'A06QJ',
    'A06QK',
    'A06QL',
    'A06QM',
    'A06QN',
    'A06QP',
    'A06QQ',
    'A06QR',
    'A06QS',
    'A06QT',
    'A06QV',
    'A06QX',
    'A06QY',
    'A06QZ',
    'A06RA',
    'A06RB',
    'A06RC',
    'A06RD',
    'A06RE',
    'A06RF',
    'A06RG',
    'A06RH',
    'A06RJ',
    'A06RK',
    'A06RL',
    'A06RM',
    'A06RN',
    'A06RP',
    'A06RQ',
    'A06RR',
    'A06RS',
    'A06RT',
    'A06RV',
    'A06RX',
    'A06RY',
    'A06RZ',
    'A06SA',
    'A06SB',
    'A06SC',
    'A06SD',
    'A06SE',
    'A06SF',
    'A06SG',
    'A06SH',
    'A06SJ',
    'A06SK',
    'A06SL',
    'A06SM',
    'A06SN',
    'A06SP',
    'A06SQ',
    'A06SR',
    'A06SS',
    'A06ST',
    'A06SV',
    'A06SX',
    'A06SY',
    'A06SZ',
    'A06TA',
    'A06TB',
    'A06TC',
    'A06TD',
    'A06TE',
    'A06TF',
    'A06TG',
    'A06TH',
    'A06TJ',
    'A06TK',
    'A06TL',
    'A06TM',
    'A06TN',
    'A06TP',
    'A06TQ',
    'A06TR',
    'A06TS',
    'A06TT',
    'A06TV',
    'A06TX',
    'A06TY',
    'A06TZ',
    'A06VA',
    'A06VB',
    'A06VC',
    'A06VD',
    'A06VE',
    'A06VF',
    'A06VG',
    'A06VH',
    'A06VJ',
    'A06VK',
    'A06VL',
    'A06VM',
    'A06VN',
    'A06VP',
    'A06VQ',
    'A06VR',
    'A06VS',
    'A06VT',
    'A06VV',
    'A0CRY',
    'A06VX',
    'A06VY',
    'A06VZ',
    'A06XA',
    'A06XB',
    'A06XC',
    'A06XD',
    'A06XE',
    'A0CHZ',
    'A047C',
    'A047D',
    'A047E',
    'A047F',
    'A047G',
    'A047H',
    'A047J',
    'A047L',
    'A17LY',
    'A047N',
    'A047P',
    'A047Q',
    'A046M',
    'A046N',
    'A046P',
    'A046Q',
    'A046R',
    'A046S',
    'A046T',
    'A046V',
    'A046X',
    'A046Y',
    'A046Z',
    'A047R',
    'A047S',
    'A047T',
    'A047V',
    'A047X',
    'A047Y',
    'A047Z',
    'A048A',
    'A048B',
    'A048C',
    'A048D',
    'A048E',
    'A048F',
    'A048G',
    'A048H',
    'A048J',
    'A048K',
    'A048L',
    'A048M',
    'A048N',
    'A169T',
    'A18FD',
    'A04QQ',
    'A048Q',
    'A048R',
    'A048S',
    'A0F3T',
    'A16GC',
    'A048T',
    'A0EVG',
    'A0EXZ',
    'A0EXY',
    'A0EXX',
    'A0EXV',
    'A0EXT',
    'A0EXS',
    'A0EXR',
    'A0EXQ',
    'A0EXP',
    'A0EXN',
    'A0EXM',
    'A0EXL',
    'A0EXK',
    'A0EVF',
    'A0EXJ',
    'A0EXH',
    'A0EXG',
    'A0EXF',
    'A0EXE',
    'A0EXD',
    'A0EXC',
    'A0EXB',
    'A0EXA',
    'A0F3A',
    'A0EVZ',
    'A0EVY',
    'A0EVX',
    'A16YP',
    'A0EVS',
    'A0EVV',
    'A0EVT',
    'A0EVR',
    'A0EVM',
    'A0EVQ',
    'A0EVP',
    'A0EVN',
    'A0EVL',
    'A0EVK',
    'A16GT',
    'A16YQ',
    'A0EVH',
    'A0CVH',
    'A0CVG',
    'A0CVF',
    'A0F4M',
    'A0F4N',
    'A0EVJ',
    'A0F4P',
    'A048X',
    'A048Y',
    'A048Z',
    'A049A',
    'A05QQ',
    'A049B',
    'A16FP',
    'A048V',
    'A16PQ',
    ...MatrixKind.extract(['A00KR', 'A012R', 'A014C']).options,
  ],
  {
    errorMap: () => ({
      message: 'Veuillez renseigner la matrice.',
    }),
  }
);

export type Matrix = z.infer<typeof Matrix>;

export const MatrixList = Matrix.options;
